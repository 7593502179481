/* Lato css */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src: url('./Lato/Lato-Regular.eot'); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'), url('./Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./Lato/Lato-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./Lato/Lato-Regular.woff') format('woff'), /* Modern Browsers */
    url('./Lato/Lato-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./Lato/Lato-Regular.svg#Lato') format('svg'); /* Legacy iOS */
}
