.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.text-tiny {
  font-size: 70% !important;
}

.text-big {
  font-size: 112% !important;
}

.text-large {
  font-size: 150% !important;
}

.text-xlarge {
  font-size: 170% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-condenced {
  line-height: 1.3 !important;
}

.line-height-inherit {
  line-height: inherit !important;
}

.text-expanded {
  letter-spacing: 0.0699105145em !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: 900 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-transform-none {
  text-transform: none !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-auto {
  overflow: auto !important;
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.container-p-x {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
@media (min-width: 992px) {
  .container-p-x {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
}

.container-m--x {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
@media (min-width: 992px) {
  .container-m--x {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
}

.container-p-y:not([class^="pt-"]):not([class*=" pt-"]) {
  padding-top: 1.5rem !important;
}
.container-p-y:not([class^="pb-"]):not([class*=" pb-"]) {
  padding-bottom: 1.5rem !important;
}

.container-m--y:not([class^="mt-"]):not([class*=" mt-"]) {
  margin-top: -1.5rem !important;
}
.container-m--y:not([class^="mb-"]):not([class*=" mb-"]) {
  margin-bottom: -1.5rem !important;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.rotate--90 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.rotate--180 {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.rotate--270 {
  -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg);
}

.rotate-0 {
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}

.scaleX--1 {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.scaleY--1 {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}

.row-bordered {
  overflow: hidden;
}
.row-bordered > .col,
.row-bordered > [class^="col-"],
.row-bordered > [class*=" col-"],
.row-bordered > [class^="col "],
.row-bordered > [class*=" col "],
.row-bordered > [class$=" col"],
.row-bordered > [class="col"] {
  position: relative;
  padding-top: 1px;
}
.row-bordered > .col::before,
.row-bordered > [class^="col-"]::before,
.row-bordered > [class*=" col-"]::before,
.row-bordered > [class^="col "]::before,
.row-bordered > [class*=" col "]::before,
.row-bordered > [class$=" col"]::before,
.row-bordered > [class="col"]::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  display: block;
  height: 0;
  border-top: 1px solid rgba(24, 28, 33, 0.06);
}
.row-bordered > .col::after,
.row-bordered > [class^="col-"]::after,
.row-bordered > [class*=" col-"]::after,
.row-bordered > [class^="col "]::after,
.row-bordered > [class*=" col "]::after,
.row-bordered > [class$=" col"]::after,
.row-bordered > [class="col"]::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  display: block;
  width: 0;
  border-left: 1px solid rgba(24, 28, 33, 0.06);
}
.row-bordered.row-border-light > .col::before, .row-bordered.row-border-light > .col::after,
.row-bordered.row-border-light > [class^="col-"]::before,
.row-bordered.row-border-light > [class^="col-"]::after,
.row-bordered.row-border-light > [class*=" col-"]::before,
.row-bordered.row-border-light > [class*=" col-"]::after,
.row-bordered.row-border-light > [class^="col "]::before,
.row-bordered.row-border-light > [class^="col "]::after,
.row-bordered.row-border-light > [class*=" col "]::before,
.row-bordered.row-border-light > [class*=" col "]::after,
.row-bordered.row-border-light > [class$=" col"]::before,
.row-bordered.row-border-light > [class$=" col"]::after,
.row-bordered.row-border-light > [class="col"]::before,
.row-bordered.row-border-light > [class="col"]::after {
  border-color: rgba(24, 28, 33, 0.06);
}

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }
}
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }
}
.flex-truncate {
  min-width: 0 !important;
}

.flex-basis-100 {
  -ms-flex-preferred-size: 100% !important;
      flex-basis: 100% !important;
}

.flex-basis-auto {
  -ms-flex-preferred-size: auto !important;
      flex-basis: auto !important;
}

@media (min-width: 576px) {
  .flex-basis-sm-100 {
    -ms-flex-preferred-size: 100% !important;
        flex-basis: 100% !important;
  }

  .flex-basis-sm-auto {
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
  }
}
@media (min-width: 768px) {
  .flex-basis-md-100 {
    -ms-flex-preferred-size: 100% !important;
        flex-basis: 100% !important;
  }

  .flex-basis-md-auto {
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
  }
}
@media (min-width: 992px) {
  .flex-basis-lg-100 {
    -ms-flex-preferred-size: 100% !important;
        flex-basis: 100% !important;
  }

  .flex-basis-lg-auto {
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
  }
}
@media (min-width: 1200px) {
  .flex-basis-xl-100 {
    -ms-flex-preferred-size: 100% !important;
        flex-basis: 100% !important;
  }

  .flex-basis-xl-auto {
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
  }
}
.d-flex,
.d-inline-flex,
.media,
.media > :not(.media-body),
.jumbotron,
.card {
  -ms-flex-negative: 1;
      flex-shrink: 1;
}

.ie-mh-1 {
  min-height: 1px;
}

.text-dark {
  color: #4E5155 !important;
}
.text-dark[href]:hover {
  color: #3c8ae2 !important;
}
.text-dark[href]:focus:not(:hover) {
  color: #4E5155 !important;
}

.text-muted[href]:hover, .text-muted[href]:focus {
  color: #8c8e90 !important;
}

.text-light {
  color: #babbbc !important;
}
.text-light[href]:hover, .text-light[href]:focus {
  color: #8c8e90 !important;
}

.text-lighter {
  color: #d1d2d3 !important;
}
.text-lighter[href]:hover, .text-lighter[href]:focus {
  color: #8c8e90 !important;
}

.text-lightest {
  color: #e8e8e9 !important;
}
.text-lightest[href]:hover, .text-lightest[href]:focus {
  color: #8c8e90 !important;
}

.bg-dark {
  background-color: rgba(24, 28, 33, 0.9) !important;
}

a.bg-dark:hover, a.bg-dark:focus {
  background-color: rgba(24, 28, 33, 0.9) !important;
}

.bg-light {
  background-color: rgba(24, 28, 33, 0.06) !important;
}

a.bg-light:hover, a.bg-light:focus {
  background-color: rgba(24, 28, 33, 0.1) !important;
}

.bg-lighter {
  background-color: rgba(24, 28, 33, 0.03) !important;
}

a.bg-lighter:hover, a.bg-lighter:focus {
  background-color: rgba(24, 28, 33, 0.06) !important;
}

.bg-lightest {
  background-color: rgba(24, 28, 33, 0.015) !important;
}

a.bg-lightest:hover, a.bg-lightest:focus {
  background-color: rgba(24, 28, 33, 0.03) !important;
}

.border-light {
  border-color: rgba(24, 28, 33, 0.06) !important;
}

.border-dark {
  border-color: rgba(24, 28, 33, 0.9) !important;
}

.border-transparent {
  border-color: transparent !important;
}

body {
  -moz-font-feature-settings: "liga" on;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  button.list-group-item {
    outline: none !important;
  }
}
.waves-ripple {
  position: absolute !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card,
  .card-body,
  .flex-column,
  .media,
  .tab-content {
    min-height: 1px;
  }

  img {
    min-height: 1px;
    height: auto;
  }
}
.container,
.container-fluid {
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 992px) {
  .container,
  .container-fluid {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.img-thumbnail {
  position: relative;
  display: block;
}

.img-thumbnail img {
  z-index: 1;
}

.img-thumbnail-content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  display: block;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.img-thumbnail:focus .img-thumbnail-content, .img-thumbnail:hover .img-thumbnail-content {
  opacity: 1;
}

.img-thumbnail-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.img-thumbnail:not(:hover):not(:focus) .img-thumbnail-overlay {
  opacity: 0 !important;
}

.img-thumbnail-shadow {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.img-thumbnail-shadow:hover, .img-thumbnail-shadow:focus {
  -webkit-box-shadow: 0 5px 20px rgba(24, 28, 33, 0.4);
          box-shadow: 0 5px 20px rgba(24, 28, 33, 0.4);
}

.img-thumbnail-zoom-in {
  overflow: hidden;
}
.img-thumbnail-zoom-in img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate3d(0);
          transform: translate3d(0);
}
.img-thumbnail-zoom-in .img-thumbnail-content {
  -webkit-transform: translate(-50%, -50%) scale(0.5);
          transform: translate(-50%, -50%) scale(0.5);
}
.img-thumbnail-zoom-in:hover img, .img-thumbnail-zoom-in:focus img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.img-thumbnail-zoom-in:hover .img-thumbnail-content, .img-thumbnail-zoom-in:focus .img-thumbnail-content {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

@-webkit-keyframes dropdownAnimation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}
@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes topTooltipAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes topTooltipAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes bottomTooltipAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes bottomTooltipAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes leftTooltipAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes leftTooltipAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes rightTooltipAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes rightTooltipAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.table .table-secondary,
.table .table-secondary > th,
.table .table-secondary > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #f3f5f7;
  color: #616263;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f1f3f5;
}

.table .table-success,
.table .table-success > th,
.table .table-success > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #eafaf4;
  color: #5e6462;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #e8f8f2;
}

.table .table-info,
.table .table-info > th,
.table .table-info > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #e9f5fe;
  color: #5d6266;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #e7f3fb;
}

.table .table-warning,
.table .table-warning > th,
.table .table-warning > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #fff4ed;
  color: #66625f;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fcf2eb;
}

.table .table-danger,
.table .table-danger > th,
.table .table-danger > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #ffedef;
  color: #665f60;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fcebed;
}

.table .table-dark,
.table .table-dark > th,
.table .table-dark > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #eaeaec;
  color: #5e5e5e;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #e8e8ea;
}

.table.table-fixed {
  table-layout: fixed;
}

.table td,
.table th {
  white-space: nowrap;
}
.table th {
  font-weight: 500;
}

.table-responsive > .table {
  max-width: none;
}

.table-active,
.table-active > td,
.table-active > th {
  border-color: rgba(24, 28, 33, 0.035);
  background-color: #f7f7f7;
}

.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: whitesmoke;
}

.card-table {
  margin-bottom: 0;
}

.card-table,
.card-table td,
.card-table th {
  border-color: rgba(24, 28, 33, 0.06);
}

.card-table > tbody:first-child > tr:first-child > th,
.card-table > tbody:first-child > tr:first-child > td,
.card-table > tfoot:first-child > tr:first-child > th,
.card-table > tfoot:first-child > tr:first-child > td,
.card-table > thead:first-child > tr:first-child > th,
.card-table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.card-table > tbody > tr > th:first-child,
.card-table > tbody > tr > td:first-child,
.card-table > tfoot > tr > th:first-child,
.card-table > tfoot > tr > td:first-child,
.card-table > thead > tr > th:first-child,
.card-table > thead > tr > td:first-child {
  padding-left: 1.5rem;
  border-left: 0;
}
.card-table > tbody > tr > th:last-child,
.card-table > tbody > tr > td:last-child,
.card-table > tfoot > tr > th:last-child,
.card-table > tfoot > tr > td:last-child,
.card-table > thead > tr > th:last-child,
.card-table > thead > tr > td:last-child {
  padding-right: 1.5rem;
  border-right: 0;
}

.card-condenced .card-table > tbody > tr > th:first-child,
.card-condenced .card-table > tbody > tr > td:first-child,
.card-condenced .card-table > tfoot > tr > th:first-child,
.card-condenced .card-table > tfoot > tr > td:first-child,
.card-condenced .card-table > thead > tr > th:first-child,
.card-condenced .card-table > thead > tr > td:first-child {
  padding-left: 1rem;
  border-left: 0;
}
.card-condenced .card-table > tbody > tr > th:last-child,
.card-condenced .card-table > tbody > tr > td:last-child,
.card-condenced .card-table > tfoot > tr > th:last-child,
.card-condenced .card-table > tfoot > tr > td:last-child,
.card-condenced .card-table > thead > tr > th:last-child,
.card-condenced .card-table > thead > tr > td:last-child {
  padding-right: 1rem;
  border-right: 0;
}

.btn {
  font-size: 0.8125rem;
  text-transform: uppercase;
}

.btn .badge {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn .badge {
    -webkit-transition: none;
    transition: none;
  }
}

label.btn {
  margin-bottom: 0;
}

.btn-secondary {
  background: #8897AA;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-secondary:hover {
  background: #8e9cae;
  color: #fff;
}
.btn-secondary:hover, .btn-secondary:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-secondary .badge {
  background: #fff;
  color: #8897AA;
}

.btn-secondary .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  background: #97a5b5;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-secondary.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #8897AA !important;
}
.btn-secondary.md-btn-flat:focus, .btn-secondary.md-btn-flat.focus {
  background: rgba(136, 151, 170, 0.1) !important;
}
.btn-secondary.md-btn-flat .waves-ripple {
  background: rgba(136, 151, 170, 0.3) !important;
}

.btn-secondary.md-btn-flat:active,
.btn-secondary.md-btn-flat.active,
.show > .btn-secondary.md-btn-flat.dropdown-toggle {
  background: rgba(136, 151, 170, 0.15) !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background: #8897AA !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-secondary.md-btn-flat.disabled,
.btn-secondary.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #8897AA !important;
}

.btn-outline-secondary {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #8897AA inset;
          box-shadow: 0 0 0 1px #8897AA inset;
  color: #8897AA;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus {
  background: #8897AA;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-secondary:hover .badge, .btn-outline-secondary:focus .badge {
  background: #fff;
  color: #8897AA;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #8897AA inset !important;
          box-shadow: 0 0 0 1px #8897AA inset !important;
  color: #8897AA !important;
}

.btn-outline-secondary .badge {
  background: #8897AA;
  color: #fff;
}

.btn-outline-secondary .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  background: #8e9cae;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-secondary:active .badge,
.btn-outline-secondary.active .badge,
.show > .btn-outline-secondary.dropdown-toggle .badge {
  background: #fff;
  color: #8897AA;
}

.btn-success {
  background: #28D094;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-success:hover {
  background: #33d299;
  color: #fff;
}
.btn-success:hover, .btn-success:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-success .badge {
  background: #fff;
  color: #28D094;
}

.btn-success .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  background: #44d6a2;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-success.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #28D094 !important;
}
.btn-success.md-btn-flat:focus, .btn-success.md-btn-flat.focus {
  background: rgba(40, 208, 148, 0.1) !important;
}
.btn-success.md-btn-flat .waves-ripple {
  background: rgba(40, 208, 148, 0.3) !important;
}

.btn-success.md-btn-flat:active,
.btn-success.md-btn-flat.active,
.show > .btn-success.md-btn-flat.dropdown-toggle {
  background: rgba(40, 208, 148, 0.15) !important;
}

.btn-success.disabled,
.btn-success:disabled {
  background: #28D094 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-success.md-btn-flat.disabled,
.btn-success.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #28D094 !important;
}

.btn-outline-success {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #28D094 inset;
          box-shadow: 0 0 0 1px #28D094 inset;
  color: #28D094;
}
.btn-outline-success:hover, .btn-outline-success:focus {
  background: #28D094;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-success:hover .badge, .btn-outline-success:focus .badge {
  background: #fff;
  color: #28D094;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #28D094 inset !important;
          box-shadow: 0 0 0 1px #28D094 inset !important;
  color: #28D094 !important;
}

.btn-outline-success .badge {
  background: #28D094;
  color: #fff;
}

.btn-outline-success .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-success:active,
.btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  background: #33d299;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-success:active .badge,
.btn-outline-success.active .badge,
.show > .btn-outline-success.dropdown-toggle .badge {
  background: #fff;
  color: #28D094;
}

.btn-info {
  background: #1E9FF2;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-info:hover {
  background: #29a4f3;
  color: #fff;
}
.btn-info:hover, .btn-info:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-info .badge {
  background: #fff;
  color: #1E9FF2;
}

.btn-info .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  background: #3babf4;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-info.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #1E9FF2 !important;
}
.btn-info.md-btn-flat:focus, .btn-info.md-btn-flat.focus {
  background: rgba(30, 159, 242, 0.1) !important;
}
.btn-info.md-btn-flat .waves-ripple {
  background: rgba(30, 159, 242, 0.3) !important;
}

.btn-info.md-btn-flat:active,
.btn-info.md-btn-flat.active,
.show > .btn-info.md-btn-flat.dropdown-toggle {
  background: rgba(30, 159, 242, 0.15) !important;
}

.btn-info.disabled,
.btn-info:disabled {
  background: #1E9FF2 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-info.md-btn-flat.disabled,
.btn-info.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #1E9FF2 !important;
}

.btn-outline-info {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #1E9FF2 inset;
          box-shadow: 0 0 0 1px #1E9FF2 inset;
  color: #1E9FF2;
}
.btn-outline-info:hover, .btn-outline-info:focus {
  background: #1E9FF2;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-info:hover .badge, .btn-outline-info:focus .badge {
  background: #fff;
  color: #1E9FF2;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #1E9FF2 inset !important;
          box-shadow: 0 0 0 1px #1E9FF2 inset !important;
  color: #1E9FF2 !important;
}

.btn-outline-info .badge {
  background: #1E9FF2;
  color: #fff;
}

.btn-outline-info .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-info:active,
.btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  background: #29a4f3;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-info:active .badge,
.btn-outline-info.active .badge,
.show > .btn-outline-info.dropdown-toggle .badge {
  background: #fff;
  color: #1E9FF2;
}

.btn-warning {
  background: #FF9149;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #663a1d;
}
.btn-warning:hover {
  background: #ff9752;
  color: #663a1d;
}
.btn-warning:hover, .btn-warning:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-warning .badge {
  background: #663a1d;
  color: #FF9149;
}

.btn-warning .waves-ripple {
  background: rgba(102, 58, 29, 0.2) !important;
}

.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  background: #ff9f61;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-warning.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #FF9149 !important;
}
.btn-warning.md-btn-flat:focus, .btn-warning.md-btn-flat.focus {
  background: rgba(255, 145, 73, 0.1) !important;
}
.btn-warning.md-btn-flat .waves-ripple {
  background: rgba(255, 145, 73, 0.3) !important;
}

.btn-warning.md-btn-flat:active,
.btn-warning.md-btn-flat.active,
.show > .btn-warning.md-btn-flat.dropdown-toggle {
  background: rgba(255, 145, 73, 0.15) !important;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background: #FF9149 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #663a1d !important;
}

.btn-warning.md-btn-flat.disabled,
.btn-warning.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #FF9149 !important;
}

.btn-outline-warning {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #FF9149 inset;
          box-shadow: 0 0 0 1px #FF9149 inset;
  color: #FF9149;
}
.btn-outline-warning:hover, .btn-outline-warning:focus {
  background: #FF9149;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #663a1d;
}
.btn-outline-warning:hover .badge, .btn-outline-warning:focus .badge {
  background: #663a1d;
  color: #FF9149;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #FF9149 inset !important;
          box-shadow: 0 0 0 1px #FF9149 inset !important;
  color: #FF9149 !important;
}

.btn-outline-warning .badge {
  background: #FF9149;
  color: #fff;
}

.btn-outline-warning .waves-ripple {
  background: rgba(102, 58, 29, 0.2) !important;
}

.btn-outline-warning:active,
.btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  background: #ff9752;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #663a1d;
}
.btn-outline-warning:active .badge,
.btn-outline-warning.active .badge,
.show > .btn-outline-warning.dropdown-toggle .badge {
  background: #663a1d;
  color: #FF9149;
}

.btn-danger {
  background: #FF4961;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-danger:hover {
  background: #ff5269;
  color: #fff;
}
.btn-danger:hover, .btn-danger:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-danger .badge {
  background: #fff;
  color: #FF4961;
}

.btn-danger .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  background: #ff6176;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-danger.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #FF4961 !important;
}
.btn-danger.md-btn-flat:focus, .btn-danger.md-btn-flat.focus {
  background: rgba(255, 73, 97, 0.1) !important;
}
.btn-danger.md-btn-flat .waves-ripple {
  background: rgba(255, 73, 97, 0.3) !important;
}

.btn-danger.md-btn-flat:active,
.btn-danger.md-btn-flat.active,
.show > .btn-danger.md-btn-flat.dropdown-toggle {
  background: rgba(255, 73, 97, 0.15) !important;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background: #FF4961 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-danger.md-btn-flat.disabled,
.btn-danger.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #FF4961 !important;
}

.btn-outline-danger {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #FF4961 inset;
          box-shadow: 0 0 0 1px #FF4961 inset;
  color: #FF4961;
}
.btn-outline-danger:hover, .btn-outline-danger:focus {
  background: #FF4961;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-danger:hover .badge, .btn-outline-danger:focus .badge {
  background: #fff;
  color: #FF4961;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #FF4961 inset !important;
          box-shadow: 0 0 0 1px #FF4961 inset !important;
  color: #FF4961 !important;
}

.btn-outline-danger .badge {
  background: #FF4961;
  color: #fff;
}

.btn-outline-danger .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-danger:active,
.btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  background: #ff5269;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-danger:active .badge,
.btn-outline-danger.active .badge,
.show > .btn-outline-danger.dropdown-toggle .badge {
  background: #fff;
  color: #FF4961;
}

.btn-light {
  background: rgba(24, 28, 33, 0.06);
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #0a0b0d;
}
.btn-light:hover {
  background: #f2f2f3;
  color: #0a0b0d;
}
.btn-light:hover, .btn-light:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-light .badge {
  background: #0a0b0d;
  color: #f1f1f2;
}

.btn-light .waves-ripple {
  background: rgba(10, 11, 13, 0.2) !important;
}

.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  background: #f3f3f4;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-light.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: rgba(24, 28, 33, 0.06) !important;
}
.btn-light.md-btn-flat:focus, .btn-light.md-btn-flat.focus {
  background: rgba(24, 28, 33, 0.1) !important;
}
.btn-light.md-btn-flat .waves-ripple {
  background: rgba(24, 28, 33, 0.3) !important;
}

.btn-light.md-btn-flat:active,
.btn-light.md-btn-flat.active,
.show > .btn-light.md-btn-flat.dropdown-toggle {
  background: rgba(24, 28, 33, 0.15) !important;
}

.btn-light.disabled,
.btn-light:disabled {
  background: rgba(24, 28, 33, 0.06) !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #0a0b0d !important;
}

.btn-light.md-btn-flat.disabled,
.btn-light.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: rgba(24, 28, 33, 0.06) !important;
}

.btn-outline-light {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.06) inset;
          box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.06) inset;
  color: rgba(24, 28, 33, 0.06);
}
.btn-outline-light:hover, .btn-outline-light:focus {
  background: rgba(24, 28, 33, 0.06);
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #0a0b0d;
}
.btn-outline-light:hover .badge, .btn-outline-light:focus .badge {
  background: #0a0b0d;
  color: #fff;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.06) inset !important;
          box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.06) inset !important;
  color: rgba(24, 28, 33, 0.06) !important;
}

.btn-outline-light .badge {
  background: rgba(24, 28, 33, 0.06);
  color: #fff;
}

.btn-outline-light .waves-ripple {
  background: rgba(10, 11, 13, 0.2) !important;
}

.btn-outline-light:active,
.btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
  background: #f2f2f3;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #0a0b0d;
}
.btn-outline-light:active .badge,
.btn-outline-light.active .badge,
.show > .btn-outline-light.dropdown-toggle .badge {
  background: #0a0b0d;
  color: #fff;
}

.btn-dark {
  background: #2c2e3e;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-dark:hover {
  background: #373848;
  color: #fff;
}
.btn-dark:hover, .btn-dark:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-dark .badge {
  background: #fff;
  color: #2c2e3e;
}

.btn-dark .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  background: #474957;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-dark.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #2c2e3e !important;
}
.btn-dark.md-btn-flat:focus, .btn-dark.md-btn-flat.focus {
  background: rgba(44, 46, 62, 0.1) !important;
}
.btn-dark.md-btn-flat .waves-ripple {
  background: rgba(44, 46, 62, 0.3) !important;
}

.btn-dark.md-btn-flat:active,
.btn-dark.md-btn-flat.active,
.show > .btn-dark.md-btn-flat.dropdown-toggle {
  background: rgba(44, 46, 62, 0.15) !important;
}

.btn-dark.disabled,
.btn-dark:disabled {
  background: #2c2e3e !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-dark.md-btn-flat.disabled,
.btn-dark.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #2c2e3e !important;
}

.btn-outline-dark {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #2c2e3e inset;
          box-shadow: 0 0 0 1px #2c2e3e inset;
  color: #2c2e3e;
}
.btn-outline-dark:hover, .btn-outline-dark:focus {
  background: #2c2e3e;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-dark:hover .badge, .btn-outline-dark:focus .badge {
  background: #fff;
  color: #2c2e3e;
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #2c2e3e inset !important;
          box-shadow: 0 0 0 1px #2c2e3e inset !important;
  color: #2c2e3e !important;
}

.btn-outline-dark .badge {
  background: #2c2e3e;
  color: #fff;
}

.btn-outline-dark .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-dark:active,
.btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
  background: #373848;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-dark:active .badge,
.btn-outline-dark.active .badge,
.show > .btn-outline-dark.dropdown-toggle .badge {
  background: #fff;
  color: #2c2e3e;
}

.btn-default {
  background: #fff;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #4E5155;
}
.btn-default:hover {
  background: white;
  color: #4E5155;
}
.btn-default:hover, .btn-default:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-default .badge {
  background: #4E5155;
  color: #fff;
}

.btn-default .waves-ripple {
  background: rgba(78, 81, 85, 0.2) !important;
}

.btn-default:active,
.btn-default.active,
.show > .btn-default.dropdown-toggle {
  background: white;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-default.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #4E5155 !important;
}
.btn-default.md-btn-flat:focus, .btn-default.md-btn-flat.focus {
  background: rgba(78, 81, 85, 0.1) !important;
}
.btn-default.md-btn-flat .waves-ripple {
  background: rgba(78, 81, 85, 0.3) !important;
}

.btn-default.md-btn-flat:active,
.btn-default.md-btn-flat.active,
.show > .btn-default.md-btn-flat.dropdown-toggle {
  background: rgba(78, 81, 85, 0.15) !important;
}

.btn-default.disabled,
.btn-default:disabled {
  background: #fff !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #4E5155 !important;
}

.btn-default.md-btn-flat.disabled,
.btn-default.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #fff !important;
}

.btn-outline-default {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset;
          box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset;
  color: rgba(24, 28, 33, 0.1);
}
.btn-outline-default:hover, .btn-outline-default:focus {
  background: rgba(24, 28, 33, 0.1);
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #4E5155;
}
.btn-outline-default:hover .badge, .btn-outline-default:focus .badge {
  background: #4E5155;
  color: #fff;
}
.btn-outline-default.disabled, .btn-outline-default:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset !important;
          box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset !important;
  color: rgba(24, 28, 33, 0.1) !important;
}

.btn-outline-default .badge {
  background: rgba(24, 28, 33, 0.1);
  color: #fff;
}

.btn-outline-default .waves-ripple {
  background: rgba(78, 81, 85, 0.2) !important;
}

.btn-outline-default:active,
.btn-outline-default.active,
.show > .btn-outline-default.dropdown-toggle {
  background: #e9e9ea;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #4E5155;
}
.btn-outline-default:active .badge,
.btn-outline-default.active .badge,
.show > .btn-outline-default.dropdown-toggle .badge {
  background: #4E5155;
  color: #fff;
}

.btn-xl, .btn-group-xl > .btn {
  padding: 0.9375rem 2.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.125rem;
}

.btn-lg {
  font-size: 1rem;
}

.btn-sm {
  font-size: 0.6875rem;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 0.0625rem 0.5rem;
  font-size: 0.6875rem;
  line-height: 1.55;
  border-radius: 0.125rem;
}

.btn-round {
  border-radius: 60rem !important;
}

.icon-btn {
  width: calc(2.194375rem + 0px) !important;
  padding: 5px 10px !important;
  line-height: 2.194375rem;
}
.icon-btn.btn-xl, .btn-group-xl > .icon-btn.btn {
  width: calc(3.75rem + 0px) !important;
  line-height: 3.75rem;
}
.icon-btn.btn-lg {
  width: calc(3.125rem + 0px) !important;
  line-height: 3.125rem;
}
.icon-btn.btn-sm {
  width: calc(1.565625rem + 0px) !important;
  line-height: 1.565625rem;
}
.icon-btn.btn-xs, .btn-group-xs > .icon-btn.btn {
  width: calc(1.190625rem + 0px) !important;
  line-height: 1.190625rem;
}

.btn.borderless:not(.active):not(:active):not(:hover):not(:focus), :not(.show) > .btn.borderless.dropdown-toggle:not(:hover):not(:focus) {
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.btn-group-sm > .btn {
  font-size: 0.6875rem;
}

.btn-group-lg > .btn {
  font-size: 1rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-group-xl > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split,
.btn-xl + .dropdown-toggle-split,
.btn-group-xl > .btn + .dropdown-toggle-split,
.dropdown-toggle-split,
.input-group-lg .btn + .dropdown-toggle-split {
  padding-right: 0.7em;
  padding-left: 0.7em;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split,
.input-group-sm .btn + .dropdown-toggle-split {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.btn-group-xs > .btn + .dropdown-toggle-split,
.btn-xs + .dropdown-toggle-split,
.btn-group-xs > .btn + .dropdown-toggle-split {
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.badge-secondary {
  background-color: #8897AA;
  color: #fff;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  background-color: #818fa2;
  color: #fff;
  text-decoration: none;
}

.btn .badge-secondary {
  background-color: #8897AA !important;
  color: #fff !important;
}

.badge-outline-secondary {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px #8897AA inset;
          box-shadow: 0 0 0 1px #8897AA inset;
  color: #8897AA;
}

.badge-outline-secondary[href]:hover, .badge-outline-secondary[href]:focus {
  color: #8897AA;
  text-decoration: none;
}

.btn .badge-outline-secondary {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #8897AA inset !important;
          box-shadow: 0 0 0 1px #8897AA inset !important;
  color: #8897AA !important;
}

.badge-success {
  background-color: #28D094;
  color: #fff;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  background-color: #26c68d;
  color: #fff;
  text-decoration: none;
}

.btn .badge-success {
  background-color: #28D094 !important;
  color: #fff !important;
}

.badge-outline-success {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px #28D094 inset;
          box-shadow: 0 0 0 1px #28D094 inset;
  color: #28D094;
}

.badge-outline-success[href]:hover, .badge-outline-success[href]:focus {
  color: #28D094;
  text-decoration: none;
}

.btn .badge-outline-success {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #28D094 inset !important;
          box-shadow: 0 0 0 1px #28D094 inset !important;
  color: #28D094 !important;
}

.badge-info {
  background-color: #1E9FF2;
  color: #fff;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  background-color: #1d97e6;
  color: #fff;
  text-decoration: none;
}

.btn .badge-info {
  background-color: #1E9FF2 !important;
  color: #fff !important;
}

.badge-outline-info {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px #1E9FF2 inset;
          box-shadow: 0 0 0 1px #1E9FF2 inset;
  color: #1E9FF2;
}

.badge-outline-info[href]:hover, .badge-outline-info[href]:focus {
  color: #1E9FF2;
  text-decoration: none;
}

.btn .badge-outline-info {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #1E9FF2 inset !important;
          box-shadow: 0 0 0 1px #1E9FF2 inset !important;
  color: #1E9FF2 !important;
}

.badge-warning {
  background-color: #FF9149;
  color: #663a1d;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  background-color: #f28a45;
  color: #663a1d;
  text-decoration: none;
}

.btn .badge-warning {
  background-color: #FF9149 !important;
  color: #663a1d !important;
}

.badge-outline-warning {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px #FF9149 inset;
          box-shadow: 0 0 0 1px #FF9149 inset;
  color: #FF9149;
}

.badge-outline-warning[href]:hover, .badge-outline-warning[href]:focus {
  color: #FF9149;
  text-decoration: none;
}

.btn .badge-outline-warning {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #FF9149 inset !important;
          box-shadow: 0 0 0 1px #FF9149 inset !important;
  color: #FF9149 !important;
}

.badge-danger {
  background-color: #FF4961;
  color: #fff;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  background-color: #f2455c;
  color: #fff;
  text-decoration: none;
}

.btn .badge-danger {
  background-color: #FF4961 !important;
  color: #fff !important;
}

.badge-outline-danger {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px #FF4961 inset;
          box-shadow: 0 0 0 1px #FF4961 inset;
  color: #FF4961;
}

.badge-outline-danger[href]:hover, .badge-outline-danger[href]:focus {
  color: #FF4961;
  text-decoration: none;
}

.btn .badge-outline-danger {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #FF4961 inset !important;
          box-shadow: 0 0 0 1px #FF4961 inset !important;
  color: #FF4961 !important;
}

.badge-dark {
  background-color: #2c2e3e;
  color: #fff;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  background-color: #2a2c3b;
  color: #fff;
  text-decoration: none;
}

.btn .badge-dark {
  background-color: #2c2e3e !important;
  color: #fff !important;
}

.badge-outline-dark {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px #2c2e3e inset;
          box-shadow: 0 0 0 1px #2c2e3e inset;
  color: #2c2e3e;
}

.badge-outline-dark[href]:hover, .badge-outline-dark[href]:focus {
  color: #2c2e3e;
  text-decoration: none;
}

.btn .badge-outline-dark {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #2c2e3e inset !important;
          box-shadow: 0 0 0 1px #2c2e3e inset !important;
  color: #2c2e3e !important;
}

.badge-default {
  background-color: rgba(24, 28, 33, 0.1);
  color: #4E5155;
}

.badge-default[href]:hover, .badge-default[href]:focus {
  background-color: rgba(24, 28, 33, 0.13);
  color: #4E5155;
  text-decoration: none;
}

.btn .badge-default {
  background-color: rgba(24, 28, 33, 0.1) !important;
  color: #4E5155 !important;
}

.badge-outline-default {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset;
          box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset;
  color: #4E5155;
}

.badge-outline-default[href]:hover, .badge-outline-default[href]:focus {
  color: #4E5155;
  text-decoration: none;
}

.btn .badge-outline-default {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset !important;
          box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset !important;
  color: #4E5155 !important;
}

.btn .badge {
  margin: 0 5px;
}

.badge.badge-dot {
  display: inline-block;
  margin: 0 3px 0 8px !important;
  padding: 0;
  width: .5em;
  height: .5em;
  border-radius: 50%;
  vertical-align: middle;
}

.badge.indicator {
  position: absolute;
  top: auto;
  display: inline-block;
  margin: 0;
  -webkit-transform: translate(-50%, 25%);
          transform: translate(-50%, 25%);
}
.badge.indicator:not(.badge-dot) {
  padding: 0 .2em;
  font-size: .6em;
  line-height: 1.2em;
  -webkit-transform: translate(-50%, -10%);
          transform: translate(-50%, -10%);
}

.btn .badge.indicator:not(.badge-dot) {
  font-size: .75em;
}

[data-trigger=hover] {
  outline: 0;
}

.dropdown-menu {
  margin: 0.125rem 0;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-animation: dropdownAnimation .25s;
          animation: dropdownAnimation .25s;
}
.dropdown-menu.dropdown-menu-right, .dropdown-menu.dropdown-menu-right .dropdown-menu {
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
.dropup .dropdown-menu {
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}
.dropup .dropdown-menu.dropdown-menu-right, .dropup .dropdown-menu.dropdown-menu-right .dropdown-menu {
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}
.mega-dropdown > .dropdown-menu {
  left: 0 !important;
  right: 0 !important;
}

.dropdown-item {
  line-height: 1.54;
}

.dropdown-menu .badge[class^="float-"],
.dropdown-menu .badge[class*=" float-"] {
  position: relative;
  top: .071em;
}

.dropdown-item,
.dropdown-menu > li {
  display: block !important;
}
.dropdown-item .waves-ripple,
.dropdown-menu > li .waves-ripple {
  background: rgba(0, 0, 0, 0.1) !important;
}
.dropdown-item.active .waves-ripple, .dropdown-item.disabled .waves-ripple,
.dropdown-menu > li.active .waves-ripple,
.dropdown-menu > li.disabled .waves-ripple {
  display: none !important;
}

.dropdown-toggle.hide-arrow::before, .dropdown-toggle.hide-arrow::after,
.dropdown-toggle-hide-arrow > .dropdown-toggle::before,
.dropdown-toggle-hide-arrow > .dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item) {
  display: block;
  clear: both;
  padding: 0.5rem 1.25rem;
  width: 100%;
  border: 0;
  background: none;
  color: #4E5155;
  text-align: inherit;
  white-space: nowrap;
  font-weight: 400;
  line-height: 1.54;
}
.dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item):hover, .dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item):focus {
  background-color: rgba(24, 28, 33, 0.03);
  color: #4E5155;
  text-decoration: none;
}
.dropdown-menu > li:not(.dropdown-item).active > a:not(.dropdown-item),
.dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item):active {
  background-color: rgba(24, 28, 33, 0.03);
  color: #4E5155;
  text-decoration: none;
}
.dropdown-menu > li:not(.dropdown-item).disabled > a:not(.dropdown-item) {
  background-color: transparent;
  color: #d1d2d3;
}

.dropdown-toggle::after, .dropdown-toggle::before {
  vertical-align: middle !important;
}
.dropdown-toggle::after, .dropup .dropdown-toggle::after, .dropright .dropdown-toggle::after {
  margin-left: .5em;
}
.dropleft .dropdown-toggle::before {
  margin-right: .5em;
}

.dropdown-toggle-split::after {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.dropdown-menu .dropdown-toggle {
  position: relative;
}
.dropdown-menu .dropdown-toggle::after {
  display: none;
}

.dropdown-menu .dropdown-toggle > .dropdown-item {
  position: relative;
  cursor: default;
}

@media (max-width: 767px) {
  .dropdown-menu .dropdown-menu {
    position: static;
    display: block;
    margin: 0;
    padding-left: 1.25rem;
    width: 100%;
    border-width: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
@media (min-width: 768px) {
  .dropdown-menu .dropdown-toggle > .dropdown-item::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0.625rem;
    display: block;
    width: .375rem;
    height: .375rem;
    border: 1px solid #a3a4a6;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(-45deg) translate(0, -50%);
            transform: rotate(-45deg) translate(0, -50%);
  }

  .dropdown-menu .dropdown-toggle:hover > .dropdown-item:not(.disabled) {
    background: rgba(24, 28, 33, 0.03);
    color: #4E5155;
  }
  .dropdown-menu .dropdown-toggle:hover > .dropdown-item:not(.disabled) + .dropdown-menu {
    display: block;
  }

  .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%;
  }

  .dropdown-menu-right .dropdown-menu {
    right: 100%;
    left: auto;
  }
}
.nav-link {
  line-height: 1.47;
}
.nav-link.disabled {
  border-color: transparent !important;
  background: transparent !important;
  color: #d1d2d3 !important;
}

.nav-pills .nav-link,
.nav-tabs .nav-link {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8125rem;
}
.nav-pills .nav-link:not(.active),
.nav-tabs .nav-link:not(.active) {
  color: #8c8e90;
}
.nav-pills .nav-link:not(.active):hover, .nav-pills .nav-link:not(.active):focus,
.nav-tabs .nav-link:not(.active):hover,
.nav-tabs .nav-link:not(.active):focus {
  color: #4E5155;
}
.nav-pills:not(.nav-fill):not(.nav-justified) .nav-link,
.nav-tabs:not(.nav-fill):not(.nav-justified) .nav-link {
  margin-right: 0.125rem;
}
.nav-pills .nav-link .badge,
.nav-tabs .nav-link .badge {
  position: relative;
  top: -1px;
}
.nav-pills .nav-item.show .nav-link:not(.active),
.nav-tabs .nav-item.show .nav-link:not(.active) {
  background-color: transparent;
}

.nav-tabs {
  border: 0;
}

.nav-tabs .nav-item {
  margin: 0 !important;
}

.nav-tabs .nav-link {
  border: 0;
  background-color: transparent;
  background-position: bottom;
  background-size: 0 2px;
  background-repeat: no-repeat;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  background-size: 100% 2px;
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
}

.nav-tabs .nav-link {
  background-clip: padding-box;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-bottom-color: transparent;
}
.nav-tabs .nav-link.active {
  border-bottom-color: #fff;
}

.nav-tabs.tabs-alt .nav-link,
.tabs-alt > .nav-tabs .nav-link {
  border-width: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.md-pills-light > .nav-pills .nav-link,
.md-pills-light > div > .nav-pills .nav-link,
.md-tabs-light > .nav-tabs .nav-link,
.md-tabs-light > div > .nav-tabs .nav-link,
.nav-pills.md-pills-light .nav-link,
.nav-tabs.md-tabs-light .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.md-pills-light > .nav-pills .nav-item.show .nav-link,
.md-pills-light > .nav-pills .nav-link.focus,
.md-pills-light > .nav-pills .nav-link:focus,
.md-pills-light > .nav-pills .nav-link:hover,
.md-pills-light > div > .nav-pills .nav-item.show .nav-link,
.md-pills-light > div > .nav-pills .nav-link.focus,
.md-pills-light > div > .nav-pills .nav-link:focus,
.md-pills-light > div > .nav-pills .nav-link:hover,
.md-tabs-light > .nav-tabs .nav-item.show .nav-link,
.md-tabs-light > .nav-tabs .nav-link.focus,
.md-tabs-light > .nav-tabs .nav-link:focus,
.md-tabs-light > .nav-tabs .nav-link:hover,
.md-tabs-light > div > .nav-tabs .nav-item.show .nav-link,
.md-tabs-light > div > .nav-tabs .nav-link.focus,
.md-tabs-light > div > .nav-tabs .nav-link:focus,
.md-tabs-light > div > .nav-tabs .nav-link:hover,
.nav-pills.md-pills-light .nav-item.show .nav-link,
.nav-pills.md-pills-light .nav-link.focus,
.nav-pills.md-pills-light .nav-link:focus,
.nav-pills.md-pills-light .nav-link:hover,
.nav-tabs.md-tabs-light .nav-item.show .nav-link,
.nav-tabs.md-tabs-light .nav-link.focus,
.nav-tabs.md-tabs-light .nav-link:focus,
.nav-tabs.md-tabs-light .nav-link:hover {
  color: #fff;
}
.md-pills-light > .nav-pills .nav-link.disabled,
.md-pills-light > div > .nav-pills .nav-link.disabled,
.md-tabs-light > .nav-tabs .nav-link.disabled,
.md-tabs-light > div > .nav-tabs .nav-link.disabled,
.nav-pills.md-pills-light .nav-link.disabled,
.nav-tabs.md-tabs-light .nav-link.disabled {
  background-image: none !important;
  color: rgba(255, 255, 255, 0.4) !important;
}

.md-tabs-light > .nav-tabs .nav-link,
.md-tabs-light > div > .nav-tabs .nav-link,
.nav-tabs.md-tabs-light .nav-link {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff)) !important;
  background-image: linear-gradient(#fff, #fff) !important;
}
.md-tabs-light > .nav-tabs .nav-link.active,
.md-tabs-light > div > .nav-tabs .nav-link.active,
.nav-tabs.md-tabs-light .nav-link.active {
  color: #fff;
}

.material-style .md-pills-light > .nav-pills .nav-link.active, .material-style .md-pills-light > .nav-pills .nav-link.active:hover, .material-style .md-pills-light > .nav-pills .nav-link.active:focus,
.material-style .md-pills-light > div > .nav-pills .nav-link.active,
.material-style .md-pills-light > div > .nav-pills .nav-link.active:hover,
.material-style .md-pills-light > div > .nav-pills .nav-link.active:focus,
.material-style .nav-pills.md-pills-light .nav-link.active,
.material-style .nav-pills.md-pills-light .nav-link.active:hover,
.material-style .nav-pills.md-pills-light .nav-link.active:focus {
  background: #fff;
  color: #4E5155;
}

.nav-lg > .nav .nav-link, .nav-lg.nav .nav-link {
  padding: 0.75rem 1.3125rem;
  font-size: 1rem;
  line-height: 1.5;
}

.nav-sm > .nav .nav-link, .nav-sm.nav .nav-link {
  padding: 0.4375rem 0.875rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.nav-tabs-bottom,
.nav-tabs-left,
.nav-tabs-right,
.nav-tabs-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.nav-tabs-bottom > .nav,
.nav-tabs-bottom > div > .nav,
.nav-tabs-left > .nav,
.nav-tabs-left > div > .nav,
.nav-tabs-right > .nav,
.nav-tabs-right > div > .nav,
.nav-tabs-top > .nav,
.nav-tabs-top > div > .nav {
  position: relative;
  z-index: 1;
  border: 0;
}
.nav-tabs-bottom > .nav .nav-link:hover, .nav-tabs-bottom > .nav .nav-link:focus,
.nav-tabs-bottom > div > .nav .nav-link:hover,
.nav-tabs-bottom > div > .nav .nav-link:focus,
.nav-tabs-left > .nav .nav-link:hover,
.nav-tabs-left > .nav .nav-link:focus,
.nav-tabs-left > div > .nav .nav-link:hover,
.nav-tabs-left > div > .nav .nav-link:focus,
.nav-tabs-right > .nav .nav-link:hover,
.nav-tabs-right > .nav .nav-link:focus,
.nav-tabs-right > div > .nav .nav-link:hover,
.nav-tabs-right > div > .nav .nav-link:focus,
.nav-tabs-top > .nav .nav-link:hover,
.nav-tabs-top > .nav .nav-link:focus,
.nav-tabs-top > div > .nav .nav-link:hover,
.nav-tabs-top > div > .nav .nav-link:focus {
  border-color: rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.2);
}
.nav-tabs-bottom > .nav .nav-link.active,
.nav-tabs-bottom > div > .nav .nav-link.active,
.nav-tabs-left > .nav .nav-link.active,
.nav-tabs-left > div > .nav .nav-link.active,
.nav-tabs-right > .nav .nav-link.active,
.nav-tabs-right > div > .nav .nav-link.active,
.nav-tabs-top > .nav .nav-link.active,
.nav-tabs-top > div > .nav .nav-link.active {
  border-color: rgba(24, 28, 33, 0.2) rgba(24, 28, 33, 0.2) #fff;
}

.nav-tabs-left,
.nav-tabs-right {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.nav-tabs-left > .nav,
.nav-tabs-left > div > .nav,
.nav-tabs-right > .nav,
.nav-tabs-right > div > .nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.nav-tabs-left > .nav .nav-link,
.nav-tabs-left > div > .nav .nav-link,
.nav-tabs-right > .nav .nav-link,
.nav-tabs-right > div > .nav .nav-link {
  margin: 0 0 0.125rem 0 !important;
}
.nav-tabs-left > .tab-content,
.nav-tabs-right > .tab-content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.nav-tabs-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.nav-tabs-top > .nav .nav-link:hover, .nav-tabs-top > .nav .nav-link:focus,
.nav-tabs-top > div > .nav .nav-link:hover,
.nav-tabs-top > div > .nav .nav-link:focus {
  border-bottom-color: transparent;
}
.nav-tabs-top > .nav .nav-link.active,
.nav-tabs-top > div > .nav .nav-link.active {
  border-bottom-color: #fff;
}

.nav-tabs-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.nav-tabs-right > .nav .nav-item,
.nav-tabs-right > div > .nav .nav-item {
  margin-bottom: 0;
  margin-left: -1px;
}
.nav-tabs-right > .nav .nav-link:hover, .nav-tabs-right > .nav .nav-link:focus,
.nav-tabs-right > div > .nav .nav-link:hover,
.nav-tabs-right > div > .nav .nav-link:focus {
  border-left-color: transparent;
}
.nav-tabs-right > .nav .nav-link.active,
.nav-tabs-right > div > .nav .nav-link.active {
  border-left-color: #fff;
}
.nav-tabs-right .nav-tabs .nav-link {
  background-position: left;
}
.nav-tabs-right .nav-tabs .nav-item.show .nav-link,
.nav-tabs-right .nav-tabs .nav-link.active {
  background-size: 2px 100%;
}

.nav-tabs-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.nav-tabs-bottom > .nav .nav-item,
.nav-tabs-bottom > div > .nav .nav-item {
  margin-top: -1px;
  margin-bottom: 0;
}
.nav-tabs-bottom > .nav .nav-link:hover, .nav-tabs-bottom > .nav .nav-link:focus,
.nav-tabs-bottom > div > .nav .nav-link:hover,
.nav-tabs-bottom > div > .nav .nav-link:focus {
  border-top-color: transparent;
}
.nav-tabs-bottom > .nav .nav-link.active,
.nav-tabs-bottom > div > .nav .nav-link.active {
  border-top-color: #fff;
}
.nav-tabs-bottom .nav-tabs .nav-link {
  background-position: top;
}
.nav-tabs-bottom .nav-tabs .nav-item.show .nav-link,
.nav-tabs-bottom .nav-tabs .nav-link.active {
  background-size: 100% 2px;
}

.nav-tabs-left > .nav .nav-item,
.nav-tabs-left > div > .nav .nav-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.nav-tabs-left > .nav .nav-link:hover, .nav-tabs-left > .nav .nav-link:focus,
.nav-tabs-left > div > .nav .nav-link:hover,
.nav-tabs-left > div > .nav .nav-link:focus {
  border-right-color: transparent;
}
.nav-tabs-left > .nav .nav-link.active,
.nav-tabs-left > div > .nav .nav-link.active {
  border-right-color: #fff;
}
.nav-tabs-left .nav-tabs .nav-link {
  background-position: right;
}
.nav-tabs-left .nav-tabs .nav-item.show .nav-link,
.nav-tabs-left .nav-tabs .nav-link.active {
  background-size: 2px 100%;
}

.nav-tabs-bottom > .tab-content,
.nav-tabs-left > .tab-content,
.nav-tabs-right > .tab-content,
.nav-tabs-top > .tab-content {
  -ms-flex-negative: 1;
      flex-shrink: 1;
  border: 0px solid rgba(24, 28, 33, 0.06);
  background: #fff;
  background-clip: padding-box;
  -webkit-box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.04);
          box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.04);
}

.nav-tabs-top > .tab-content {
  border-radius: 0 0 0.125rem 0.125rem;
}

.nav-tabs-right > .tab-content {
  border-radius: 0.125rem 0 0 0.125rem;
}

.nav-tabs-bottom > .tab-content {
  border-radius: 0.125rem 0.125rem 0 0;
}

.nav-tabs-left > .tab-content {
  border-radius: 0 0.125rem 0.125rem 0;
}

@media (max-width: 575px) {
  .nav-responsive-sm > .nav,
  .nav-responsive-sm > div > .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border: 0;
  }
  .nav-responsive-sm > .nav .nav-item,
  .nav-responsive-sm > div > .nav .nav-item {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .nav-responsive-sm > .nav.card-header-pills, .nav-responsive-sm > .nav.card-header-tabs,
  .nav-responsive-sm > div > .nav.card-header-pills,
  .nav-responsive-sm > div > .nav.card-header-tabs {
    margin-bottom: -0.125rem;
  }
  .nav-responsive-sm > .nav-tabs .nav-item,
  .nav-responsive-sm > div > .nav-pills .nav-item,
  .nav-responsive-sm > div > .nav-tabs .nav-item,
  .nav-responsive-sm > .nav-pills .nav-item {
    margin: 0 !important;
  }
  .nav-responsive-sm > .nav-tabs .nav-link,
  .nav-responsive-sm > div > .nav-pills .nav-link,
  .nav-responsive-sm > div > .nav-tabs .nav-link,
  .nav-responsive-sm > .nav-pills .nav-link {
    margin: 0 0 0.125rem 0 !important;
  }
  .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link:focus,
  .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link:hover,
  .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link:focus {
    border-color: rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.2) !important;
  }
  .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link.active,
  .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: rgba(24, 28, 33, 0.2) rgba(24, 28, 33, 0.2) #fff !important;
  }
  .nav-responsive-sm.nav-tabs-bottom > .tab-content, .nav-responsive-sm.nav-tabs-left > .tab-content, .nav-responsive-sm.nav-tabs-right > .tab-content, .nav-responsive-sm.nav-tabs-top > .tab-content {
    border-radius: 0.125rem !important;
  }
  .nav-responsive-sm.nav-tabs-bottom, .nav-responsive-sm.nav-tabs-left, .nav-responsive-sm.nav-tabs-right {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
}
@media (max-width: 767px) {
  .nav-responsive-md > .nav,
  .nav-responsive-md > div > .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border: 0;
  }
  .nav-responsive-md > .nav .nav-item,
  .nav-responsive-md > div > .nav .nav-item {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .nav-responsive-md > .nav.card-header-pills, .nav-responsive-md > .nav.card-header-tabs,
  .nav-responsive-md > div > .nav.card-header-pills,
  .nav-responsive-md > div > .nav.card-header-tabs {
    margin-bottom: -0.125rem;
  }
  .nav-responsive-md > .nav-tabs .nav-item,
  .nav-responsive-md > div > .nav-pills .nav-item,
  .nav-responsive-md > div > .nav-tabs .nav-item,
  .nav-responsive-md > .nav-pills .nav-item {
    margin: 0 !important;
  }
  .nav-responsive-md > .nav-tabs .nav-link,
  .nav-responsive-md > div > .nav-pills .nav-link,
  .nav-responsive-md > div > .nav-tabs .nav-link,
  .nav-responsive-md > .nav-pills .nav-link {
    margin: 0 0 0.125rem 0 !important;
  }
  .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link:focus,
  .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link:hover,
  .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link:focus {
    border-color: rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.2) !important;
  }
  .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link.active,
  .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: rgba(24, 28, 33, 0.2) rgba(24, 28, 33, 0.2) #fff !important;
  }
  .nav-responsive-md.nav-tabs-bottom > .tab-content, .nav-responsive-md.nav-tabs-left > .tab-content, .nav-responsive-md.nav-tabs-right > .tab-content, .nav-responsive-md.nav-tabs-top > .tab-content {
    border-radius: 0.125rem !important;
  }
  .nav-responsive-md.nav-tabs-bottom, .nav-responsive-md.nav-tabs-left, .nav-responsive-md.nav-tabs-right {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
}
@media (max-width: 991px) {
  .nav-responsive-lg > .nav,
  .nav-responsive-lg > div > .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border: 0;
  }
  .nav-responsive-lg > .nav .nav-item,
  .nav-responsive-lg > div > .nav .nav-item {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .nav-responsive-lg > .nav.card-header-pills, .nav-responsive-lg > .nav.card-header-tabs,
  .nav-responsive-lg > div > .nav.card-header-pills,
  .nav-responsive-lg > div > .nav.card-header-tabs {
    margin-bottom: -0.125rem;
  }
  .nav-responsive-lg > .nav-tabs .nav-item,
  .nav-responsive-lg > div > .nav-pills .nav-item,
  .nav-responsive-lg > div > .nav-tabs .nav-item,
  .nav-responsive-lg > .nav-pills .nav-item {
    margin: 0 !important;
  }
  .nav-responsive-lg > .nav-tabs .nav-link,
  .nav-responsive-lg > div > .nav-pills .nav-link,
  .nav-responsive-lg > div > .nav-tabs .nav-link,
  .nav-responsive-lg > .nav-pills .nav-link {
    margin: 0 0 0.125rem 0 !important;
  }
  .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link:focus,
  .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link:hover,
  .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link:focus {
    border-color: rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.2) !important;
  }
  .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link.active,
  .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: rgba(24, 28, 33, 0.2) rgba(24, 28, 33, 0.2) #fff !important;
  }
  .nav-responsive-lg.nav-tabs-bottom > .tab-content, .nav-responsive-lg.nav-tabs-left > .tab-content, .nav-responsive-lg.nav-tabs-right > .tab-content, .nav-responsive-lg.nav-tabs-top > .tab-content {
    border-radius: 0.125rem !important;
  }
  .nav-responsive-lg.nav-tabs-bottom, .nav-responsive-lg.nav-tabs-left, .nav-responsive-lg.nav-tabs-right {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
}
@media (max-width: 1199px) {
  .nav-responsive-xl > .nav,
  .nav-responsive-xl > div > .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border: 0;
  }
  .nav-responsive-xl > .nav .nav-item,
  .nav-responsive-xl > div > .nav .nav-item {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .nav-responsive-xl > .nav.card-header-pills, .nav-responsive-xl > .nav.card-header-tabs,
  .nav-responsive-xl > div > .nav.card-header-pills,
  .nav-responsive-xl > div > .nav.card-header-tabs {
    margin-bottom: -0.125rem;
  }
  .nav-responsive-xl > .nav-tabs .nav-item,
  .nav-responsive-xl > div > .nav-pills .nav-item,
  .nav-responsive-xl > div > .nav-tabs .nav-item,
  .nav-responsive-xl > .nav-pills .nav-item {
    margin: 0 !important;
  }
  .nav-responsive-xl > .nav-tabs .nav-link,
  .nav-responsive-xl > div > .nav-pills .nav-link,
  .nav-responsive-xl > div > .nav-tabs .nav-link,
  .nav-responsive-xl > .nav-pills .nav-link {
    margin: 0 0 0.125rem 0 !important;
  }
  .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link:focus,
  .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link:hover,
  .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link:focus {
    border-color: rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.1) rgba(24, 28, 33, 0.2) !important;
  }
  .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link.active,
  .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: rgba(24, 28, 33, 0.2) rgba(24, 28, 33, 0.2) #fff !important;
  }
  .nav-responsive-xl.nav-tabs-bottom > .tab-content, .nav-responsive-xl.nav-tabs-left > .tab-content, .nav-responsive-xl.nav-tabs-right > .tab-content, .nav-responsive-xl.nav-tabs-top > .tab-content {
    border-radius: 0.125rem !important;
  }
  .nav-responsive-xl.nav-tabs-bottom, .nav-responsive-xl.nav-tabs-left, .nav-responsive-xl.nav-tabs-right {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
}
.page-item + .page-item .page-link,
.pagination li + li > a:not(.page-link) {
  margin-left: 0.0625rem;
}

.page-link,
.page-link > a {
  min-width: calc(2.144rem + 0px);
  text-align: center;
  line-height: 1 !important;
  border-radius: 0.125rem;
  font-weight: 500;
}
.page-link:focus,
.page-link > a:focus {
  color: #4E5155;
}

.page-link.btn-primary {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.pagination > li > a:not(.page-link) {
  position: relative;
  display: block;
  padding: 0.625rem 0.3125rem;
  min-width: calc(2.144rem + 0px);
  border: 0px solid rgba(24, 28, 33, 0.2);
  background-color: transparent;
  color: #a3a4a6;
  text-align: center;
  line-height: 1 !important;
  border-radius: 0.125rem;
  font-weight: 500;
}
.pagination > li > a:not(.page-link):hover, .pagination > li > a:not(.page-link):focus {
  border-color: rgba(24, 28, 33, 0.2);
  background-color: transparent;
  color: #4E5155;
  text-decoration: none;
}
.pagination > li > a:not(.page-link):focus {
  color: #4E5155;
}

.page-link .waves-ripple,
.pagination > li .waves-ripple {
  background: rgba(0, 0, 0, 0.1) !important;
}

.page-link.waves-effect {
  display: block;
}

.page-item.active .page-link .waves-ripple,
.page-item.disabled .page-link .waves-ripple,
.pagination > li.active .waves-ripple,
.pagination > li.disabled .waves-ripple {
  display: none !important;
}

.pagination-lg .page-link,
.pagination-lg > li > a:not(.page-link) {
  min-width: calc(2.875rem + 0px);
  border-radius: 0.125rem;
}

.pagination-sm .page-link,
.pagination-sm > li > a:not(.page-link) {
  min-width: calc(1.5rem + 0px);
  border-radius: 0.125rem;
}

.alert-secondary {
  border-color: #dfe1e3;
  background-color: #edeff2;
  color: #5f6061;
}
.alert-secondary hr {
  border-top-color: #dfe1e3;
}
.alert-secondary .close,
.alert-secondary .alert-link {
  color: #5f6061;
}

.alert-dark-secondary {
  background-color: #8897AA;
  color: #fff;
}
.alert-dark-secondary hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.alert-dark-secondary .close,
.alert-dark-secondary .alert-link {
  color: #fff;
}

.alert-success {
  border-color: #d2e9e1;
  background-color: #dff8ef;
  color: #596360;
}
.alert-success hr {
  border-top-color: #d2e9e1;
}
.alert-success .close,
.alert-success .alert-link {
  color: #596360;
}

.alert-dark-success {
  background-color: #28D094;
  color: #fff;
}
.alert-dark-success hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.alert-dark-success .close,
.alert-dark-success .alert-link {
  color: #fff;
}

.alert-info {
  border-color: #d0e3ee;
  background-color: #ddf1fd;
  color: #586065;
}
.alert-info hr {
  border-top-color: #d0e3ee;
}
.alert-info .close,
.alert-info .alert-link {
  color: #586065;
}

.alert-dark-info {
  background-color: #1E9FF2;
  color: #fff;
}
.alert-dark-info hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.alert-dark-info .close,
.alert-dark-info .alert-link {
  color: #fff;
}

.alert-warning {
  border-color: #f0e1d6;
  background-color: #ffefe4;
  color: #66605b;
}
.alert-warning hr {
  border-top-color: #f0e1d6;
}
.alert-warning .close,
.alert-warning .alert-link {
  color: #66605b;
}

.alert-dark-warning {
  background-color: #FF9149;
  color: #663a1d;
}
.alert-dark-warning hr {
  border-top-color: rgba(102, 58, 29, 0.2);
}
.alert-dark-warning .close,
.alert-dark-warning .alert-link {
  color: #663a1d;
}

.alert-danger {
  border-color: #f0d6d9;
  background-color: #ffe4e7;
  color: #665b5c;
}
.alert-danger hr {
  border-top-color: #f0d6d9;
}
.alert-danger .close,
.alert-danger .alert-link {
  color: #665b5c;
}

.alert-dark-danger {
  background-color: #FF4961;
  color: #fff;
}
.alert-dark-danger hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.alert-dark-danger .close,
.alert-dark-danger .alert-link {
  color: #fff;
}

.alert-dark {
  border-color: #d2d3d4;
  background-color: #dfe0e2;
  color: #595a5a;
}
.alert-dark hr {
  border-top-color: #d2d3d4;
}
.alert-dark .close,
.alert-dark .alert-link {
  color: #595a5a;
}

.alert-dark-dark {
  background-color: #2c2e3e;
  color: #fff;
}
.alert-dark-dark hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.alert-dark-dark .close,
.alert-dark-dark .alert-link {
  color: #fff;
}

.tooltip-inner {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
}

.modal-open .tooltip {
  z-index: 1092;
}

.tooltip-secondary .tooltip-inner, .tooltip-secondary > .tooltip .tooltip-inner, .ngb-tooltip-secondary + ngb-tooltip-window .tooltip-inner {
  background: #8897AA;
  color: #fff;
}
.tooltip-secondary.bs-tooltip-top .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-top .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #8897AA;
}
.tooltip-secondary.bs-tooltip-right .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-right .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #8897AA;
}
.tooltip-secondary.bs-tooltip-bottom .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #8897AA;
}
.tooltip-secondary.bs-tooltip-left .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-left .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #8897AA;
}

.tooltip-success .tooltip-inner, .tooltip-success > .tooltip .tooltip-inner, .ngb-tooltip-success + ngb-tooltip-window .tooltip-inner {
  background: #28D094;
  color: #fff;
}
.tooltip-success.bs-tooltip-top .arrow::before, .tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-success > .tooltip.bs-tooltip-top .arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #28D094;
}
.tooltip-success.bs-tooltip-right .arrow::before, .tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-success > .tooltip.bs-tooltip-right .arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #28D094;
}
.tooltip-success.bs-tooltip-bottom .arrow::before, .tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-success > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #28D094;
}
.tooltip-success.bs-tooltip-left .arrow::before, .tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-success > .tooltip.bs-tooltip-left .arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #28D094;
}

.tooltip-info .tooltip-inner, .tooltip-info > .tooltip .tooltip-inner, .ngb-tooltip-info + ngb-tooltip-window .tooltip-inner {
  background: #1E9FF2;
  color: #fff;
}
.tooltip-info.bs-tooltip-top .arrow::before, .tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-info > .tooltip.bs-tooltip-top .arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #1E9FF2;
}
.tooltip-info.bs-tooltip-right .arrow::before, .tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-info > .tooltip.bs-tooltip-right .arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #1E9FF2;
}
.tooltip-info.bs-tooltip-bottom .arrow::before, .tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-info > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #1E9FF2;
}
.tooltip-info.bs-tooltip-left .arrow::before, .tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-info > .tooltip.bs-tooltip-left .arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #1E9FF2;
}

.tooltip-warning .tooltip-inner, .tooltip-warning > .tooltip .tooltip-inner, .ngb-tooltip-warning + ngb-tooltip-window .tooltip-inner {
  background: #FF9149;
  color: #663a1d;
}
.tooltip-warning.bs-tooltip-top .arrow::before, .tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-top .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #FF9149;
}
.tooltip-warning.bs-tooltip-right .arrow::before, .tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-right .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #FF9149;
}
.tooltip-warning.bs-tooltip-bottom .arrow::before, .tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #FF9149;
}
.tooltip-warning.bs-tooltip-left .arrow::before, .tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-left .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #FF9149;
}

.tooltip-danger .tooltip-inner, .tooltip-danger > .tooltip .tooltip-inner, .ngb-tooltip-danger + ngb-tooltip-window .tooltip-inner {
  background: #FF4961;
  color: #fff;
}
.tooltip-danger.bs-tooltip-top .arrow::before, .tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-top .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #FF4961;
}
.tooltip-danger.bs-tooltip-right .arrow::before, .tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-right .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #FF4961;
}
.tooltip-danger.bs-tooltip-bottom .arrow::before, .tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #FF4961;
}
.tooltip-danger.bs-tooltip-left .arrow::before, .tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-left .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #FF4961;
}

.tooltip-dark .tooltip-inner, .tooltip-dark > .tooltip .tooltip-inner, .ngb-tooltip-dark + ngb-tooltip-window .tooltip-inner {
  background: #2c2e3e;
  color: #fff;
}
.tooltip-dark.bs-tooltip-top .arrow::before, .tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-top .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #2c2e3e;
}
.tooltip-dark.bs-tooltip-right .arrow::before, .tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-right .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #2c2e3e;
}
.tooltip-dark.bs-tooltip-bottom .arrow::before, .tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #2c2e3e;
}
.tooltip-dark.bs-tooltip-left .arrow::before, .tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-left .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #2c2e3e;
}

.tooltip {
  -webkit-transition: none !important;
  transition: none !important;
}

.tooltip.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  -webkit-animation: topTooltipAnimation .25s;
          animation: topTooltipAnimation .25s;
}

.tooltip.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  -webkit-animation: rightTooltipAnimation .25s;
          animation: rightTooltipAnimation .25s;
}

.tooltip.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  -webkit-animation: bottomTooltipAnimation .25s;
          animation: bottomTooltipAnimation .25s;
}

.tooltip.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  -webkit-animation: leftTooltipAnimation .25s;
          animation: leftTooltipAnimation .25s;
}

.popover {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
}
.popover .arrow {
  z-index: 1;
}

.modal-open .popover {
  z-index: 1091;
}

.popover-secondary, .popover-secondary > .popover, .ngb-popover-secondary + ngb-popover-window {
  border-color: transparent;
  background: #8897AA;
}
.popover-secondary .popover-header, .popover-secondary > .popover .popover-header, .ngb-popover-secondary + ngb-popover-window .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #fff;
}
.popover-secondary .popover-body, .popover-secondary > .popover .popover-body, .ngb-popover-secondary + ngb-popover-window .popover-body {
  background: transparent;
  color: #fff;
}
.popover-secondary .arrow::before, .popover-secondary > .popover .arrow::before, .ngb-popover-secondary + ngb-popover-window .arrow::before {
  border-color: transparent !important;
}
.popover-secondary.bs-popover-top .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-secondary > .popover.bs-popover-top .arrow::after, .popover-secondary > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #8897AA;
}
.popover-secondary.bs-popover-right .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-secondary > .popover.bs-popover-right .arrow::after, .popover-secondary > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #8897AA;
}
.popover-secondary.bs-popover-bottom .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-secondary > .popover.bs-popover-bottom .arrow::after, .popover-secondary > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #8897AA;
}
.popover-secondary.bs-popover-left .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-secondary > .popover.bs-popover-left .arrow::after, .popover-secondary > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #8897AA;
}

.popover-success, .popover-success > .popover, .ngb-popover-success + ngb-popover-window {
  border-color: transparent;
  background: #28D094;
}
.popover-success .popover-header, .popover-success > .popover .popover-header, .ngb-popover-success + ngb-popover-window .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #fff;
}
.popover-success .popover-body, .popover-success > .popover .popover-body, .ngb-popover-success + ngb-popover-window .popover-body {
  background: transparent;
  color: #fff;
}
.popover-success .arrow::before, .popover-success > .popover .arrow::before, .ngb-popover-success + ngb-popover-window .arrow::before {
  border-color: transparent !important;
}
.popover-success.bs-popover-top .arrow::after, .popover-success.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-success > .popover.bs-popover-top .arrow::after, .popover-success > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #28D094;
}
.popover-success.bs-popover-right .arrow::after, .popover-success.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-success > .popover.bs-popover-right .arrow::after, .popover-success > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #28D094;
}
.popover-success.bs-popover-bottom .arrow::after, .popover-success.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-success > .popover.bs-popover-bottom .arrow::after, .popover-success > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #28D094;
}
.popover-success.bs-popover-left .arrow::after, .popover-success.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-success > .popover.bs-popover-left .arrow::after, .popover-success > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #28D094;
}

.popover-info, .popover-info > .popover, .ngb-popover-info + ngb-popover-window {
  border-color: transparent;
  background: #1E9FF2;
}
.popover-info .popover-header, .popover-info > .popover .popover-header, .ngb-popover-info + ngb-popover-window .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #fff;
}
.popover-info .popover-body, .popover-info > .popover .popover-body, .ngb-popover-info + ngb-popover-window .popover-body {
  background: transparent;
  color: #fff;
}
.popover-info .arrow::before, .popover-info > .popover .arrow::before, .ngb-popover-info + ngb-popover-window .arrow::before {
  border-color: transparent !important;
}
.popover-info.bs-popover-top .arrow::after, .popover-info.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-info > .popover.bs-popover-top .arrow::after, .popover-info > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #1E9FF2;
}
.popover-info.bs-popover-right .arrow::after, .popover-info.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-info > .popover.bs-popover-right .arrow::after, .popover-info > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #1E9FF2;
}
.popover-info.bs-popover-bottom .arrow::after, .popover-info.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-info > .popover.bs-popover-bottom .arrow::after, .popover-info > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #1E9FF2;
}
.popover-info.bs-popover-left .arrow::after, .popover-info.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-info > .popover.bs-popover-left .arrow::after, .popover-info > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #1E9FF2;
}

.popover-warning, .popover-warning > .popover, .ngb-popover-warning + ngb-popover-window {
  border-color: transparent;
  background: #FF9149;
}
.popover-warning .popover-header, .popover-warning > .popover .popover-header, .ngb-popover-warning + ngb-popover-window .popover-header {
  border-color: rgba(102, 58, 29, 0.2);
  background: transparent;
  color: #663a1d;
}
.popover-warning .popover-body, .popover-warning > .popover .popover-body, .ngb-popover-warning + ngb-popover-window .popover-body {
  background: transparent;
  color: #663a1d;
}
.popover-warning .arrow::before, .popover-warning > .popover .arrow::before, .ngb-popover-warning + ngb-popover-window .arrow::before {
  border-color: transparent !important;
}
.popover-warning.bs-popover-top .arrow::after, .popover-warning.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-warning > .popover.bs-popover-top .arrow::after, .popover-warning > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #FF9149;
}
.popover-warning.bs-popover-right .arrow::after, .popover-warning.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-warning > .popover.bs-popover-right .arrow::after, .popover-warning > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #FF9149;
}
.popover-warning.bs-popover-bottom .arrow::after, .popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-warning > .popover.bs-popover-bottom .arrow::after, .popover-warning > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #FF9149;
}
.popover-warning.bs-popover-left .arrow::after, .popover-warning.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-warning > .popover.bs-popover-left .arrow::after, .popover-warning > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #FF9149;
}

.popover-danger, .popover-danger > .popover, .ngb-popover-danger + ngb-popover-window {
  border-color: transparent;
  background: #FF4961;
}
.popover-danger .popover-header, .popover-danger > .popover .popover-header, .ngb-popover-danger + ngb-popover-window .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #fff;
}
.popover-danger .popover-body, .popover-danger > .popover .popover-body, .ngb-popover-danger + ngb-popover-window .popover-body {
  background: transparent;
  color: #fff;
}
.popover-danger .arrow::before, .popover-danger > .popover .arrow::before, .ngb-popover-danger + ngb-popover-window .arrow::before {
  border-color: transparent !important;
}
.popover-danger.bs-popover-top .arrow::after, .popover-danger.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-danger > .popover.bs-popover-top .arrow::after, .popover-danger > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #FF4961;
}
.popover-danger.bs-popover-right .arrow::after, .popover-danger.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-danger > .popover.bs-popover-right .arrow::after, .popover-danger > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #FF4961;
}
.popover-danger.bs-popover-bottom .arrow::after, .popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-danger > .popover.bs-popover-bottom .arrow::after, .popover-danger > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #FF4961;
}
.popover-danger.bs-popover-left .arrow::after, .popover-danger.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-danger > .popover.bs-popover-left .arrow::after, .popover-danger > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #FF4961;
}

.popover-dark, .popover-dark > .popover, .ngb-popover-dark + ngb-popover-window {
  border-color: transparent;
  background: #2c2e3e;
}
.popover-dark .popover-header, .popover-dark > .popover .popover-header, .ngb-popover-dark + ngb-popover-window .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #fff;
}
.popover-dark .popover-body, .popover-dark > .popover .popover-body, .ngb-popover-dark + ngb-popover-window .popover-body {
  background: transparent;
  color: #fff;
}
.popover-dark .arrow::before, .popover-dark > .popover .arrow::before, .ngb-popover-dark + ngb-popover-window .arrow::before {
  border-color: transparent !important;
}
.popover-dark.bs-popover-top .arrow::after, .popover-dark.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-dark > .popover.bs-popover-top .arrow::after, .popover-dark > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #2c2e3e;
}
.popover-dark.bs-popover-right .arrow::after, .popover-dark.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-dark > .popover.bs-popover-right .arrow::after, .popover-dark > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #2c2e3e;
}
.popover-dark.bs-popover-bottom .arrow::after, .popover-dark.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-dark > .popover.bs-popover-bottom .arrow::after, .popover-dark > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #2c2e3e;
}
.popover-dark.bs-popover-left .arrow::after, .popover-dark.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-dark > .popover.bs-popover-left .arrow::after, .popover-dark > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #2c2e3e;
}

.popover {
  -webkit-transition: none !important;
  transition: none !important;
}

.popover-header {
  border-bottom-width: 1px;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  -webkit-animation: topTooltipAnimation .25s;
          animation: topTooltipAnimation .25s;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  -webkit-animation: rightTooltipAnimation .25s;
          animation: rightTooltipAnimation .25s;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  -webkit-animation: bottomTooltipAnimation .25s;
          animation: bottomTooltipAnimation .25s;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  -webkit-animation: leftTooltipAnimation .25s;
          animation: leftTooltipAnimation .25s;
}

.form-control {
  border-bottom-width: 1px;
  border-radius: 0 !important;
}
.form-control:disabled {
  border-bottom: 1px dotted rgba(24, 28, 33, 0.3) !important;
  color: #a3a4a6 !important;
}

/* :not(.input-group) > .form-control:not([class*='px-']):not([class*='pl-']) {
  padding-left: 0;
}

:not(.input-group) > .form-control:not([class*='px-']):not([class*='pr-']) {
  padding-right: 0;
} */

.form-control {
  min-height: 2.1875rem;
}

.form-control-sm,
.input-group-sm .form-control {
  min-height: 1.5625rem;
}

.form-control-lg,
.input-group-lg .form-control {
  min-height: 3.125rem;
}

select.form-control[size="0"]:not([multiple]) {
  height: 2.1875rem;
}

select.form-control-sm[size="0"]:not([multiple]) {
  height: 1.5625rem;
}

select.form-control-lg[size="0"]:not([multiple]) {
  height: 3.125rem;
}

.form-control,
.custom-select,
.custom-file-label,
.input-group-text {
  padding-bottom: calc(0.5rem - 1px) !important;
  line-height: 1.33;
}

.form-control-sm,
.custom-select-sm,
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm .input-group-text {
  padding-bottom: calc(0.25rem - 1px) !important;
  line-height: 1.417;
}

.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding-bottom: calc(0.25rem - 1px) !important;
  font-size: 0.6875rem;
}

.form-control-lg,
.custom-select-lg,
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg .input-group-text {
  padding-bottom: calc(0.8125rem - 1px) !important;
  line-height: 1.5;
}

.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding-bottom: calc(0.8125rem - 1px) !important;
  font-size: 1rem;
}

.form-control-plaintext {
  color: #4E5155;
}
.form-inline .form-control-plaintext {
  width: auto;
}

.form-control-inverted {
  border-color: rgba(255, 255, 255, 0.25);
}
.form-control-inverted, .form-control-inverted:hover, .form-control-inverted:focus {
  background-color: transparent;
  color: #fff;
}
.form-control-inverted::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.form-control-inverted:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.form-control-inverted::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.form-control-inverted::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.form-control-inverted:disabled {
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.7) !important;
  border-color: rgba(255, 255, 255, 0.35) !important;
}
.form-control-inverted option {
  color: #4E5155 !important;
}

.form-label,
.col-form-label,
.col-form-legend {
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.83125rem;
}

.col-form-label,
.col-form-legend {
  padding-bottom: 0;
  line-height: 1.47;
}

.form-label-sm,
.col-form-label-sm,
.col-form-legend-sm {
  font-size: 0.75rem !important;
  line-height: 1.55;
}

.form-label-lg,
.col-form-label-lg,
.col-form-legend-lg {
  font-size: 1rem !important;
  line-height: 1.5;
}

.form-label,
.col-form-label,
.col-form-legend,
.form-label-sm,
.col-form-label-sm,
.col-form-legend-sm,
.col-form-label-lg,
.col-form-legend-lg,
.form-label-lg {
  margin-bottom: 0;
}

.form-check {
  min-height: 1.31418rem;
}

.valid-feedback {
  display: none;
  margin-top: 0.25rem;
  color: #28D094;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  margin-top: .1rem;
  padding: .5rem;
  width: 250px;
  border-radius: .2rem;
  background-color: rgba(40, 208, 148, 0.8);
  color: #fff;
  line-height: 1;
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28D094 !important;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  -webkit-box-shadow: 0 -1px 0 0 #28D094 inset !important;
          box-shadow: 0 -1px 0 0 #28D094 inset !important;
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .custom-control-label, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control.custom-radio .custom-control-input:valid ~ .custom-control-label,
.custom-control.custom-radio .custom-control-input.is-valid ~ .custom-control-label {
  color: #28D094;
}
.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .custom-control-label::before, .was-validated .custom-control.custom-checkbox .custom-control-input:valid:focus ~ .custom-control-label::before, .was-validated .custom-control.custom-checkbox .custom-control-input:valid:active ~ .custom-control-label::before, .was-validated .custom-control.custom-checkbox .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-valid:focus ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-valid:active ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control.custom-radio .custom-control-input:valid ~ .custom-control-label::before,
.was-validated .custom-control.custom-radio .custom-control-input:valid:focus ~ .custom-control-label::before,
.was-validated .custom-control.custom-radio .custom-control-input:valid:active ~ .custom-control-label::before,
.was-validated .custom-control.custom-radio .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-valid ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-valid:active ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #28D094;
}
.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .custom-control-label::after, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .custom-control-label::after,
.was-validated .custom-control.custom-radio .custom-control-input:valid ~ .custom-control-label::after,
.custom-control.custom-radio .custom-control-input.is-valid ~ .custom-control-label::after {
  background: rgba(40, 208, 148, 0.15) !important;
}
.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .valid-tooltip, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .valid-feedback,
.custom-control.custom-checkbox .custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control.custom-radio .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control.custom-radio .custom-control-input:valid ~ .valid-tooltip,
.custom-control.custom-radio .custom-control-input.is-valid ~ .valid-feedback,
.custom-control.custom-radio .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #28D094;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.was-validated .custom-control.custom-radio .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control.custom-radio .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%2328D094'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%2328D094'/%3E%3C/svg%3E");
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28D094 !important;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 -1px 0 0 #28D094 inset !important;
          box-shadow: 0 -1px 0 0 #28D094 inset !important;
}

.invalid-feedback {
  display: none;
  margin-top: 0.25rem;
  color: #FF4961;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  margin-top: .1rem;
  padding: .5rem;
  width: 250px;
  border-radius: .2rem;
  background-color: rgba(255, 73, 97, 0.8);
  color: #fff;
  line-height: 1;
}

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #FF4961 !important;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  -webkit-box-shadow: 0 -1px 0 0 #FF4961 inset !important;
          box-shadow: 0 -1px 0 0 #FF4961 inset !important;
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .custom-control-label, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control.custom-radio .custom-control-input:invalid ~ .custom-control-label,
.custom-control.custom-radio .custom-control-input.is-invalid ~ .custom-control-label {
  color: #FF4961;
}
.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .custom-control-label::before, .was-validated .custom-control.custom-checkbox .custom-control-input:invalid:focus ~ .custom-control-label::before, .was-validated .custom-control.custom-checkbox .custom-control-input:invalid:active ~ .custom-control-label::before, .was-validated .custom-control.custom-checkbox .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-invalid:active ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control.custom-radio .custom-control-input:invalid ~ .custom-control-label::before,
.was-validated .custom-control.custom-radio .custom-control-input:invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control.custom-radio .custom-control-input:invalid:active ~ .custom-control-label::before,
.was-validated .custom-control.custom-radio .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-invalid ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-invalid:active ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #FF4961;
}
.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .custom-control-label::after, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label::after,
.was-validated .custom-control.custom-radio .custom-control-input:invalid ~ .custom-control-label::after,
.custom-control.custom-radio .custom-control-input.is-invalid ~ .custom-control-label::after {
  background: rgba(255, 73, 97, 0.15) !important;
}
.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .invalid-tooltip, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control.custom-checkbox .custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control.custom-radio .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control.custom-radio .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control.custom-radio .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control.custom-radio .custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #FF4961;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.was-validated .custom-control.custom-radio .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control.custom-radio .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23FF4961'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23FF4961'/%3E%3C/svg%3E");
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #FF4961 !important;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 -1px 0 0 #FF4961 inset !important;
          box-shadow: 0 -1px 0 0 #FF4961 inset !important;
}

.form-inline .custom-control {
  display: block;
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline .custom-control,
  .form-inline .custom-select,
  .form-inline .form-control-plaintext {
    display: inline-block;
    width: auto;
  }

  .form-inline .form-text {
    margin-top: 0;
  }

  .form-inline .form-label {
    margin-bottom: 0 !important;
  }
}
.custom-control {
  position: relative;
  min-height: 1.125rem;
}

.custom-control-label {
  position: static;
}
.custom-control-label::before {
  top: 0.0625rem;
  left: 0;
  border: 2px solid rgba(24, 28, 33, 0.1);
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: all .2s;
  transition: all .2s;
  pointer-events: auto;
  z-index: 2;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.custom-control-label::after {
  content: '';
  position: absolute;
  top: 0.0625rem;
  left: 0;
  z-index: 1;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background: rgba(24, 28, 33, 0.08);
  opacity: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: scale(0) translateZ(0);
          transform: scale(0) translateZ(0);
}

.custom-checkbox .custom-control-label::before {
  background-size: 75% 75%;
}

.custom-radio .custom-control-label::before {
  background-size: 60% 60%;
}

.custom-control-input:active ~ .custom-control-label::before {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.custom-control-input:active:not(:checked) ~ .custom-control-label::before {
  background-color: transparent;
}
.custom-control-input:disabled ~ .custom-control-label::before, fieldset[disabled] .custom-control-input ~ .custom-control-label::before {
  border-color: rgba(24, 28, 33, 0.1) !important;
  background-color: transparent !important;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before, fieldset[disabled] .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23a3a4a6;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E") !important;
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before, fieldset[disabled] .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23a3a4a6'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23a3a4a6'/%3E%3C/svg%3E") !important;
}

.custom-control-input:focus ~ .custom-control-label::after {
  opacity: 1;
  -webkit-transform: scale(2.25) translateZ(0);
          transform: scale(2.25) translateZ(0);
}
.custom-control-input:active ~ .custom-control-label::after {
  opacity: 0;
  -webkit-transform: scale(0) translateZ(0);
          transform: scale(0) translateZ(0);
}
.custom-control-input:disabled ~ .custom-control-label::after, fieldset[disabled] .custom-control-input ~ .custom-control-label::after {
  display: none !important;
}

.custom-radio .custom-control-input:not(:checked) ~ .custom-control-label::before {
  background-size: 0 0;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-controls-stacked .custom-control {
  display: block;
  margin-bottom: .5rem;
}

.custom-select {
  display: block;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAMCAYAAACEJVa/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAE5JREFUeNpi+P//PwMIUwKYGKgABo8hLMQo8vDxa/j/7189NjlGJqZGolyyY8umBpBibAaA5Ij2DrpBMAPAHFKjGOQ1EEYRHE0nGAAgwAD7ACa5V929KwAAAABJRU5ErkJggg==") !important;
  background-clip: padding-box;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-bottom-width: 1px;
  border-radius: 0 !important;
}
.custom-select[size="0"]:not([multiple]) {
  padding-right: 1.875rem;
}
.custom-select:disabled {
  border-bottom: 1px dotted rgba(24, 28, 33, 0.3) !important;
  color: #a3a4a6 !important;
}

.custom-select-inverted {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAMCAYAAACEJVa/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFNJREFUeNpi+v//PwOlmImBCmDwGMJCpLoKIA7BIr4GiDuIdUkXEB9CEzsEFSfaO/+AuAqIr0D5V6D8f2AeidEpCMSToDRcnBFEDJ8opoohAAEGAD/3cpNUeZv2AAAAAElFTkSuQmCC") !important;
}
.custom-select-inverted, .custom-select-inverted:focus {
  background-color: transparent;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.custom-select-inverted:disabled {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.35) !important;
  color: rgba(255, 255, 255, 0.7) !important;
}
.custom-select-inverted::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.custom-select-inverted:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.custom-select-inverted::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.custom-select-inverted::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.custom-select-inverted option {
  color: #4E5155 !important;
}

.custom-select-sm,
.input-group-sm .custom-select {
  padding: 0.25rem 1.625rem 0.25rem 0.625rem;
  height: 1.5625rem;
  background-position: right 0.625rem center;
  font-size: 0.75rem;
  line-height: 1.5;
}
.custom-select-sm[size="0"]:not([multiple]),
.input-group-sm .custom-select[size="0"]:not([multiple]) {
  padding-right: 1.625rem;
}

.custom-select-lg,
.input-group-lg .custom-select {
  padding: 0.8125rem 2.25rem 0.8125rem 1.25rem;
  height: 3.125rem;
  background-position: right 1.25rem center;
  font-size: 1rem;
  line-height: 1.5;
}
/* .custom-select-lg[size="0"]:not([multiple]),
.input-group-lg .custom-select[size="0"]:not([multiple]) {
  padding-right: 2.25rem;
}

:not(.input-group) > .custom-select:not([class*='px-']):not([class*='pl-']) {
  padding-left: 0;
} */

:not(.input-group) > .custom-select:not([class*='px-']):not([class*='pr-']):not([multiple]):not([size]), :not(.input-group) > .custom-select:not([class*='px-']):not([class*='pr-']):not([multiple])[size="0"], :not(.input-group) > .custom-select:not([class*='px-']):not([class*='pr-']):not([multiple])[size="1"] {
  padding-right: 0.875rem !important;
  background-position: right center !important;
}

.custom-select[class][multiple],
.custom-select[size]:not([size="1"]):not([size="0"]) {
  padding-right: 0 !important;
  padding-left: 0 !important;
  height: auto !important;
  background-image: none !important;
}

.custom-select-sm[class][multiple], .custom-select-sm[size]:not([size="1"]):not([size="0"]),
.input-group-sm .custom-select[class][multiple],
.input-group-sm .custom-select[size]:not([size="1"]):not([size="0"]) {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.custom-select-lg[class][multiple], .custom-select-lg[size]:not([size="1"]):not([size="0"]),
.input-group-lg .custom-select[class][multiple],
.input-group-lg .custom-select[size]:not([size="1"]):not([size="0"]) {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.custom-file {
  display: block;
  width: 100%;
}

.custom-file-label {
  background-clip: padding-box;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  padding-right: 0;
  padding-left: 0;
  border-bottom-width: 1px;
}
.custom-file-input:disabled ~ .custom-file-label {
  background: transparent;
  border-bottom: 1px dotted rgba(24, 28, 33, 0.3) !important;
  color: #a3a4a6 !important;
}
.custom-file-input:disabled ~ .custom-file-label::before {
  opacity: .65;
}

.switcher {
  position: relative;
  display: inline-block;
  margin-right: 0.75rem;
  margin-bottom: 0;
  border-radius: 60rem;
  vertical-align: middle;
  font-weight: normal;
  padding-left: 2.25rem;
  min-height: 1.25rem;
  font-size: 0.894rem;
  line-height: 1.4;
}
.switcher .switcher-blank .switcher-indicator {
  top: 0.1875rem !important;
}
.switcher .switcher-indicator {
  width: 2.25rem;
  height: 0.875rem;
  font-size: 0.625rem;
  line-height: 1.25rem;
  top: 0.1883rem;
}
.switcher .switcher-label {
  top: 0;
}
.switcher .switcher-input:checked ~ .switcher-indicator::after {
  left: 1rem;
}
.switcher .switcher-indicator::after {
  top: -0.1875rem;
  margin: 0rem 0 0 0rem;
  width: 1.25rem;
  height: 1.25rem;
}
.switcher .switcher-no {
  padding-right: 0rem;
  padding-left: 1.25rem;
}
.switcher .switcher-yes {
  padding-right: 1.25rem;
  padding-left: 0rem;
}
.switcher .switcher-indicator::before {
  top: -0.1875rem;
  width: 1.25rem;
  height: 1.25rem;
}
.switcher .switcher-input:checked ~ .switcher-indicator::before {
  left: 1rem;
}
@media (min-width: 576px) {
  .form-inline .switcher {
    display: block;
    margin-right: 0;
  }
}

.switcher-input {
  position: absolute;
  z-index: -1;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.switcher-indicator {
  position: absolute;
  left: 0;
  overflow: hidden;
  border-radius: 60rem;
  background: rgba(24, 28, 33, 0.1);
  color: rgba(24, 28, 33, 0.3);
  font-weight: bold;
  cursor: default;
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
  -webkit-transition-property: left, right, background, -webkit-box-shadow;
  transition-property: left, right, background, -webkit-box-shadow;
  transition-property: left, right, background, box-shadow;
  transition-property: left, right, background, box-shadow, -webkit-box-shadow;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: visible;
}
.switcher-indicator::before {
  content: '';
  position: absolute;
  display: block;
  border-radius: 50%;
  background: rgba(24, 28, 33, 0.08);
  opacity: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
  -webkit-transform: scale(0) translateZ(0);
          transform: scale(0) translateZ(0);
}
.switcher-input:focus ~ .switcher-indicator::before {
  opacity: 1;
  -webkit-transform: scale(2.25) translateZ(0);
          transform: scale(2.25) translateZ(0);
}
.switcher-input:active ~ .switcher-indicator::before {
  opacity: 0;
  -webkit-transform: scale(0) translateZ(0);
          transform: scale(0) translateZ(0);
}
.switcher-input:disabled ~ .switcher-indicator::before {
  display: none !important;
}

.switcher-label {
  position: relative;
  display: inline-block;
  padding-left: 0.5rem;
  color: #4E5155;
  font-weight: 400;
  cursor: default;
}

.switcher-no,
.switcher-yes {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  display: none !important;
}

.switcher-no {
  left: 0;
}

.switcher-yes {
  left: -100%;
}
.switcher-input:not(:checked) ~ .switcher-indicator .switcher-yes {
  color: transparent !important;
}

.switcher-input:checked ~ .switcher-indicator .switcher-no {
  left: 100%;
  color: transparent !important;
}
.switcher-input:checked ~ .switcher-indicator .switcher-yes {
  left: 0;
}

.switcher-indicator::after {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  border-radius: 999px;
  background: #fff;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
  -webkit-transition-property: left, right, background;
  transition-property: left, right, background;
}

.switcher-input:disabled ~ .switcher-indicator,
fieldset[disabled] .switcher-input ~ .switcher-indicator {
  opacity: .5;
  cursor: not-allowed !important;
}
.switcher-input:disabled ~ .switcher-indicator::after,
fieldset[disabled] .switcher-input ~ .switcher-indicator::after {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.switcher-input:disabled ~ .switcher-label,
fieldset[disabled] .switcher-input ~ .switcher-label {
  color: #a3a4a6 !important;
  cursor: not-allowed;
}

.switchers-stacked .switcher {
  display: block;
  margin-right: 0;
  margin-bottom: 0.5rem;
}

.switcher-square,
.switcher-square .switcher-indicator {
  border-radius: 0.125rem;
}

.switcher-square .switcher-indicator::after {
  border-radius: 0.125rem;
}

.switcher-sm,
.form-group-sm .switcher {
  padding-left: 1.75rem;
  min-height: 1rem;
  font-size: 0.75rem;
  line-height: 1.486;
}
.switcher-sm .switcher-blank .switcher-indicator,
.form-group-sm .switcher .switcher-blank .switcher-indicator {
  top: 0.125rem !important;
}
.switcher-sm .switcher-indicator,
.form-group-sm .switcher .switcher-indicator {
  width: 1.75rem;
  height: 0.75rem;
  font-size: 0.625rem;
  line-height: 1rem;
  top: 0.18225rem;
}
.switcher-sm .switcher-label,
.form-group-sm .switcher .switcher-label {
  top: 0;
}
.switcher-sm .switcher-input:checked ~ .switcher-indicator::after,
.form-group-sm .switcher .switcher-input:checked ~ .switcher-indicator::after {
  left: 0.75rem;
}
.switcher-sm .switcher-indicator::after,
.form-group-sm .switcher .switcher-indicator::after {
  top: -0.125rem;
  margin: 0rem 0 0 0rem;
  width: 1rem;
  height: 1rem;
}
.switcher-sm .switcher-no,
.form-group-sm .switcher .switcher-no {
  padding-right: 0rem;
  padding-left: 1rem;
}
.switcher-sm .switcher-yes,
.form-group-sm .switcher .switcher-yes {
  padding-right: 1rem;
  padding-left: 0rem;
}
.switcher-sm .switcher-indicator::before,
.form-group-sm .switcher .switcher-indicator::before {
  top: -0.125rem;
  width: 1rem;
  height: 1rem;
}
.switcher-sm .switcher-input:checked ~ .switcher-indicator::before,
.form-group-sm .switcher .switcher-input:checked ~ .switcher-indicator::before {
  left: 0.75rem;
}

.switcher-lg,
.form-group-lg .switcher {
  padding-left: 2.75rem;
  min-height: 1.5rem;
  font-size: 1rem;
  line-height: 1.47;
}
.switcher-lg .switcher-blank .switcher-indicator,
.form-group-lg .switcher .switcher-blank .switcher-indicator {
  top: 0.1875rem !important;
}
.switcher-lg .switcher-indicator,
.form-group-lg .switcher .switcher-indicator {
  width: 2.75rem;
  height: 1.125rem;
  font-size: 0.625rem;
  line-height: 1.5rem;
  top: 0.1875rem;
}
.switcher-lg .switcher-label,
.form-group-lg .switcher .switcher-label {
  top: 0.015rem;
}
.switcher-lg .switcher-input:checked ~ .switcher-indicator::after,
.form-group-lg .switcher .switcher-input:checked ~ .switcher-indicator::after {
  left: 1.25rem;
}
.switcher-lg .switcher-indicator::after,
.form-group-lg .switcher .switcher-indicator::after {
  top: -0.1875rem;
  margin: 0rem 0 0 0rem;
  width: 1.5rem;
  height: 1.5rem;
}
.switcher-lg .switcher-no,
.form-group-lg .switcher .switcher-no {
  padding-right: 0rem;
  padding-left: 1.5rem;
}
.switcher-lg .switcher-yes,
.form-group-lg .switcher .switcher-yes {
  padding-right: 1.5rem;
  padding-left: 0rem;
}
.switcher-lg .switcher-indicator::before,
.form-group-lg .switcher .switcher-indicator::before {
  top: -0.1875rem;
  width: 1.5rem;
  height: 1.5rem;
}
.switcher-lg .switcher-input:checked ~ .switcher-indicator::before,
.form-group-lg .switcher .switcher-input:checked ~ .switcher-indicator::before {
  left: 1.25rem;
}

.switcher-secondary.switcher .switcher-input:checked ~ .switcher-indicator {
  background: rgba(136, 151, 170, 0.5);
}
.switcher-secondary.switcher .switcher-input:checked ~ .switcher-indicator::after {
  background: #8897AA;
}
.switcher-secondary.switcher .switcher-input:checked ~ .switcher-indicator::before {
  background: rgba(136, 151, 170, 0.15);
}

.switcher-success.switcher .switcher-input:checked ~ .switcher-indicator {
  background: rgba(40, 208, 148, 0.5);
}
.switcher-success.switcher .switcher-input:checked ~ .switcher-indicator::after {
  background: #28D094;
}
.switcher-success.switcher .switcher-input:checked ~ .switcher-indicator::before {
  background: rgba(40, 208, 148, 0.15);
}

.switcher-info.switcher .switcher-input:checked ~ .switcher-indicator {
  background: rgba(30, 159, 242, 0.5);
}
.switcher-info.switcher .switcher-input:checked ~ .switcher-indicator::after {
  background: #1E9FF2;
}
.switcher-info.switcher .switcher-input:checked ~ .switcher-indicator::before {
  background: rgba(30, 159, 242, 0.15);
}

.switcher-warning.switcher .switcher-input:checked ~ .switcher-indicator {
  background: rgba(255, 145, 73, 0.5);
}
.switcher-warning.switcher .switcher-input:checked ~ .switcher-indicator::after {
  background: #FF9149;
}
.switcher-warning.switcher .switcher-input:checked ~ .switcher-indicator::before {
  background: rgba(255, 145, 73, 0.15);
}

.switcher-danger.switcher .switcher-input:checked ~ .switcher-indicator {
  background: rgba(255, 73, 97, 0.5);
}
.switcher-danger.switcher .switcher-input:checked ~ .switcher-indicator::after {
  background: #FF4961;
}
.switcher-danger.switcher .switcher-input:checked ~ .switcher-indicator::before {
  background: rgba(255, 73, 97, 0.15);
}

.switcher-dark.switcher .switcher-input:checked ~ .switcher-indicator {
  background: rgba(44, 46, 62, 0.5);
}
.switcher-dark.switcher .switcher-input:checked ~ .switcher-indicator::after {
  background: #2c2e3e;
}
.switcher-dark.switcher .switcher-input:checked ~ .switcher-indicator::before {
  background: rgba(44, 46, 62, 0.15);
}

.was-validated .switcher-input:valid ~ .switcher-indicator, .switcher-input.is-valid ~ .switcher-indicator {
  background: rgba(40, 208, 148, 0.5) !important;
}
.was-validated .switcher-input:valid:checked ~ .switcher-indicator::after, .switcher-input.is-valid:checked ~ .switcher-indicator::after {
  background: #28D094 !important;
}
.was-validated .switcher-input:valid:checked ~ .switcher-indicator::before, .switcher-input.is-valid:checked ~ .switcher-indicator::before {
  background: rgba(40, 208, 148, 0.15) !important;
}
.was-validated .switcher-input:valid ~ .switcher-label, .switcher-input.is-valid ~ .switcher-label {
  color: #28D094 !important;
}

.was-validated .switcher-input:invalid ~ .switcher-indicator, .switcher-input.is-invalid ~ .switcher-indicator {
  background: rgba(255, 73, 97, 0.5) !important;
}
.was-validated .switcher-input:invalid:checked ~ .switcher-indicator::after, .switcher-input.is-invalid:checked ~ .switcher-indicator::after {
  background: #FF4961 !important;
}
.was-validated .switcher-input:invalid:checked ~ .switcher-indicator::before, .switcher-input.is-invalid:checked ~ .switcher-indicator::before {
  background: rgba(255, 73, 97, 0.15) !important;
}
.was-validated .switcher-input:invalid ~ .switcher-label, .switcher-input.is-invalid ~ .switcher-label {
  color: #FF4961 !important;
}

.input-group .custom-select {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}

.input-group-text {
  background-clip: padding-box;
}

.input-group-text {
  border-bottom-width: 1px;
}

.input-group-text + .input-group-text {
  padding-left: 0;
}

.input-group-prepend .input-group-text:first-child {
  padding-left: 0;
}

.input-group-prepend .input-group-text:last-child {
  padding-right: 0;
}

.input-group-append .input-group-text:last-child {
  padding-right: 0;
}

.input-group-append .input-group-text:first-child {
  padding-left: 0;
}

.input-group > .form-control:first-child:not([class*='px-']):not([class*='pl-']),
.input-group > .custom-select:first-child:not([class*='px-']):not([class*='pl-']),
.input-group > .form-control + .form-control:not([class*='px-']):not([class*='pl-']),
.input-group > .form-control + .custom-select:not([class*='px-']):not([class*='pl-']),
.input-group > .custom-select + .form-control:not([class*='px-']):not([class*='pl-']),
.input-group > .custom-select + .custom-select:not([class*='px-']):not([class*='pl-']) {
  padding-left: 0;
}

.input-group > .form-control:last-child:not([class*='px-']):not([class*='pr-']) {
  padding-right: 0;
}

.input-group > .custom-select:last-child:not([class*='px-']):not([class*='pr-']) {
  padding-right: 0.875rem !important;
  background-position: right center !important;
}

.modal {
  z-index: 1090;
}

.modal-backdrop {
  z-index: 1089;
}

.modal-content {
  -webkit-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.15), 0px 24px 38px 3px rgba(0, 0, 0, 0.1), 0px 9px 46px 8px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.15), 0px 24px 38px 3px rgba(0, 0, 0, 0.1), 0px 9px 46px 8px rgba(0, 0, 0, 0.08);
  border-radius: 0;
}

.modal-header {
  position: relative;
  padding: 1.25rem 2.5625rem 0 1.5625rem;
}

.modal-footer {
  padding: 1rem 1.5rem;
}

.modal-header .close,
.modal-slide .close {
  position: absolute;
  top: 50%;
  right: 1.5625rem;
  margin: 0;
  padding: 0;
  line-height: 1.47;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

@media (min-width: 576px) {
  .modal-content {
    -webkit-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.15), 0px 24px 38px 3px rgba(0, 0, 0, 0.1), 0px 9px 46px 8px rgba(0, 0, 0, 0.08);
            box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.15), 0px 24px 38px 3px rgba(0, 0, 0, 0.1), 0px 9px 46px 8px rgba(0, 0, 0, 0.08);
  }

  ngb-modal-window.modal-sm {
    max-width: none;
  }

  .modal-sm .modal-dialog {
    max-width: 22.5rem;
  }
}
@media (min-width: 992px) {
  ngb-modal-window.modal-lg {
    max-width: none;
  }

  .modal-lg .modal-dialog {
    max-width: 50rem;
  }
}
.modal-top .modal-dialog {
  margin-top: 0;
}
.modal-top .modal-content {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.modal-slide,
.modal-slide .modal {
  overflow: hidden !important;
  padding: 0 !important;
}

.modal-slide .modal-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  margin: 0;
  max-width: none;
  width: 100%;
}
@media (min-width: 576px) {
  .modal-slide .modal-dialog {
    width: 20rem;
  }
}
.modal-slide .modal-content {
  overflow: auto;
  padding-top: 3.22127rem;
  padding-bottom: 3.22127rem;
  height: 100%;
  border-radius: 0;
}
.modal-slide .modal-body {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin: auto 0;
  padding-top: 0;
  padding-bottom: 0;
}
.modal-slide .close {
  top: 0.625rem;
  z-index: 10;
  -webkit-transform: none;
          transform: none;
}

.modal-fill-in .modal-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  padding-top: 3.22127rem;
  padding-bottom: 3.22127rem;
  min-height: 100vh;
}
.modal-fill-in .modal-content {
  margin: auto;
  width: 100%;
  border: 0;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.modal-fill-in .close {
  position: absolute;
  top: -2rem;
  right: 1.5625rem;
  font-size: 2rem;
  -webkit-transform: none;
          transform: none;
}

.modal.fade .modal-dialog {
  -webkit-transform: translateY(150px) scale(0.8);
          transform: translateY(150px) scale(0.8);
}

.modal.show .modal-dialog {
  -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
}

.modal-top.fade .modal-dialog,
.modal-top .modal.fade .modal-dialog {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.modal-top.show .modal-dialog,
.modal-top .modal.show .modal-dialog {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.modal-slide.fade .modal-dialog,
.modal-slide .modal.fade .modal-dialog {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.modal-slide.show .modal-dialog,
.modal-slide .modal.show .modal-dialog {
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
}

.modal-fill-in.fade .modal-dialog,
.modal-fill-in .modal.fade .modal-dialog {
  -webkit-transform: scale(0.5, 0.5);
          transform: scale(0.5, 0.5);
}

.modal-fill-in.show .modal-dialog,
.modal-fill-in .modal.show .modal-dialog {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.breadcrumb-item,
.breadcrumb-item a {
  color: #a3a4a6;
}
.breadcrumb-item:hover,
.breadcrumb-item a:hover {
  color: #4E5155;
}

.list-group-item-secondary {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #f3f5f7;
  color: #616263;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #616263;
}
a.list-group-item-secondary:hover, a.list-group-item-secondary:focus,
button.list-group-item-secondary:hover,
button.list-group-item-secondary:focus {
  background-color: #f1f3f5;
  color: #616263;
}
a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  border-color: #8897AA;
  background-color: #8897AA;
  color: #616263;
}

.list-group-item-success {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #eafaf4;
  color: #5e6462;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #5e6462;
}
a.list-group-item-success:hover, a.list-group-item-success:focus,
button.list-group-item-success:hover,
button.list-group-item-success:focus {
  background-color: #e8f8f2;
  color: #5e6462;
}
a.list-group-item-success.active,
button.list-group-item-success.active {
  border-color: #28D094;
  background-color: #28D094;
  color: #5e6462;
}

.list-group-item-info {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #e9f5fe;
  color: #5d6266;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #5d6266;
}
a.list-group-item-info:hover, a.list-group-item-info:focus,
button.list-group-item-info:hover,
button.list-group-item-info:focus {
  background-color: #e7f3fb;
  color: #5d6266;
}
a.list-group-item-info.active,
button.list-group-item-info.active {
  border-color: #1E9FF2;
  background-color: #1E9FF2;
  color: #5d6266;
}

.list-group-item-warning {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #fff4ed;
  color: #66625f;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #66625f;
}
a.list-group-item-warning:hover, a.list-group-item-warning:focus,
button.list-group-item-warning:hover,
button.list-group-item-warning:focus {
  background-color: #fcf2eb;
  color: #66625f;
}
a.list-group-item-warning.active,
button.list-group-item-warning.active {
  border-color: #FF9149;
  background-color: #FF9149;
  color: #66625f;
}

.list-group-item-danger {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #ffedef;
  color: #665f60;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #665f60;
}
a.list-group-item-danger:hover, a.list-group-item-danger:focus,
button.list-group-item-danger:hover,
button.list-group-item-danger:focus {
  background-color: #fcebed;
  color: #665f60;
}
a.list-group-item-danger.active,
button.list-group-item-danger.active {
  border-color: #FF4961;
  background-color: #FF4961;
  color: #665f60;
}

.list-group-item-dark {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #eaeaec;
  color: #5e5e5e;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #5e5e5e;
}
a.list-group-item-dark:hover, a.list-group-item-dark:focus,
button.list-group-item-dark:hover,
button.list-group-item-dark:focus {
  background-color: #e8e8ea;
  color: #5e5e5e;
}
a.list-group-item-dark.active,
button.list-group-item-dark.active {
  border-color: #2c2e3e;
  background-color: #2c2e3e;
  color: #5e5e5e;
}

.navbar {
  z-index: 2;
  -webkit-box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.04);
          box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.04);
}

.fixed-top {
  z-index: 1030;
}

.navbar.navbar-dark {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.4) !important;
}

.navbar.navbar-light {
  color: rgba(24, 28, 33, 0.4);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(24, 28, 33, 0.2) !important;
}

.navbar-collapse,
.navbar-brand,
.navbar-text {
  -ms-flex-negative: 1;
      flex-shrink: 1;
}

.navbar-dark hr {
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-light hr {
  border-color: rgba(24, 28, 33, 0.06);
}

.navbar-icon {
  font-size: 130%;
}

.navbar-search-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  width: 200px;
}
.navbar-search-box:not(.active) {
  cursor: pointer;
}

.navbar-search-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 0;
  -webkit-transition: max-width .3s ease-in-out;
  transition: max-width .3s ease-in-out;
}
.navbar-search-box.active .navbar-search-input {
  max-width: 100vw;
}
.navbar-search-input .form-control {
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: auto;
  border: 0 !important;
  border-radius: 0 !important;
  background: none;
  color: inherit !important;
}
.navbar-dark .navbar-search-input .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.navbar-dark .navbar-search-input .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.navbar-dark .navbar-search-input .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.navbar-dark .navbar-search-input .form-control::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.navbar-light .navbar-search-input .form-control::-webkit-input-placeholder {
  color: rgba(24, 28, 33, 0.2);
}
.navbar-light .navbar-search-input .form-control:-ms-input-placeholder {
  color: rgba(24, 28, 33, 0.2);
}
.navbar-light .navbar-search-input .form-control::-ms-input-placeholder {
  color: rgba(24, 28, 33, 0.2);
}
.navbar-light .navbar-search-input .form-control::placeholder {
  color: rgba(24, 28, 33, 0.2);
}

.navbar-search-cancel {
  color: inherit !important;
  font-weight: 100;
  font-size: 1.55em;
  line-height: 1;
  opacity: .5;
}
.navbar-search-cancel:hover, .navbar-search-cancel:focus {
  opacity: 1;
}

.navbar-expand-sm .navbar-search-box.nav-link {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important;
  }
  .navbar-expand-sm .navbar-search-input .form-control {
    width: 100% !important;
  }
  .navbar-expand-sm .navbar-search-cancel {
    display: none !important;
  }
  .navbar-expand-sm.navbar-dark .navbar-search-box {
    color: #fff !important;
  }
  .navbar-expand-sm.navbar-light .navbar-search-box {
    color: #4E5155 !important;
  }
}
@media (min-width: 576px) {
  .navbar-expand-md .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important;
  }
  .navbar-expand-md .navbar-search-input .form-control {
    width: 100% !important;
  }
  .navbar-expand-md .navbar-search-cancel {
    display: none !important;
  }
  .navbar-expand-md.navbar-dark .navbar-search-box {
    color: #fff !important;
  }
  .navbar-expand-md.navbar-light .navbar-search-box {
    color: #4E5155 !important;
  }
}
@media (min-width: 768px) {
  .navbar-expand-lg .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important;
  }
  .navbar-expand-lg .navbar-search-input .form-control {
    width: 100% !important;
  }
  .navbar-expand-lg .navbar-search-cancel {
    display: none !important;
  }
  .navbar-expand-lg.navbar-dark .navbar-search-box {
    color: #fff !important;
  }
  .navbar-expand-lg.navbar-light .navbar-search-box {
    color: #4E5155 !important;
  }
}
@media (min-width: 992px) {
  .navbar-expand-xl .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important;
  }
  .navbar-expand-xl .navbar-search-input .form-control {
    width: 100% !important;
  }
  .navbar-expand-xl .navbar-search-cancel {
    display: none !important;
  }
  .navbar-expand-xl.navbar-dark .navbar-search-box {
    color: #fff !important;
  }
  .navbar-expand-xl.navbar-light .navbar-search-box {
    color: #4E5155 !important;
  }
}
@media (min-width: 1200px) {
  .navbar-expand .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.navbar-expand .navbar-search-input {
  max-width: 100vw !important;
  width: 100% !important;
}
.navbar-expand .navbar-search-input .form-control {
  width: 100% !important;
}
.navbar-expand .navbar-search-cancel {
  display: none !important;
}
.navbar-expand.navbar-dark .navbar-search-box {
  color: #fff !important;
}
.navbar-expand.navbar-light .navbar-search-box {
  color: #4E5155 !important;
}

.mega-dropdown .dropdown-menu {
  width: 100%;
}
.mega-dropdown .dropdown-toggle {
  outline: 0;
}

.card {
  background-clip: padding-box;
  -webkit-box-shadow: 0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07);
    box-shadow: 0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07);
}

.card-link {
  display: inline-block;
}

.card-header {
  line-height: 1.54;
  border-bottom-width: 1px;
}

.card-footer {
  border-top-width: 1px;
}

.card-header,
.card-footer,
.card hr {
  border-color: rgba(24, 28, 33, 0.06);
}

.card .list-group-item {
  border-color: #f1f1f2;
}

.card .row-bordered > [class^="col-"]::before, .card .row-bordered > [class^="col-"]::after,
.card .row-bordered > [class*=" col-"]::before,
.card .row-bordered > [class*=" col-"]::after,
.card .row-bordered > [class^="col "]::before,
.card .row-bordered > [class^="col "]::after,
.card .row-bordered > [class*=" col "]::before,
.card .row-bordered > [class*=" col "]::after,
.card .row-bordered > [class$=" col"]::before,
.card .row-bordered > [class$=" col"]::after,
.card .row-bordered > [class="col"]::before,
.card .row-bordered > [class="col"]::after {
  border-color: rgba(24, 28, 33, 0.06);
}

.card-header.with-elements,
.card-title.with-elements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.card-header.with-elements {
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
}

.card-header-elements,
.card-title-elements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.card-header-elements + .card-header-elements,
.card-header-elements > * + *,
.card-title-elements + .card-header-elements,
.card-title-elements > * + *, .card-header-elements +
.card-title-elements,
.card-title-elements +
.card-title-elements {
  margin-left: .25rem;
}

.card-header-title {
  padding: 0.65rem 0;
}

.card-header-elements {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.card > .list-group .list-group-item {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.card-condenced .card-header,
.card-condenced .card-footer,
.card-condenced > .list-group .list-group-item {
  padding-right: 1rem;
  padding-left: 1rem;
}
.card-condenced .card-body,
.card-condenced .card-img-overlay {
  padding: 1rem;
}

.ngb-accordion-with-icon .card-header > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.collapse-icon {
  position: relative;
  display: block;
  -ms-flex-item-align: center;
      align-self: center;
  width: .875rem;
  height: .875rem;
}
.collapse-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  margin-top: -.25rem;
  margin-left: -.25rem;
  width: .5rem;
  height: .5rem;
  border-bottom: 0.0625rem solid rgba(24, 28, 33, 0.3);
  border-left: 0.0625rem solid rgba(24, 28, 33, 0.3);
  -webkit-transition: all .3s;
  transition: all .3s;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
[aria-expanded="true"] .collapse-icon::after {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

.footer-link {
  display: inline-block;
}

.footer-dark {
  color: rgba(255, 255, 255, 0.8);
}
.footer-dark .footer-link {
  color: rgba(255, 255, 255, 0.8);
}
.footer-dark .footer-link:hover, .footer-dark .footer-link:focus {
  color: #fff;
}
.footer-dark .footer-link.disabled {
  color: rgba(255, 255, 255, 0.4) !important;
}
.footer-dark .footer-text {
  color: #fff;
}
.footer-dark .show > .footer-link,
.footer-dark .active > .footer-link,
.footer-dark .footer-link.show,
.footer-dark .footer-link.active {
  color: #fff;
}
.footer-dark hr {
  border-color: rgba(255, 255, 255, 0.1);
}

.footer-light {
  color: rgba(24, 28, 33, 0.4);
}
.footer-light .footer-link {
  color: rgba(24, 28, 33, 0.4);
}
.footer-light .footer-link:hover, .footer-light .footer-link:focus {
  color: #4E5155;
}
.footer-light .footer-link.disabled {
  color: rgba(24, 28, 33, 0.2) !important;
}
.footer-light .footer-text {
  color: #4E5155;
}
.footer-light .show > .footer-link,
.footer-light .active > .footer-link,
.footer-light .footer-link.show,
.footer-light .footer-link.active {
  color: #4E5155;
}
.footer-light hr {
  border-color: rgba(255, 255, 255, 0.2);
}

.sidenav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.sidenav .ps__thumb-y,
.sidenav .ps__rail-y {
  width: .125rem !important;
}
.sidenav .ps__rail-y {
  right: .25rem !important;
  left: auto !important;
  background: none !important;
}
.sidenav .ps__rail-y:hover,
.sidenav .ps__rail-y:focus,
.sidenav .ps__rail-y.ps--clicking,
.sidenav .ps__rail-y:hover > .ps__thumb-y,
.sidenav .ps__rail-y:focus > .ps__thumb-y,
.sidenav .ps__rail-y.ps--clicking > .ps__thumb-y {
  width: .375rem !important;
}

.sidenav-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.sidenav-item,
.sidenav-header,
.sidenav-divider,
.sidenav-block {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidenav-item {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.sidenav-item.sidenav-item-animating {
  -webkit-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}

.sidenav-item .sidenav-link {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}
.sidenav-item.active > .sidenav-item .sidenav-link {
  font-weight: 500;
}
.sidenav-item.disabled .sidenav-item .sidenav-link {
  cursor: default !important;
}
.sidenav:not(.sidenav-no-animation) .sidenav-item .sidenav-link {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.sidenav-item .sidenav-link > :not(.sidenav-icon) {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}

.sidenav-toggle::after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: 0.42em;
  height: 0.42em;
  border: 1px solid;
  border-top: 0;
  border-right: 0;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle::after {
  -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
}
.sidenav:not(.sidenav-no-animation) .sidenav-toggle::after {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  transition-property: -webkit-transform, transform;
}

.sidenav-menu {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
  padding: 0;
}
.sidenav:not(.sidenav-no-animation) .sidenav-menu {
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.sidenav-item.open > .sidenav-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sidenav-icon {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 0.25rem;
  font-size: 1rem;
}

.sidenav-divider {
  width: calc( 100% - 60px);
  border: 0;
  border-top: 1px solid;
  margin: 0 30px;
}

.sidenav-vertical {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.sidenav-vertical:not(.sidenav-no-animation) {
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.sidenav-vertical,
.sidenav-vertical .sidenav-block,
.sidenav-vertical .sidenav-inner > .sidenav-item,
.sidenav-vertical .sidenav-inner > .sidenav-header {
  width: 14.625rem;
}
.sidenav-vertical .sidenav-inner {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.sidenav-vertical .sidenav-inner > .sidenav-item {
  margin: 0.125rem 0;
}
.sidenav-vertical .sidenav-item .sidenav-link,
.sidenav-vertical .sidenav-header,
.sidenav-vertical .sidenav-block {
  padding: 0.75rem 1.7rem;
}
.sidenav-vertical .sidenav-divider {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  padding: 0;
}
.sidenav-vertical .sidenav-item .sidenav-toggle {
  padding-right: calc(1.7rem + 1.26em);
}
.sidenav-vertical .sidenav-item .sidenav-toggle::after {
  right: 1.7rem;
}
.sidenav-vertical .sidenav-menu {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.sidenav-vertical .sidenav-menu .sidenav-link {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.sidenav-vertical .sidenav-icon {
  width: 1.75rem;
}
.sidenav-vertical .sidenav-menu .sidenav-icon {
  margin-right: 0;
}
.sidenav-vertical .sidenav-menu .sidenav-link {
  padding-left: 3.7rem;
}
.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 4.7rem;
}
.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 5.7rem;
}
.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 6.7rem;
}
.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 7.7rem;
}

.sidenav-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
}
.sidenav-horizontal .sidenav-inner {
  overflow: hidden;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
          flex: 0 1 100%;
}
.sidenav-horizontal .sidenav-item .sidenav-link {
  padding: 1rem 1rem;
}
.sidenav-horizontal .sidenav-item .sidenav-toggle {
  padding-right: calc(1rem + 1.26em);
}
.sidenav-horizontal .sidenav-item .sidenav-toggle::after {
  right: 1rem;
}
.sidenav-horizontal .sidenav-inner > .sidenav-item > .sidenav-toggle::after {
  -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
}
.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing).open > .sidenav-toggle::after {
  -webkit-transform: translateY(-50%) rotate(135deg);
          transform: translateY(-50%) rotate(135deg);
}
.sidenav-horizontal .sidenav-header,
.sidenav-horizontal .sidenav-divider {
  display: none !important;
}
.sidenav-horizontal .sidenav-menu {
  position: absolute;
  width: 12.5rem;
}
.sidenav-horizontal .sidenav-menu .sidenav-menu {
  position: static;
  width: auto;
}
.sidenav-horizontal .sidenav-menu .sidenav-link {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.sidenav-horizontal .sidenav-inner > .sidenav-item > .sidenav-menu {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.sidenav-horizontal:not(.sidenav-no-animation) .sidenav-inner > .sidenav-item.open > .sidenav-menu {
  -webkit-animation: sidenavDropdownShow 0.2s ease-in-out;
          animation: sidenavDropdownShow 0.2s ease-in-out;
}
.sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 2rem;
}
.sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 3rem;
}
.sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 4rem;
}
.sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 5rem;
}

.sidenav-horizontal-wrapper {
  overflow: hidden;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
          flex: 0 1 100%;
  width: 0;
}
.sidenav:not(.sidenav-no-animation) .sidenav-horizontal-wrapper .sidenav-inner {
  -webkit-transition: margin 0.2s;
  transition: margin 0.2s;
}

.sidenav-horizontal-prev,
.sidenav-horizontal-next {
  position: relative;
  display: block;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 2.25rem;
}
.sidenav-horizontal-prev::after,
.sidenav-horizontal-next::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border: 1px solid;
  border-top: 0;
}
.sidenav-horizontal-prev.disabled,
.sidenav-horizontal-next.disabled {
  cursor: default !important;
}

.sidenav-horizontal-prev::after {
  border-right: 0;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}

.sidenav-horizontal-next::after {
  border-left: 0;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}

@-webkit-keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.sidenav-dark {
  color: rgba(255, 255, 255, 0.8);
}
.sidenav-dark .sidenav-link,
.sidenav-dark .sidenav-horizontal-prev,
.sidenav-dark .sidenav-horizontal-next {
  color: rgba(255, 255, 255, 0.8);
}
.sidenav-dark .sidenav-link:hover, .sidenav-dark .sidenav-link:focus,
.sidenav-dark .sidenav-horizontal-prev:hover,
.sidenav-dark .sidenav-horizontal-prev:focus,
.sidenav-dark .sidenav-horizontal-next:hover,
.sidenav-dark .sidenav-horizontal-next:focus {
  color: #fff;
}
.sidenav-dark .sidenav-link.active,
.sidenav-dark .sidenav-horizontal-prev.active,
.sidenav-dark .sidenav-horizontal-next.active {
  color: #fff;
}
.sidenav-dark .sidenav-item.disabled .sidenav-link {
  color: rgba(255, 255, 255, 0.4) !important;
}
.sidenav-dark .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav-dark .sidenav-item.active > .sidenav-link {
  color: #fff;
}
.sidenav-dark .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background: rgba(0, 0, 0, 0.1);
}
.sidenav-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  color: rgba(255, 255, 255, 0.8);
}
.sidenav-dark .sidenav-text {
  color: #fff;
}
.sidenav-dark .sidenav-header {
  color: rgba(255, 255, 255, 0.8);
}
.sidenav-dark hr,
.sidenav-dark .sidenav-divider,
.sidenav-dark .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.sidenav-dark .sidenav-inner > .sidenav-header::before,
.sidenav-dark .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.4);
}
.sidenav-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: rgba(255, 255, 255, 0.1);
}
.sidenav-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}
.sidenav-dark .ps__thumb-y {
  background: rgba(255, 255, 255, 0.8) !important;
}

.sidenav-light {
  color: rgba(24, 28, 33, 0.4);
}
.sidenav-light .sidenav-link,
.sidenav-light .sidenav-horizontal-prev,
.sidenav-light .sidenav-horizontal-next {
  color: rgba(24, 28, 33, 0.4);
}
.sidenav-light .sidenav-link:hover, .sidenav-light .sidenav-link:focus,
.sidenav-light .sidenav-horizontal-prev:hover,
.sidenav-light .sidenav-horizontal-prev:focus,
.sidenav-light .sidenav-horizontal-next:hover,
.sidenav-light .sidenav-horizontal-next:focus {
  color: #4E5155;
}
.sidenav-light .sidenav-link.active,
.sidenav-light .sidenav-horizontal-prev.active,
.sidenav-light .sidenav-horizontal-next.active {
  color: #4E5155;
}
.sidenav-light .sidenav-item.disabled .sidenav-link {
  color: rgba(24, 28, 33, 0.2) !important;
}
.sidenav-light .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav-light .sidenav-item.active > .sidenav-link {
  color: #4E5155;
}
.sidenav-light .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background: rgba(0, 0, 0, 0.2);
}
.sidenav-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  color: rgba(24, 28, 33, 0.4);
}
.sidenav-light .sidenav-text {
  color: #4E5155;
}
.sidenav-light .sidenav-header {
  color: rgba(24, 28, 33, 0.4);
}
.sidenav-light hr,
.sidenav-light .sidenav-divider,
.sidenav-light .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.2) !important;
}
.sidenav-light .sidenav-inner > .sidenav-header::before,
.sidenav-light .sidenav-block::before {
  background-color: rgba(24, 28, 33, 0.2);
}
.sidenav-light .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.sidenav-light .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #4E5155;
}
.sidenav-light .ps__thumb-y {
  background: rgba(24, 28, 33, 0.4) !important;
}

.layout-wrapper,
.layout-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 100%;
}

.layout-wrapper {
  overflow: hidden;
}

.layout-inner {
  min-height: 100vh;
}

.layout-container,
.layout-content,
.layout-content > *,
.layout-sidenav {
  min-height: 1px;
}

.layout-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 0;
}
.layout-without-sidenav .layout-container {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.layout-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          /* justify-content: space-between; */
}

.layout-navbar,
.layout-footer {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.layout-navbar {
  position: relative;
  z-index: 2;
}
.layout-navbar .navbar {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.layout-sidenav {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.layout-sidenav .sidenav {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.layout-sidenav .sidenav-vertical {
  height: 100%;
}

.layout-1 .layout-inner {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.layout-1 .layout-content {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  width: 0;
  min-width: 0;
  max-width: 100%;
}

.layout-2 .layout-container {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 0;
  min-width: 0;
  max-width: 100%;
}
.layout-2 .layout-content {
  width: 100%;
}

.layout-reversed .layout-1 .layout-container {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.layout-reversed .layout-2 .layout-inner {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.layout-sidenav-toggle {
  display: block;
}

@media (max-width: 991px) {
  .layout-sidenav {
    position: fixed !important;
    top: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    will-change: transform, -webkit-transform;
  }
  .layout-reversed .layout-sidenav {
    right: 0 !important;
    left: auto !important;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  .layout-expanded .layout-sidenav {
    -webkit-transform: translate3d(0, 0, 0) !important;
            transform: translate3d(0, 0, 0) !important;
  }

  .layout-expanded body {
    overflow: hidden;
  }

  .layout-overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100% !important;
    left: 0;
    display: none;
    background: #181C21;
    opacity: 0.2;
    cursor: pointer;
  }
  .layout-expanded .layout-overlay {
    display: block;
  }

  .layout-sidenav-100vh .layout-sidenav,
  .layout-sidenav-100vh .layout-overlay {
    height: 100vh !important;
  }
}
@media (min-width: 992px) {
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) {
    width: 4.375rem;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item {
    width: 16.55rem;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-link,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link {
    padding-left: 4.375rem;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-header,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-block,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-header,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-block {
    position: relative;
    margin-left: 4.375rem;
    padding-right: 3.15rem;
    padding-left: 0.25rem;
    width: 15.625rem;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-header::before,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-block::before,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-header::before,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-block::before {
    content: "";
    position: absolute;
    top: 0.75rem;
    bottom: 0.75rem;
    left: -3.28125rem;
    display: block;
    width: 2.1875rem;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-menu,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open > .sidenav-menu,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu {
    position: relative;
    margin-left: 0.925rem;
    background: none !important;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-menu .sidenav-link,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open > .sidenav-menu .sidenav-link,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu .sidenav-link,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu .sidenav-link {
    background: none !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open > .sidenav-menu::before,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(2.1875rem - 1px);
    display: block;
    margin-left: -0.925rem;
    width: 0;
    border-left: 2px solid;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    margin-top: -4px;
    margin-left: calc(1.2625rem - 4px);
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-toggle::after,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-toggle::after {
    right: auto;
    left: 3.5rem;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon {
    margin-left: -4.375rem;
    width: 4.375rem;
    text-align: center;
  }

  .layout-collapsed .layout-sidenav:hover {
    margin-right: -11.25rem;
  }
  .layout-collapsed.layout-reversed .layout-sidenav:hover {
    margin-right: 0;
    margin-left: -11.25rem;
  }
}
@media (min-width: 992px) {
  .layout-collapsed.layout-offcanvas .layout-sidenav {
    margin-right: -15.625rem;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .layout-collapsed.layout-offcanvas.layout-reversed .layout-sidenav {
    margin-right: 0;
    margin-left: -15.625rem;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@media (min-width: 992px) {
  .layout-fixed .layout-sidenav,
  .layout-fixed-offcanvas .layout-sidenav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .layout-fixed.layout-reversed .layout-sidenav,
  .layout-fixed-offcanvas.layout-reversed .layout-sidenav {
    right: 0;
    left: auto;
  }

  .layout-fixed-offcanvas.layout-collapsed .layout-sidenav {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .layout-fixed-offcanvas.layout-collapsed.layout-reversed .layout-sidenav {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  .layout-fixed:not(.layout-collapsed) .layout-container,
  .layout-fixed-offcanvas:not(.layout-collapsed) .layout-container {
    padding-left: 14.625rem;
  }
  .layout-fixed:not(.layout-collapsed).layout-reversed .layout-container,
  .layout-fixed-offcanvas:not(.layout-collapsed).layout-reversed .layout-container {
    padding-right: 15.625rem;
    padding-left: 0;
  }

  .layout-fixed.layout-collapsed .layout-container {
    padding-left: 4.375rem;
  }
  .layout-fixed.layout-collapsed.layout-reversed .layout-container {
    padding-right: 4.375rem;
    padding-left: 0;
  }
}
html:not(.layout-navbar-fixed):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-container,
html:not(.layout-navbar-fixed) .layout-2 .layout-container {
  padding-top: 0 !important;
}

html:not(.layout-footer-fixed) .layout-content {
  padding-bottom: 0 !important;
}

@media (max-width: 991px) {
  .layout-fixed .layout-wrapper.layout-1 .layout-sidenav,
  .layout-fixed-offcanvas .layout-wrapper.layout-1 .layout-sidenav {
    top: 0 !important;
  }

  html:not(.layout-navbar-fixed) .layout-1 .layout-container {
    padding-top: 0 !important;
  }
}
.layout-navbar-fixed .layout-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

@media (min-width: 992px) {
  .layout-fixed .layout-1 .layout-navbar,
  .layout-fixed-offcanvas .layout-1 .layout-navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }

  .layout-navbar-fixed:not(.layout-collapsed) .layout-2 .layout-navbar,
  .layout-fixed.layout-navbar-fixed:not(.layout-collapsed) .layout-2 .layout-navbar,
  .layout-fixed-offcanvas.layout-navbar-fixed:not(.layout-collapsed) .layout-2 .layout-navbar {
    left: 15.625rem;
  }
  .layout-navbar-fixed:not(.layout-collapsed).layout-reversed .layout-2 .layout-navbar,
  .layout-fixed.layout-navbar-fixed:not(.layout-collapsed).layout-reversed .layout-2 .layout-navbar,
  .layout-fixed-offcanvas.layout-navbar-fixed:not(.layout-collapsed).layout-reversed .layout-2 .layout-navbar {
    right: 15.625rem;
    left: 0;
  }

  .layout-navbar-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-2 .layout-navbar,
  .layout-fixed.layout-navbar-fixed.layout-collapsed .layout-2 .layout-navbar {
    left: 4.375rem;
  }
  .layout-navbar-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas).layout-reversed .layout-2 .layout-navbar,
  .layout-fixed.layout-navbar-fixed.layout-collapsed.layout-reversed .layout-2 .layout-navbar {
    right: 4.375rem;
    left: 0;
  }
}
.layout-footer-fixed .layout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .layout-footer-fixed:not(.layout-collapsed) .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    left: 15.625rem;
  }
  .layout-footer-fixed:not(.layout-collapsed).layout-reversed .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    right: 15.625rem;
    left: 0;
  }

  .layout-footer-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    left: 4.375rem;
  }
  .layout-footer-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas).layout-reversed .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    right: 4.375rem;
    left: 0;
  }
}
.layout-navbar-fixed body:not(.modal-open) .layout-1 .layout-navbar,
.layout-fixed body:not(.modal-open) .layout-1 .layout-navbar,
.layout-fixed-offcanvas body:not(.modal-open) .layout-1 .layout-navbar {
  z-index: 1080;
}
.layout-navbar-fixed body:not(.modal-open) .layout-2 .layout-navbar,
.layout-fixed body:not(.modal-open) .layout-2 .layout-navbar,
.layout-fixed-offcanvas body:not(.modal-open) .layout-2 .layout-navbar {
  z-index: 1075;
}

.layout-footer-fixed .layout-footer {
  z-index: 1030;
}

.layout-sidenav-horizontal {
  z-index: 9;
}

@media (max-width: 991px) {
  .layout-sidenav {
    z-index: 1100;
  }

  .layout-overlay {
    z-index: 1099;
  }
}
@media (min-width: 992px) {
  .layout-1 .layout-navbar {
    z-index: 10;
  }
  .layout-1 .layout-sidenav {
    z-index: 9;
  }

  .layout-2 .layout-navbar {
    z-index: 9;
  }
  .layout-2 .layout-sidenav {
    z-index: 10;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-1 .layout-sidenav:hover {
    z-index: 1075 !important;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-2 .layout-sidenav {
    z-index: 1085 !important;
  }

  .layout-fixed body:not(.modal-open) .layout-1 .layout-sidenav,
  .layout-fixed-offcanvas body:not(.modal-open) .layout-1 .layout-sidenav {
    z-index: 1075;
  }

  .layout-navbar-fixed body:not(.modal-open) .layout-2 .layout-sidenav,
  .layout-fixed body:not(.modal-open) .layout-2 .layout-sidenav,
  .layout-fixed-offcanvas body:not(.modal-open) .layout-2 .layout-sidenav {
    z-index: 1080;
  }
}
.layout-sidenav-link-no-transition .layout-sidenav .sidenav-link,
.layout-sidenav-link-no-transition .layout-sidenav-horizontal .sidenav-link {
  -webkit-transition: none !important;
  transition: none !important;
  -webkit-animation: none !important;
          animation: none !important;
}

.layout-no-transition .layout-sidenav, .layout-no-transition .layout-sidenav .sidenav, .layout-no-transition .layout-sidenav .sidenav-item,
.layout-no-transition .layout-sidenav-horizontal,
.layout-no-transition .layout-sidenav-horizontal .sidenav,
.layout-no-transition .layout-sidenav-horizontal .sidenav-item {
  -webkit-transition: none !important;
  transition: none !important;
  -webkit-animation: none !important;
          animation: none !important;
}

@media (max-width: 991px) {
  .layout-transitioning .layout-overlay {
    -webkit-animation: layoutSidenavAnimation 0.2s;
            animation: layoutSidenavAnimation 0.2s;
  }
  .layout-transitioning .layout-sidenav {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    transition-property: transform, -webkit-transform;
  }
}
@media (min-width: 992px) {
  .layout-collapsed:not(.layout-transitioning):not(.layout-offcanvas):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-sidenav {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: margin-left, margin-right, width;
    transition-property: margin-left, margin-right, width;
  }

  .layout-transitioning.layout-offcanvas .layout-sidenav {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    transition-property: margin-left, margin-right, transform, -webkit-transform;
  }
  .layout-transitioning.layout-fixed .layout-container, .layout-transitioning.layout-fixed-offcanvas .layout-container {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: padding-left, padding-right;
    transition-property: padding-left, padding-right;
  }
  .layout-transitioning.layout-fixed .layout-sidenav {
    -webkit-transition: width 0.2s;
    transition: width 0.2s;
  }
  .layout-transitioning.layout-fixed-offcanvas .layout-sidenav {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    transition-property: transform, -webkit-transform;
  }
  .layout-transitioning.layout-navbar-fixed .layout-2 .layout-navbar, .layout-transitioning.layout-footer-fixed .layout-footer {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: left, right;
    transition-property: left, right;
  }
  .layout-transitioning:not(.layout-offcanvas):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-sidenav {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: margin-left, margin-right, width;
    transition-property: margin-left, margin-right, width;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sidenav,
  .layout-sidenav,
  .layout-container,
  .layout-navbar,
  .layout-footer {
    -webkit-transition: none !important;
    transition: none !important;
    -webkit-transition-duration: 0s !important;
            transition-duration: 0s !important;
  }

  .layout-overlay {
    -webkit-animation: none !important;
            animation: none !important;
  }
}
@-webkit-keyframes layoutSidenavAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes layoutSidenavAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
.app-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  line-height: 1;
  min-height: 1px;
}

.app-brand-logo {
  display: block;
  overflow: hidden;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  min-height: 1px;
}
.app-brand-logo img,
.app-brand-logo svg {
  display: block;
}

.app-brand-text {
  display: block;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.app-brand .layout-sidenav-toggle {
  display: block;
}

.sidenav-vertical .app-brand {
  padding-right: 1.45rem;
  padding-left: 1.45rem;
}

.sidenav-horizontal .app-brand,
.sidenav-horizontal .app-brand + .sidenav-divider {
  display: none !important;
}

:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand {
  padding-right: 0;
  padding-left: 0;
  width: 4.375rem;
}
:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand-logo,
:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand-text {
  margin-right: auto;
  margin-left: auto;
}
:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand-logo ~ .app-brand-text,
:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand .layout-sidenav-toggle {
  display: none;
}

@media (min-width: 992px) {
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand {
    padding-right: 0;
    padding-left: 0;
    width: 4.375rem;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand-logo,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand-text {
    margin-right: auto;
    margin-left: auto;
  }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand-logo ~ .app-brand-text,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand .layout-sidenav-toggle {
    display: none;
  }
}
body {
  background: rgba(237,242,249,.96) !important;
}

a {
  color: #1b7597;
}
a:hover {
  color: #453dab;
}
.badge-soft-warning {
  color: #c46632;
  background-color: #fde6d8;
}
.text-primary {
  color: #716aca !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #453dab !important;
}

.bg-primary {
  background-color: #716aca !important;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #6b65c0 !important;
}

.bg-primary-dark {
  background-color: #665fb6 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus {
  background-color: #615aad !important;
}

.bg-primary-darker {
  background-color: #605aac !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus {
  background-color: #5b56a3 !important;
}

.badge-primary {
  background-color: #716aca;
  color: #fff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  background-color: #6b65c0;
  color: #fff;
  text-decoration: none;
}

.btn .badge-primary {
  background-color: #716aca !important;
  color: #fff !important;
}

.badge-outline-primary {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px #716aca inset;
          box-shadow: 0 0 0 1px #716aca inset;
  color: #716aca;
}

.badge-outline-primary[href]:hover, .badge-outline-primary[href]:focus {
  color: #716aca;
  text-decoration: none;
}

.btn .badge-outline-primary {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #716aca inset !important;
          box-shadow: 0 0 0 1px #716aca inset !important;
  color: #716aca !important;
}

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus,
.pagination li.active > a:not(.page-link),
.pagination li.active > a:not(.page-link):hover,
.pagination li.active > a:not(.page-link):focus {
  border-color: #716aca !important;
  background-color: #716aca !important;
  color: #fff !important;
}

.progress-bar {
  background-color: #716aca;
  color: #fff;
}

.list-group-item-primary {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #f1f0fa;
  color: #606064;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #606064;
}
a.list-group-item-primary:hover, a.list-group-item-primary:focus,
button.list-group-item-primary:hover,
button.list-group-item-primary:focus {
  background-color: #efeef8;
  color: #606064;
}
a.list-group-item-primary.active,
button.list-group-item-primary.active {
  border-color: #716aca;
  background-color: #716aca;
  color: #606064;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  border-color: #716aca;
  background-color: #716aca;
  color: #fff;
}

.alert-primary {
  border-color: #dcdbe8;
  background-color: #eae9f7;
  color: #5e5d63;
}
.alert-primary hr {
  border-top-color: #dcdbe8;
}
.alert-primary .close,
.alert-primary .alert-link {
  color: #5e5d63;
}

.alert-dark-primary {
  background-color: #716aca;
  color: #fff;
}
.alert-dark-primary hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.alert-dark-primary .close,
.alert-dark-primary .alert-link {
  color: #fff;
}

.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner, .ngb-tooltip-primary + ngb-tooltip-window .tooltip-inner {
  background: #716aca;
  color: #fff;
}
.tooltip-primary.bs-tooltip-top .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-top .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #716aca;
}
.tooltip-primary.bs-tooltip-right .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-right .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #716aca;
}
.tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #716aca;
}
.tooltip-primary.bs-tooltip-left .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-left .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #716aca;
}

.popover-primary, .popover-primary > .popover, .ngb-popover-primary + ngb-popover-window {
  border-color: transparent;
  background: #716aca;
}
.popover-primary .popover-header, .popover-primary > .popover .popover-header, .ngb-popover-primary + ngb-popover-window .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #fff;
}
.popover-primary .popover-body, .popover-primary > .popover .popover-body, .ngb-popover-primary + ngb-popover-window .popover-body {
  background: transparent;
  color: #fff;
}
.popover-primary .arrow::before, .popover-primary > .popover .arrow::before, .ngb-popover-primary + ngb-popover-window .arrow::before {
  border-color: transparent !important;
}
.popover-primary.bs-popover-top .arrow::after, .popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-primary > .popover.bs-popover-top .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #716aca;
}
.popover-primary.bs-popover-right .arrow::after, .popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-primary > .popover.bs-popover-right .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #716aca;
}
.popover-primary.bs-popover-bottom .arrow::after, .popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-primary > .popover.bs-popover-bottom .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #716aca;
}
.popover-primary.bs-popover-left .arrow::after, .popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-primary > .popover.bs-popover-left .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #716aca;
}

.table .table-primary,
.table .table-primary > th,
.table .table-primary > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #f1f0fa;
  color: #606064;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #efeef8;
}

.btn-primary {
  background: #716aca;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-primary:hover {
  background: #7871cd;
  color: #fff;
}
.btn-primary:hover, .btn-primary:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-primary .badge {
  background: #fff;
  color: #716aca;
}

.btn-primary .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background: #837dd1;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-primary.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #716aca !important;
}
.btn-primary.md-btn-flat:focus, .btn-primary.md-btn-flat.focus {
  background: rgba(113, 106, 202, 0.1) !important;
}
.btn-primary.md-btn-flat .waves-ripple {
  background: rgba(113, 106, 202, 0.3) !important;
}

.btn-primary.md-btn-flat:active,
.btn-primary.md-btn-flat.active,
.show > .btn-primary.md-btn-flat.dropdown-toggle {
  background: rgba(113, 106, 202, 0.15) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: #716aca !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-primary.md-btn-flat.disabled,
.btn-primary.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #716aca !important;
}

.btn-outline-primary {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #716aca inset;
          box-shadow: 0 0 0 1px #716aca inset;
  color: #716aca;
}
.btn-outline-primary:hover, .btn-outline-primary:focus {
  background: #716aca;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-primary:hover .badge, .btn-outline-primary:focus .badge {
  background: #fff;
  color: #716aca;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #716aca inset !important;
          box-shadow: 0 0 0 1px #716aca inset !important;
  color: #716aca !important;
}

.btn-outline-primary .badge {
  background: #716aca;
  color: #fff;
}

.btn-outline-primary .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  background: #7871cd;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-primary:active .badge,
.btn-outline-primary.active .badge,
.show > .btn-outline-primary.dropdown-toggle .badge {
  background: #fff;
  color: #716aca;
}

.dropdown-item:not(.disabled).active {
  background-color: #716aca;
  color: #fff;
}

.dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
  background-color: #716aca;
  color: #fff;
}

.nav-tabs .nav-link {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#716aca), to(#716aca));
  background-image: linear-gradient(#716aca, #716aca);
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
  background-color: #716aca;
  color: #fff;
}

.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #716aca;
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #716aca;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.custom-control.custom-radio .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23716aca'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23716aca'/%3E%3C/svg%3E");
}
.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(113, 106, 202, 0.15);
}

.custom-file-input:focus ~ .custom-file-label {
  border-bottom-color: #716aca;
  -webkit-box-shadow: 0 -1px 0 0 #716aca inset;
          box-shadow: 0 -1px 0 0 #716aca inset;
}

.form-control:focus,
.custom-select:focus {
  border-bottom-color: #716aca;
  -webkit-box-shadow: 0 -1px 0 0 #716aca inset;
          box-shadow: 0 -1px 0 0 #716aca inset;
}

.switcher-input:checked ~ .switcher-indicator {
  background: rgba(113, 106, 202, 0.5);
}
.switcher-input:checked ~ .switcher-indicator::after {
  background: #716aca;
}
.switcher-input:checked ~ .switcher-indicator::before {
  background: rgba(113, 106, 202, 0.15);
}

.navbar.bg-primary {
  background-color: #716aca !important;
  color: #e1dff4;
}
.navbar.bg-primary .navbar-brand {
  color: #fff;
}
.navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus {
  color: #fff;
}
.navbar.bg-primary .navbar-nav .nav-link {
  color: #e1dff4;
}
.navbar.bg-primary .navbar-nav .nav-link:hover, .navbar.bg-primary .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar.bg-primary .navbar-nav .nav-link.disabled {
  color: #b4b0e3 !important;
}
.navbar.bg-primary .navbar-nav .show > .nav-link,
.navbar.bg-primary .navbar-nav .active > .nav-link,
.navbar.bg-primary .navbar-nav .nav-link.show,
.navbar.bg-primary .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-primary .navbar-toggler {
  color: #e1dff4;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-primary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar.bg-primary .navbar-text {
  color: #e1dff4;
}
.navbar.bg-primary .navbar-text a {
  color: #fff;
}
.navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-primary {
  background-color: #716aca !important;
  color: #e1dff4;
}
.sidenav.bg-primary .sidenav-link,
.sidenav.bg-primary .sidenav-horizontal-prev,
.sidenav.bg-primary .sidenav-horizontal-next {
  color: #e1dff4;
}
.sidenav.bg-primary .sidenav-link:hover, .sidenav.bg-primary .sidenav-link:focus,
.sidenav.bg-primary .sidenav-horizontal-prev:hover,
.sidenav.bg-primary .sidenav-horizontal-prev:focus,
.sidenav.bg-primary .sidenav-horizontal-next:hover,
.sidenav.bg-primary .sidenav-horizontal-next:focus {
  color: #fff;
}
.sidenav.bg-primary .sidenav-link.active,
.sidenav.bg-primary .sidenav-horizontal-prev.active,
.sidenav.bg-primary .sidenav-horizontal-next.active {
  color: #fff;
}
.sidenav.bg-primary .sidenav-item.disabled .sidenav-link,
.sidenav.bg-primary .sidenav-horizontal-prev.disabled,
.sidenav.bg-primary .sidenav-horizontal-next.disabled {
  color: #b4b0e3 !important;
}
.sidenav.bg-primary .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #6b65c0;
}
.sidenav.bg-primary.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #6660b6;
}
.sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu, .sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #6b65c0;
}
.sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #e1dff4;
}
.sidenav.bg-primary .sidenav-text {
  color: #fff;
}
.sidenav.bg-primary .sidenav-header {
  color: #c5c2ea;
}
.sidenav.bg-primary hr,
.sidenav.bg-primary .sidenav-divider,
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}
.sidenav.bg-primary .sidenav-inner > .sidenav-header::before,
.sidenav.bg-primary .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #817cc9;
}
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  -webkit-box-shadow: 0 0 0 2px #6b65c0;
          box-shadow: 0 0 0 2px #6b65c0;
}
.sidenav.bg-primary .ps__thumb-y,
.sidenav.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.5867247059) !important;
}

.footer.bg-primary {
  background-color: #716aca !important;
  color: #e1dff4;
}
.footer.bg-primary .footer-link {
  color: #e1dff4;
}
.footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
  color: #fff;
}
.footer.bg-primary .footer-link.disabled {
  color: #b4b0e3 !important;
}
.footer.bg-primary .footer-text {
  color: #fff;
}
.footer.bg-primary .show > .footer-link,
.footer.bg-primary .active > .footer-link,
.footer.bg-primary .footer-link.show,
.footer.bg-primary .footer-link.active {
  color: #fff;
}
.footer.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.fill-primary {
  fill: #716aca;
}

.slider-primary .slider-handle {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.slider-primary .slider-handle,
.slider-primary .slider-selection,
.slider-primary .slider-reversed .slider-track-high, .slider-primary.slider-reversed .slider-track-high {
  background: #716aca;
}

.noUi-primary .noUi-base .noUi-handle {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: #716aca !important;
}
.noUi-primary .noUi-base .noUi-connect {
  background: #716aca !important;
}

.material-style .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #716aca !important;
  color: #fff !important;
}
.material-style .select2-container--default.select2-container--focus:not(.select2-container--disabled) .select2-selection, .material-style .select2-container--default.select2-container--open:not(.select2-container--disabled) .select2-selection {
  border-color: #716aca !important;
  -webkit-box-shadow: 0 -1px 0 0 #716aca inset !important;
          box-shadow: 0 -1px 0 0 #716aca inset !important;
}

.material-style .select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #716aca;
  color: #fff;
}

.bootstrap-tagsinput.focus {
  border-color: #716aca !important;
  -webkit-box-shadow: 0 -1px 0 0 #716aca inset !important;
          box-shadow: 0 -1px 0 0 #716aca inset !important;
}

.bootstrap-select .dropdown-menu.inner a[aria-selected=true] {
  background: #716aca !important;
  color: #fff !important;
}

.datepicker table tr td.active, .datepicker table tr td.active.highlighted, .datepicker table tr td.active.today,
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled, .datepicker table tr td.range-start, .datepicker table tr td.range-end {
  background: #716aca !important;
  color: #fff !important;
}
.datepicker table tr td.range, .datepicker table tr td.range.highlighted, .datepicker table tr td.range.today {
  background: #eae9f7 !important;
  color: #5e5d63 !important;
}
.datepicker table tr td.range.focused, .datepicker table tr td.range.highlighted.focused, .datepicker table tr td.range.today.focused {
  background: #d5d3ef !important;
}
.datepicker table tr td.range.disabled, .datepicker table tr td.range.highlighted.disabled, .datepicker table tr td.range.today.disabled {
  background: rgba(234, 233, 247, 0.5) !important;
  color: rgba(94, 93, 99, 0.5) !important;
}

.daterangepicker td.active {
  background-color: #716aca !important;
  color: #fff !important;
}

.daterangepicker td.in-range:not(.start-date):not(.end-date):not(.off) {
  background-color: #eae9f7 !important;
  color: #5e5d63 !important;
}

.daterangepicker .input-mini.active {
  border-color: #716aca !important;
}

.ranges li.active {
  background-color: #716aca !important;
  color: #fff !important;
}

.dtp-date,
.dtp-time {
  background: #716aca;
  color: #fff;
}

.dtp-close > a {
  color: #fff;
}

header.dtp-header {
  background: #605aac;
  color: #fff;
}

table.dtp-picker-days tr > td > a.selected {
  background: #716aca;
  color: #fff !important;
}

.dtp-actual-meridien a.selected {
  background: #716aca !important;
  color: #fff !important;
}

.dtp .year-picker-item:hover {
  color: #716aca;
}
.dtp .year-picker-item.active {
  color: #716aca;
}

.dtp .svg-clock [stroke="#8BC34A"] {
  stroke: #716aca !important;
}

.dtp .svg-clock [fill="#8BC34A"] {
  fill: #716aca !important;
}

.dtp .svg-clock [fill="#fff"] {
  fill: #fff !important;
}

li.ui-timepicker-selected,
.ui-timepicker-list .ui-timepicker-selected:hover {
  background: #716aca !important;
  color: #fff !important;
}

.growl-default {
  background: #716aca !important;
  color: #fff !important;
}

.md-editor.active {
  border-color: #716aca !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar button:hover,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #716aca !important;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #716aca !important;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #716aca !important;
}

.tt-suggestion:active,
.tt-cursor {
  background: #716aca !important;
  color: #fff !important;
}

.dropzone.dz-drag-hover {
  border-color: #716aca !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #716aca !important;
}

.sk-primary.sk-rotating-plane,
.sk-primary.sk-double-bounce .sk-child,
.sk-primary.sk-wave .sk-rect,
.sk-primary.sk-wandering-cubes .sk-cube,
.sk-primary.sk-spinner-pulse,
.sk-primary.sk-chasing-dots .sk-child,
.sk-primary.sk-three-bounce .sk-child,
.sk-primary.sk-circle .sk-child:before,
.sk-primary.sk-cube-grid .sk-cube,
.sk-primary.sk-fading-circle .sk-circle:before,
.sk-primary.sk-folding-cube .sk-cube:before {
  background-color: #716aca;
}

.sw-theme-default .step-anchor > li.active > a .sw-icon,
.sw-theme-default .step-anchor > li.active > a .sw-number {
  color: #716aca;
  border-color: #716aca;
}

.plyr input[type='range']::-ms-fill-lower {
  background: #716aca !important;
}

.plyr input[type='range']:active::-webkit-slider-thumb {
  background: #716aca !important;
}
.plyr input[type='range']:active::-moz-range-thumb {
  background: #716aca !important;
}
.plyr input[type='range']:active::-ms-thumb {
  background: #716aca !important;
}

.plyr--video .plyr__controls button.tab-focus:focus,
.plyr--video .plyr__controls button:hover {
  background: #716aca !important;
  color: !important;
}

.plyr--audio .plyr__controls button.tab-focus:focus,
.plyr--audio .plyr__controls button:hover {
  background: #716aca !important;
  color: !important;
}

.plyr__play-large {
  background: #716aca !important;
  color: !important;
}

.plyr__progress--played,
.plyr__volume--display {
  color: #716aca !important;
}

.fc-event,
.fc-event-dot {
  background: rgba(113, 106, 202, 0.15) !important;
}

.fc-highlight,
.fc-bgevent {
  background: #716aca !important;
}

.ui-product-color.active {
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #716aca !important;
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #716aca !important;
}

.bg-navbar-theme {
  background-color: #716aca !important;
  color: #e1dff4;
}
.bg-navbar-theme .navbar-brand {
  color: #fff;
}
.bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus {
  color: #fff;
}
.bg-navbar-theme .navbar-nav .nav-link {
  color: #e1dff4;
}
.bg-navbar-theme .navbar-nav .nav-link:hover, .bg-navbar-theme .navbar-nav .nav-link:focus {
  color: #fff;
}
.bg-navbar-theme .navbar-nav .nav-link.disabled {
  color: #b4b0e3 !important;
}
.bg-navbar-theme .navbar-nav .show > .nav-link,
.bg-navbar-theme .navbar-nav .active > .nav-link,
.bg-navbar-theme .navbar-nav .nav-link.show,
.bg-navbar-theme .navbar-nav .nav-link.active {
  color: #fff;
}
.bg-navbar-theme .navbar-toggler {
  color: #e1dff4;
  border-color: rgba(255, 255, 255, 0.15);
}
.bg-navbar-theme .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.bg-navbar-theme .navbar-text {
  color: #e1dff4;
}
.bg-navbar-theme .navbar-text a {
  color: #fff;
}
.bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
  color: #fff;
}
.bg-navbar-theme hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar {
  -webkit-box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
          box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
}

.material-style .sidenav .app-brand.demo {
  height: 70px;
}

.bg-sidenav-theme {
  background-color: #fff !important;
  color: #2c2e3e;
}
.bg-sidenav-theme .sidenav-link,
.bg-sidenav-theme .sidenav-horizontal-prev,
.bg-sidenav-theme .sidenav-horizontal-next {
  color: #2c2e3e;
}
.bg-sidenav-theme .sidenav-link:hover, .bg-sidenav-theme .sidenav-link:focus,
.bg-sidenav-theme .sidenav-horizontal-prev:hover,
.bg-sidenav-theme .sidenav-horizontal-prev:focus,
.bg-sidenav-theme .sidenav-horizontal-next:hover,
.bg-sidenav-theme .sidenav-horizontal-next:focus {
  color: #4E5155;
}
.bg-sidenav-theme .sidenav-link.active,
.bg-sidenav-theme .sidenav-horizontal-prev.active,
.bg-sidenav-theme .sidenav-horizontal-next.active {
  color: #4E5155;
}
.bg-sidenav-theme .sidenav-item.disabled .sidenav-link,
.bg-sidenav-theme .sidenav-horizontal-prev.disabled,
.bg-sidenav-theme .sidenav-horizontal-next.disabled {
  color: #80828b !important;
}
.bg-sidenav-theme .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.bg-sidenav-theme .sidenav-item.active > .sidenav-link {
  color: #4E5155;
}
.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #eaebf4;
}
.bg-sidenav-theme.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #e4e5ee;
}
.bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu, .bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #eaebf4;
}
.bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #2c2e3e;
}
.bg-sidenav-theme .sidenav-text {
  color: #4E5155;
}
.bg-sidenav-theme .sidenav-header {
  color: #61626e;
}
.bg-sidenav-theme hr,
.bg-sidenav-theme .sidenav-divider,
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(78, 81, 85, 0.075) !important;
}
.bg-sidenav-theme .sidenav-inner > .sidenav-header::before,
.bg-sidenav-theme .sidenav-block::before {
  background-color: rgba(78, 81, 85, 0.075);
}
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #dedfe8;
}
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #4E5155;
}
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  -webkit-box-shadow: 0 0 0 2px #eaebf4;
          box-shadow: 0 0 0 2px #eaebf4;
}
.bg-sidenav-theme .ps__thumb-y,
.bg-sidenav-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(78, 81, 85, 0.2) !important;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon,
.bg-sidenav-theme .sidenav-inner > .sidenav-item > .sidenav-link:focus .sidenav-icon,
.bg-sidenav-theme .sidenav-inner > .sidenav-item > .sidenav-link:hover .sidenav-icon {
  color: rgba(24, 28, 33, 0.2);
}
.bg-sidenav-theme .sidenav-inner > .sidenav-item.active > .sidenav-link .sidenav-icon {
  color: #716aca !important;
}
.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background: #f2f3f8;
}

.layout-sidenav,
.layout-sidenav-horizontal {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.bg-footer-theme {
  background-color: #f2f3f8 !important;
  color: #a3a4a6;
}
.bg-footer-theme .footer-link {
  color: #a3a4a6;
}
.bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
  color: #4E5155;
}
.bg-footer-theme .footer-link.disabled {
  color: #c3c4c7 !important;
}
.bg-footer-theme .footer-text {
  color: #4E5155;
}
.bg-footer-theme .show > .footer-link,
.bg-footer-theme .active > .footer-link,
.bg-footer-theme .footer-link.show,
.bg-footer-theme .footer-link.active {
  color: #4E5155;
}
.bg-footer-theme hr {
  border-color: rgba(78, 81, 85, 0.0772998039);
}

.layout-footer {
  -webkit-box-shadow: 0 -1px 0 rgba(24, 28, 33, 0.04);
          box-shadow: 0 -1px 0 rgba(24, 28, 33, 0.04);
}

hr {
  border-color: rgba(24, 28, 33, 0.04);
}

.nav-tabs-bottom > .tab-content,
.nav-tabs-left > .tab-content,
.nav-tabs-right > .tab-content,
.nav-tabs-top > .tab-content {
  border-color: transparent;
  -webkit-box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.04);
          box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.04);
}

.form-wizard-boxed-steps .wizard-nav,
.ng2-archwizard-boxed-steps ul.steps-indicator {
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.04);
          box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.04);
}

.nav-tabs-bottom > div > .nav-tabs:not(.tabs-alt) .nav-link:not(.active),
.nav-tabs-bottom > .nav-tabs:not(.tabs-alt) .nav-link:not(.active),
.nav-tabs-left > div > .nav-tabs:not(.tabs-alt) .nav-link:not(.active),
.nav-tabs-left > .nav-tabs:not(.tabs-alt) .nav-link:not(.active),
.nav-tabs-right > div > .nav-tabs:not(.tabs-alt) .nav-link:not(.active),
.nav-tabs-right > .nav-tabs:not(.tabs-alt) .nav-link:not(.active),
.nav-tabs-top > div > .nav-tabs:not(.tabs-alt) .nav-link:not(.active),
.nav-tabs-top > .nav-tabs:not(.tabs-alt) .nav-link:not(.active) {
  border-color: transparent !important;
}
.nav-tabs-bottom > div > .nav .nav-link.active,
.nav-tabs-bottom > .nav .nav-link.active,
.nav-tabs-left > div > .nav .nav-link.active,
.nav-tabs-left > .nav .nav-link.active,
.nav-tabs-right > div > .nav .nav-link.active,
.nav-tabs-right > .nav .nav-link.active,
.nav-tabs-top > div > .nav .nav-link.active,
.nav-tabs-top > .nav .nav-link.active {
  border-color: #fff !important;
}

.nav-tabs-bottom > .nav-tabs:not(.tabs-alt),
.nav-tabs-bottom > div > .nav-tabs:not(.tabs-alt),
.nav-tabs-top > .nav-tabs:not(.tabs-alt),
.nav-tabs-top > div > .nav-tabs:not(.tabs-alt) {
  padding-right: 0px;
  padding-left: 0px;
}

.nav-tabs-left > .nav-tabs:not(.tabs-alt),
.nav-tabs-left > div > .nav-tabs:not(.tabs-alt),
.nav-tabs-right > .nav-tabs:not(.tabs-alt),
.nav-tabs-right > div > .nav-tabs:not(.tabs-alt) {
  padding-top: 0px;
  padding-bottom: 0px;
}

.progress {
  background: #f2f3f8;
}

.sortable-example li {
  background: #f2f3f8;
}

.btn-glow-primary {
  -webkit-box-shadow: 0 1px 20px 1px rgba(113, 106, 202, 0.6);
          box-shadow: 0 1px 20px 1px rgba(113, 106, 202, 0.6);
}
.btn-glow-primary:hover {
  -webkit-box-shadow: 0 1px 20px 3px rgba(113, 106, 202, 0.6);
          box-shadow: 0 1px 20px 3px rgba(113, 106, 202, 0.6);
}
.btn-glow-primary:not(:disabled):not(.disabled).active, .btn-glow-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-primary.dropdown-toggle, .btn-glow-primary.active, .btn-glow-primary:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-secondary {
  -webkit-box-shadow: 0 1px 20px 1px rgba(136, 151, 170, 0.6);
          box-shadow: 0 1px 20px 1px rgba(136, 151, 170, 0.6);
}
.btn-glow-secondary:hover {
  -webkit-box-shadow: 0 1px 20px 3px rgba(136, 151, 170, 0.6);
          box-shadow: 0 1px 20px 3px rgba(136, 151, 170, 0.6);
}
.btn-glow-secondary:not(:disabled):not(.disabled).active, .btn-glow-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-secondary.dropdown-toggle, .btn-glow-secondary.active, .btn-glow-secondary:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-success {
  -webkit-box-shadow: 0 1px 20px 1px rgba(40, 208, 148, 0.6);
          box-shadow: 0 1px 20px 1px rgba(40, 208, 148, 0.6);
}
.btn-glow-success:hover {
  -webkit-box-shadow: 0 1px 20px 3px rgba(40, 208, 148, 0.6);
          box-shadow: 0 1px 20px 3px rgba(40, 208, 148, 0.6);
}
.btn-glow-success:not(:disabled):not(.disabled).active, .btn-glow-success:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-success.dropdown-toggle, .btn-glow-success.active, .btn-glow-success:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-info {
  -webkit-box-shadow: 0 1px 20px 1px rgba(30, 159, 242, 0.6);
          box-shadow: 0 1px 20px 1px rgba(30, 159, 242, 0.6);
}
.btn-glow-info:hover {
  -webkit-box-shadow: 0 1px 20px 3px rgba(30, 159, 242, 0.6);
          box-shadow: 0 1px 20px 3px rgba(30, 159, 242, 0.6);
}
.btn-glow-info:not(:disabled):not(.disabled).active, .btn-glow-info:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-info.dropdown-toggle, .btn-glow-info.active, .btn-glow-info:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-warning {
  -webkit-box-shadow: 0 1px 20px 1px rgba(255, 145, 73, 0.6);
          box-shadow: 0 1px 20px 1px rgba(255, 145, 73, 0.6);
}
.btn-glow-warning:hover {
  -webkit-box-shadow: 0 1px 20px 3px rgba(255, 145, 73, 0.6);
          box-shadow: 0 1px 20px 3px rgba(255, 145, 73, 0.6);
}
.btn-glow-warning:not(:disabled):not(.disabled).active, .btn-glow-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-warning.dropdown-toggle, .btn-glow-warning.active, .btn-glow-warning:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-danger {
  -webkit-box-shadow: 0 1px 20px 1px rgba(255, 73, 97, 0.6);
          box-shadow: 0 1px 20px 1px rgba(255, 73, 97, 0.6);
}
.btn-glow-danger:hover {
  -webkit-box-shadow: 0 1px 20px 3px rgba(255, 73, 97, 0.6);
          box-shadow: 0 1px 20px 3px rgba(255, 73, 97, 0.6);
}
.btn-glow-danger:not(:disabled):not(.disabled).active, .btn-glow-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-danger.dropdown-toggle, .btn-glow-danger.active, .btn-glow-danger:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-light {
  -webkit-box-shadow: 0 1px 20px 1px rgba(24, 28, 33, 0);
          box-shadow: 0 1px 20px 1px rgba(24, 28, 33, 0);
}
.btn-glow-light:hover {
  -webkit-box-shadow: 0 1px 20px 3px rgba(24, 28, 33, 0);
          box-shadow: 0 1px 20px 3px rgba(24, 28, 33, 0);
}
.btn-glow-light:not(:disabled):not(.disabled).active, .btn-glow-light:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-light.dropdown-toggle, .btn-glow-light.active, .btn-glow-light:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-dark {
  -webkit-box-shadow: 0 1px 20px 1px rgba(44, 46, 62, 0.6);
          box-shadow: 0 1px 20px 1px rgba(44, 46, 62, 0.6);
}
.btn-glow-dark:hover {
  -webkit-box-shadow: 0 1px 20px 3px rgba(44, 46, 62, 0.6);
          box-shadow: 0 1px 20px 3px rgba(44, 46, 62, 0.6);
}
.btn-glow-dark:not(:disabled):not(.disabled).active, .btn-glow-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-dark.dropdown-toggle, .btn-glow-dark.active, .btn-glow-dark:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.chart-shadow-primary {
  -webkit-filter: drop-shadow(0 8px 8px #716aca);
          filter: drop-shadow(0 8px 8px #716aca);
}

.chart-shadow-secondary {
  -webkit-filter: drop-shadow(0 8px 8px #8897AA);
          filter: drop-shadow(0 8px 8px #8897AA);
}

.chart-shadow-success {
  -webkit-filter: drop-shadow(0 8px 8px #28D094);
          filter: drop-shadow(0 8px 8px #28D094);
}

.chart-shadow-info {
  -webkit-filter: drop-shadow(0 8px 8px #1E9FF2);
          filter: drop-shadow(0 8px 8px #1E9FF2);
}

.chart-shadow-warning {
  -webkit-filter: drop-shadow(0 8px 8px #FF9149);
          filter: drop-shadow(0 8px 8px #FF9149);
}

.chart-shadow-danger {
  -webkit-filter: drop-shadow(0 8px 8px #FF4961);
          filter: drop-shadow(0 8px 8px #FF4961);
}

.chart-shadow-light {
  -webkit-filter: drop-shadow(0 8px 8px rgba(24, 28, 33, 0.06));
          filter: drop-shadow(0 8px 8px rgba(24, 28, 33, 0.06));
}

.chart-shadow-dark {
  -webkit-filter: drop-shadow(0 8px 8px #2c2e3e);
          filter: drop-shadow(0 8px 8px #2c2e3e);
}

.text-twitter {
  color: #1DA1F2 !important;
}

a.text-twitter:hover, a.text-twitter:focus {
  color: #0b76b8 !important;
}

.bg-twitter {
  background-color: #1DA1F2 !important;
}

a.bg-twitter:hover, a.bg-twitter:focus {
  background-color: #1c99e6 !important;
}

.bg-twitter-dark {
  background-color: #1a91da !important;
}

a.bg-twitter-dark:hover, a.bg-twitter-dark:focus {
  background-color: #198acf !important;
}

.bg-twitter-darker {
  background-color: #1989ce !important;
}

a.bg-twitter-darker:hover, a.bg-twitter-darker:focus {
  background-color: #1882c4 !important;
}

.btn-twitter {
  background: #1DA1F2;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-twitter:hover {
  background: #28a6f3;
  color: #fff;
}
.btn-twitter:hover, .btn-twitter:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-twitter .badge {
  background: #fff;
  color: #1DA1F2;
}

.btn-twitter .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-twitter:active,
.btn-twitter.active,
.show > .btn-twitter.dropdown-toggle {
  background: #3aadf4;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-twitter.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #1DA1F2 !important;
}
.btn-twitter.md-btn-flat:focus, .btn-twitter.md-btn-flat.focus {
  background: rgba(29, 161, 242, 0.1) !important;
}
.btn-twitter.md-btn-flat .waves-ripple {
  background: rgba(29, 161, 242, 0.3) !important;
}

.btn-twitter.md-btn-flat:active,
.btn-twitter.md-btn-flat.active,
.show > .btn-twitter.md-btn-flat.dropdown-toggle {
  background: rgba(29, 161, 242, 0.15) !important;
}

.btn-twitter.disabled,
.btn-twitter:disabled {
  background: #1DA1F2 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-twitter.md-btn-flat.disabled,
.btn-twitter.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #1DA1F2 !important;
}

.btn-outline-twitter {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #1DA1F2 inset;
          box-shadow: 0 0 0 1px #1DA1F2 inset;
  color: #1DA1F2;
}
.btn-outline-twitter:hover, .btn-outline-twitter:focus {
  background: #1DA1F2;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-twitter:hover .badge, .btn-outline-twitter:focus .badge {
  background: #fff;
  color: #1DA1F2;
}
.btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #1DA1F2 inset !important;
          box-shadow: 0 0 0 1px #1DA1F2 inset !important;
  color: #1DA1F2 !important;
}

.btn-outline-twitter .badge {
  background: #1DA1F2;
  color: #fff;
}

.btn-outline-twitter .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-twitter:active,
.btn-outline-twitter.active,
.show > .btn-outline-twitter.dropdown-toggle {
  background: #28a6f3;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-twitter:active .badge,
.btn-outline-twitter.active .badge,
.show > .btn-outline-twitter.dropdown-toggle .badge {
  background: #fff;
  color: #1DA1F2;
}

.text-google {
  color: #D1523F !important;
}

a.text-google:hover, a.text-google:focus {
  color: #9e3526 !important;
}

.bg-google {
  background-color: #D1523F !important;
}

a.bg-google:hover, a.bg-google:focus {
  background-color: #c74e3c !important;
}

.bg-google-dark {
  background-color: #bc4a39 !important;
}

a.bg-google-dark:hover, a.bg-google-dark:focus {
  background-color: #b34636 !important;
}

.bg-google-darker {
  background-color: #b24636 !important;
}

a.bg-google-darker:hover, a.bg-google-darker:focus {
  background-color: #a94333 !important;
}

.btn-google {
  background: #D1523F;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-google:hover {
  background: #d35b49;
  color: #fff;
}
.btn-google:hover, .btn-google:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-google .badge {
  background: #fff;
  color: #D1523F;
}

.btn-google .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-google:active,
.btn-google.active,
.show > .btn-google.dropdown-toggle {
  background: #d76858;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-google.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #D1523F !important;
}
.btn-google.md-btn-flat:focus, .btn-google.md-btn-flat.focus {
  background: rgba(209, 82, 63, 0.1) !important;
}
.btn-google.md-btn-flat .waves-ripple {
  background: rgba(209, 82, 63, 0.3) !important;
}

.btn-google.md-btn-flat:active,
.btn-google.md-btn-flat.active,
.show > .btn-google.md-btn-flat.dropdown-toggle {
  background: rgba(209, 82, 63, 0.15) !important;
}

.btn-google.disabled,
.btn-google:disabled {
  background: #D1523F !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-google.md-btn-flat.disabled,
.btn-google.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #D1523F !important;
}

.btn-outline-google {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #D1523F inset;
          box-shadow: 0 0 0 1px #D1523F inset;
  color: #D1523F;
}
.btn-outline-google:hover, .btn-outline-google:focus {
  background: #D1523F;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-google:hover .badge, .btn-outline-google:focus .badge {
  background: #fff;
  color: #D1523F;
}
.btn-outline-google.disabled, .btn-outline-google:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #D1523F inset !important;
          box-shadow: 0 0 0 1px #D1523F inset !important;
  color: #D1523F !important;
}

.btn-outline-google .badge {
  background: #D1523F;
  color: #fff;
}

.btn-outline-google .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-google:active,
.btn-outline-google.active,
.show > .btn-outline-google.dropdown-toggle {
  background: #d35b49;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-google:active .badge,
.btn-outline-google.active .badge,
.show > .btn-outline-google.dropdown-toggle .badge {
  background: #fff;
  color: #D1523F;
}

.text-facebook {
  color: #3B5998 !important;
}

a.text-facebook:hover, a.text-facebook:focus {
  color: #263961 !important;
}

.bg-facebook {
  background-color: #3B5998 !important;
}

a.bg-facebook:hover, a.bg-facebook:focus {
  background-color: #385590 !important;
}

.bg-facebook-dark {
  background-color: #355089 !important;
}

a.bg-facebook-dark:hover, a.bg-facebook-dark:focus {
  background-color: #324c82 !important;
}

.bg-facebook-darker {
  background-color: #324c81 !important;
}

a.bg-facebook-darker:hover, a.bg-facebook-darker:focus {
  background-color: #30487b !important;
}

.btn-facebook {
  background: #3B5998;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-facebook:hover {
  background: #45619d;
  color: #fff;
}
.btn-facebook:hover, .btn-facebook:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-facebook .badge {
  background: #fff;
  color: #3B5998;
}

.btn-facebook .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-facebook:active,
.btn-facebook.active,
.show > .btn-facebook.dropdown-toggle {
  background: #546fa5;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-facebook.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #3B5998 !important;
}
.btn-facebook.md-btn-flat:focus, .btn-facebook.md-btn-flat.focus {
  background: rgba(59, 89, 152, 0.1) !important;
}
.btn-facebook.md-btn-flat .waves-ripple {
  background: rgba(59, 89, 152, 0.3) !important;
}

.btn-facebook.md-btn-flat:active,
.btn-facebook.md-btn-flat.active,
.show > .btn-facebook.md-btn-flat.dropdown-toggle {
  background: rgba(59, 89, 152, 0.15) !important;
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  background: #3B5998 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-facebook.md-btn-flat.disabled,
.btn-facebook.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #3B5998 !important;
}

.btn-outline-facebook {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #3B5998 inset;
          box-shadow: 0 0 0 1px #3B5998 inset;
  color: #3B5998;
}
.btn-outline-facebook:hover, .btn-outline-facebook:focus {
  background: #3B5998;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-facebook:hover .badge, .btn-outline-facebook:focus .badge {
  background: #fff;
  color: #3B5998;
}
.btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #3B5998 inset !important;
          box-shadow: 0 0 0 1px #3B5998 inset !important;
  color: #3B5998 !important;
}

.btn-outline-facebook .badge {
  background: #3B5998;
  color: #fff;
}

.btn-outline-facebook .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-facebook:active,
.btn-outline-facebook.active,
.show > .btn-outline-facebook.dropdown-toggle {
  background: #45619d;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-facebook:active .badge,
.btn-outline-facebook.active .badge,
.show > .btn-outline-facebook.dropdown-toggle .badge {
  background: #fff;
  color: #3B5998;
}

.text-pinterest {
  color: #bd121c !important;
}

a.text-pinterest:hover, a.text-pinterest:focus {
  color: #770b12 !important;
}

.bg-pinterest {
  background-color: #bd121c !important;
}

a.bg-pinterest:hover, a.bg-pinterest:focus {
  background-color: #b4111b !important;
}

.bg-pinterest-dark {
  background-color: #aa1019 !important;
}

a.bg-pinterest-dark:hover, a.bg-pinterest-dark:focus {
  background-color: #a20f18 !important;
}

.bg-pinterest-darker {
  background-color: #a10f18 !important;
}

a.bg-pinterest-darker:hover, a.bg-pinterest-darker:focus {
  background-color: #990e17 !important;
}

.btn-pinterest {
  background: #bd121c;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-pinterest:hover {
  background: #c01e27;
  color: #fff;
}
.btn-pinterest:hover, .btn-pinterest:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-pinterest .badge {
  background: #fff;
  color: #bd121c;
}

.btn-pinterest .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-pinterest:active,
.btn-pinterest.active,
.show > .btn-pinterest.dropdown-toggle {
  background: #c6313a;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-pinterest.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #bd121c !important;
}
.btn-pinterest.md-btn-flat:focus, .btn-pinterest.md-btn-flat.focus {
  background: rgba(189, 18, 28, 0.1) !important;
}
.btn-pinterest.md-btn-flat .waves-ripple {
  background: rgba(189, 18, 28, 0.3) !important;
}

.btn-pinterest.md-btn-flat:active,
.btn-pinterest.md-btn-flat.active,
.show > .btn-pinterest.md-btn-flat.dropdown-toggle {
  background: rgba(189, 18, 28, 0.15) !important;
}

.btn-pinterest.disabled,
.btn-pinterest:disabled {
  background: #bd121c !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-pinterest.md-btn-flat.disabled,
.btn-pinterest.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #bd121c !important;
}

.btn-outline-pinterest {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #bd121c inset;
          box-shadow: 0 0 0 1px #bd121c inset;
  color: #bd121c;
}
.btn-outline-pinterest:hover, .btn-outline-pinterest:focus {
  background: #bd121c;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-pinterest:hover .badge, .btn-outline-pinterest:focus .badge {
  background: #fff;
  color: #bd121c;
}
.btn-outline-pinterest.disabled, .btn-outline-pinterest:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #bd121c inset !important;
          box-shadow: 0 0 0 1px #bd121c inset !important;
  color: #bd121c !important;
}

.btn-outline-pinterest .badge {
  background: #bd121c;
  color: #fff;
}

.btn-outline-pinterest .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-pinterest:active,
.btn-outline-pinterest.active,
.show > .btn-outline-pinterest.dropdown-toggle {
  background: #c01e27;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-pinterest:active .badge,
.btn-outline-pinterest.active .badge,
.show > .btn-outline-pinterest.dropdown-toggle .badge {
  background: #fff;
  color: #bd121c;
}

.text-instagram {
  color: #000 !important;
}

a.text-instagram:hover, a.text-instagram:focus {
  color: black !important;
}

.bg-instagram {
  background-color: #000 !important;
}

a.bg-instagram:hover, a.bg-instagram:focus {
  background-color: black !important;
}

.bg-instagram-dark {
  background-color: black !important;
}

a.bg-instagram-dark:hover, a.bg-instagram-dark:focus {
  background-color: black !important;
}

.bg-instagram-darker {
  background-color: black !important;
}

a.bg-instagram-darker:hover, a.bg-instagram-darker:focus {
  background-color: black !important;
}

.btn-instagram {
  background: #000;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-instagram:hover {
  background: #0d0d0d;
  color: #fff;
}
.btn-instagram:hover, .btn-instagram:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-instagram .badge {
  background: #fff;
  color: #000;
}

.btn-instagram .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-instagram:active,
.btn-instagram.active,
.show > .btn-instagram.dropdown-toggle {
  background: #212121;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-instagram.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #000 !important;
}
.btn-instagram.md-btn-flat:focus, .btn-instagram.md-btn-flat.focus {
  background: rgba(0, 0, 0, 0.1) !important;
}
.btn-instagram.md-btn-flat .waves-ripple {
  background: rgba(0, 0, 0, 0.3) !important;
}

.btn-instagram.md-btn-flat:active,
.btn-instagram.md-btn-flat.active,
.show > .btn-instagram.md-btn-flat.dropdown-toggle {
  background: rgba(0, 0, 0, 0.15) !important;
}

.btn-instagram.disabled,
.btn-instagram:disabled {
  background: #000 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-instagram.md-btn-flat.disabled,
.btn-instagram.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #000 !important;
}

.btn-outline-instagram {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #000 inset;
          box-shadow: 0 0 0 1px #000 inset;
  color: #000;
}
.btn-outline-instagram:hover, .btn-outline-instagram:focus {
  background: #000;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-instagram:hover .badge, .btn-outline-instagram:focus .badge {
  background: #fff;
  color: #000;
}
.btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #000 inset !important;
          box-shadow: 0 0 0 1px #000 inset !important;
  color: #000 !important;
}

.btn-outline-instagram .badge {
  background: #000;
  color: #fff;
}

.btn-outline-instagram .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-instagram:active,
.btn-outline-instagram.active,
.show > .btn-outline-instagram.dropdown-toggle {
  background: #0d0d0d;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-instagram:active .badge,
.btn-outline-instagram.active .badge,
.show > .btn-outline-instagram.dropdown-toggle .badge {
  background: #fff;
  color: #000;
}

.text-windows {
  color: #26AAE1 !important;
}

a.text-windows:hover, a.text-windows:focus {
  color: #177aa4 !important;
}

.bg-windows {
  background-color: #26AAE1 !important;
}

a.bg-windows:hover, a.bg-windows:focus {
  background-color: #24a2d6 !important;
}

.bg-windows-dark {
  background-color: #2299cb !important;
}

a.bg-windows-dark:hover, a.bg-windows-dark:focus {
  background-color: #2091c1 !important;
}

.bg-windows-darker {
  background-color: #2091bf !important;
}

a.bg-windows-darker:hover, a.bg-windows-darker:focus {
  background-color: #1e8ab5 !important;
}

.btn-windows {
  background: #26AAE1;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-windows:hover {
  background: #31aee3;
  color: #fff;
}
.btn-windows:hover, .btn-windows:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-windows .badge {
  background: #fff;
  color: #26AAE1;
}

.btn-windows .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-windows:active,
.btn-windows.active,
.show > .btn-windows.dropdown-toggle {
  background: #42b5e5;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-windows.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #26AAE1 !important;
}
.btn-windows.md-btn-flat:focus, .btn-windows.md-btn-flat.focus {
  background: rgba(38, 170, 225, 0.1) !important;
}
.btn-windows.md-btn-flat .waves-ripple {
  background: rgba(38, 170, 225, 0.3) !important;
}

.btn-windows.md-btn-flat:active,
.btn-windows.md-btn-flat.active,
.show > .btn-windows.md-btn-flat.dropdown-toggle {
  background: rgba(38, 170, 225, 0.15) !important;
}

.btn-windows.disabled,
.btn-windows:disabled {
  background: #26AAE1 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-windows.md-btn-flat.disabled,
.btn-windows.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #26AAE1 !important;
}

.btn-outline-windows {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #26AAE1 inset;
          box-shadow: 0 0 0 1px #26AAE1 inset;
  color: #26AAE1;
}
.btn-outline-windows:hover, .btn-outline-windows:focus {
  background: #26AAE1;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-windows:hover .badge, .btn-outline-windows:focus .badge {
  background: #fff;
  color: #26AAE1;
}
.btn-outline-windows.disabled, .btn-outline-windows:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #26AAE1 inset !important;
          box-shadow: 0 0 0 1px #26AAE1 inset !important;
  color: #26AAE1 !important;
}

.btn-outline-windows .badge {
  background: #26AAE1;
  color: #fff;
}

.btn-outline-windows .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-windows:active,
.btn-outline-windows.active,
.show > .btn-outline-windows.dropdown-toggle {
  background: #31aee3;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-windows:active .badge,
.btn-outline-windows.active .badge,
.show > .btn-outline-windows.dropdown-toggle .badge {
  background: #fff;
  color: #26AAE1;
}

.text-macos {
  color: #343434 !important;
}

a.text-macos:hover, a.text-macos:focus {
  color: #0e0e0e !important;
}

.bg-macos {
  background-color: #343434 !important;
}

a.bg-macos:hover, a.bg-macos:focus {
  background-color: #313131 !important;
}

.bg-macos-dark {
  background-color: #2f2f2f !important;
}

a.bg-macos-dark:hover, a.bg-macos-dark:focus {
  background-color: #2d2d2d !important;
}

.bg-macos-darker {
  background-color: #2c2c2c !important;
}

a.bg-macos-darker:hover, a.bg-macos-darker:focus {
  background-color: #2a2a2a !important;
}

.btn-macos {
  background: #343434;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-macos:hover {
  background: #3e3e3e;
  color: #fff;
}
.btn-macos:hover, .btn-macos:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-macos .badge {
  background: #fff;
  color: #343434;
}

.btn-macos .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-macos:active,
.btn-macos.active,
.show > .btn-macos.dropdown-toggle {
  background: #4e4e4e;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-macos.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #343434 !important;
}
.btn-macos.md-btn-flat:focus, .btn-macos.md-btn-flat.focus {
  background: rgba(52, 52, 52, 0.1) !important;
}
.btn-macos.md-btn-flat .waves-ripple {
  background: rgba(52, 52, 52, 0.3) !important;
}

.btn-macos.md-btn-flat:active,
.btn-macos.md-btn-flat.active,
.show > .btn-macos.md-btn-flat.dropdown-toggle {
  background: rgba(52, 52, 52, 0.15) !important;
}

.btn-macos.disabled,
.btn-macos:disabled {
  background: #343434 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-macos.md-btn-flat.disabled,
.btn-macos.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #343434 !important;
}

.btn-outline-macos {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #343434 inset;
          box-shadow: 0 0 0 1px #343434 inset;
  color: #343434;
}
.btn-outline-macos:hover, .btn-outline-macos:focus {
  background: #343434;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-macos:hover .badge, .btn-outline-macos:focus .badge {
  background: #fff;
  color: #343434;
}
.btn-outline-macos.disabled, .btn-outline-macos:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #343434 inset !important;
          box-shadow: 0 0 0 1px #343434 inset !important;
  color: #343434 !important;
}

.btn-outline-macos .badge {
  background: #343434;
  color: #fff;
}

.btn-outline-macos .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-macos:active,
.btn-outline-macos.active,
.show > .btn-outline-macos.dropdown-toggle {
  background: #3e3e3e;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-macos:active .badge,
.btn-outline-macos.active .badge,
.show > .btn-outline-macos.dropdown-toggle .badge {
  background: #fff;
  color: #343434;
}

.text-linux {
  color: #800080 !important;
}

a.text-linux:hover, a.text-linux:focus {
  color: #340034 !important;
}

.bg-linux {
  background-color: #800080 !important;
}

a.bg-linux:hover, a.bg-linux:focus {
  background-color: #7a007a !important;
}

.bg-linux-dark {
  background-color: #730073 !important;
}

a.bg-linux-dark:hover, a.bg-linux-dark:focus {
  background-color: #6d006d !important;
}

.bg-linux-darker {
  background-color: #6d006d !important;
}

a.bg-linux-darker:hover, a.bg-linux-darker:focus {
  background-color: #680068 !important;
}

.btn-linux {
  background: #800080;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-linux:hover {
  background: #860d86;
  color: #fff;
}
.btn-linux:hover, .btn-linux:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-linux .badge {
  background: #fff;
  color: #800080;
}

.btn-linux .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-linux:active,
.btn-linux.active,
.show > .btn-linux.dropdown-toggle {
  background: #912191;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-linux.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #800080 !important;
}
.btn-linux.md-btn-flat:focus, .btn-linux.md-btn-flat.focus {
  background: rgba(128, 0, 128, 0.1) !important;
}
.btn-linux.md-btn-flat .waves-ripple {
  background: rgba(128, 0, 128, 0.3) !important;
}

.btn-linux.md-btn-flat:active,
.btn-linux.md-btn-flat.active,
.show > .btn-linux.md-btn-flat.dropdown-toggle {
  background: rgba(128, 0, 128, 0.15) !important;
}

.btn-linux.disabled,
.btn-linux:disabled {
  background: #800080 !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-linux.md-btn-flat.disabled,
.btn-linux.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #800080 !important;
}

.btn-outline-linux {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #800080 inset;
          box-shadow: 0 0 0 1px #800080 inset;
  color: #800080;
}
.btn-outline-linux:hover, .btn-outline-linux:focus {
  background: #800080;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-linux:hover .badge, .btn-outline-linux:focus .badge {
  background: #fff;
  color: #800080;
}
.btn-outline-linux.disabled, .btn-outline-linux:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #800080 inset !important;
          box-shadow: 0 0 0 1px #800080 inset !important;
  color: #800080 !important;
}

.btn-outline-linux .badge {
  background: #800080;
  color: #fff;
}

.btn-outline-linux .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-linux:active,
.btn-outline-linux.active,
.show > .btn-outline-linux.dropdown-toggle {
  background: #860d86;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-linux:active .badge,
.btn-outline-linux.active .badge,
.show > .btn-outline-linux.dropdown-toggle .badge {
  background: #fff;
  color: #800080;
}

.btn-white {
  background: #fff;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #4E5155;
}
.btn-white:hover {
  background: white;
  color: #4E5155;
}
.btn-white:hover, .btn-white:focus {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-white .badge {
  background: #4E5155;
  color: #fff;
}

.btn-white .waves-ripple {
  background: rgba(78, 81, 85, 0.2) !important;
}

.btn-white:active,
.btn-white.active,
.show > .btn-white.dropdown-toggle {
  background: white;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-white.md-btn-flat {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #fff !important;
}
.btn-white.md-btn-flat:focus, .btn-white.md-btn-flat.focus {
  background: rgba(255, 255, 255, 0.1) !important;
}
.btn-white.md-btn-flat .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-white.md-btn-flat:active,
.btn-white.md-btn-flat.active,
.show > .btn-white.md-btn-flat.dropdown-toggle {
  background: rgba(255, 255, 255, 0.15) !important;
}

.btn-white.disabled,
.btn-white:disabled {
  background: #fff !important;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #4E5155 !important;
}

.btn-white.md-btn-flat.disabled,
.btn-white.md-btn-flat:disabled {
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #fff !important;
}

.btn-outline-white {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px #fff inset;
          box-shadow: 0 0 0 1px #fff inset;
  color: #fff;
}
.btn-outline-white:hover, .btn-outline-white:focus {
  background: #fff;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #4E5155;
}
.btn-outline-white:hover .badge, .btn-outline-white:focus .badge {
  background: #4E5155;
  color: #fff;
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #fff inset !important;
          box-shadow: 0 0 0 1px #fff inset !important;
  color: #fff !important;
}

.btn-outline-white .badge {
  background: #fff;
  color: #fff;
}

.btn-outline-white .waves-ripple {
  background: rgba(78, 81, 85, 0.2) !important;
}

.btn-outline-white:active,
.btn-outline-white.active,
.show > .btn-outline-white.dropdown-toggle {
  background: white;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #4E5155;
}
.btn-outline-white:active .badge,
.btn-outline-white.active .badge,
.show > .btn-outline-white.dropdown-toggle .badge {
  background: #4E5155;
  color: #fff;
}

.custom-control.custom-control-black .custom-control-label::before,
.custom-control.custom-control-black .custom-control-input:active ~ .custom-control-label::before {
  background-color: #000;
}
.custom-control.custom-control-black.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-control.custom-control-black.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #000;
}
.custom-control.custom-control-black.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.custom-control.custom-control-black.custom-radio .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23000'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23000'/%3E%3C/svg%3E");
}
.custom-control.custom-control-black .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(0, 0, 0, 0.15);
}

.custom-control.custom-control-white .custom-control-label::before,
.custom-control.custom-control-white .custom-control-input:active ~ .custom-control-label::before {
  background-color: #fff;
}
.custom-control.custom-control-white.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-control.custom-control-white.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #fff;
}
.custom-control.custom-control-white.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23666666;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.custom-control.custom-control-white.custom-radio .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23fff'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23fff'/%3E%3C/svg%3E");
}
.custom-control.custom-control-white .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(255, 255, 255, 0.15);
}

.custom-control.custom-control-silver .custom-control-label::before,
.custom-control.custom-control-silver .custom-control-input:active ~ .custom-control-label::before {
  background-color: #eee;
}
.custom-control.custom-control-silver.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-control.custom-control-silver.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #eee;
}
.custom-control.custom-control-silver.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #eee;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%235f5f5f;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.custom-control.custom-control-silver.custom-radio .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23eee'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23eee'/%3E%3C/svg%3E");
}
.custom-control.custom-control-silver .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(238, 238, 238, 0.15);
}

.custom-control.custom-control-gray .custom-control-label::before,
.custom-control.custom-control-gray .custom-control-input:active ~ .custom-control-label::before {
  background-color: #777;
}
.custom-control.custom-control-gray.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-control.custom-control-gray.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #777;
}
.custom-control.custom-control-gray.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #777;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.custom-control.custom-control-gray.custom-radio .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23777'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23777'/%3E%3C/svg%3E");
}
.custom-control.custom-control-gray .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(119, 119, 119, 0.15);
}

.custom-control.custom-control-gold .custom-control-label::before,
.custom-control.custom-control-gold .custom-control-input:active ~ .custom-control-label::before {
  background-color: #ffeb3b;
}
.custom-control.custom-control-gold.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-control.custom-control-gold.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ffeb3b;
}
.custom-control.custom-control-gold.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffeb3b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23665e18;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.custom-control.custom-control-gold.custom-radio .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23ffeb3b'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23ffeb3b'/%3E%3C/svg%3E");
}
.custom-control.custom-control-gold .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(255, 235, 59, 0.15);
}

.custom-control.custom-control-pink .custom-control-label::before,
.custom-control.custom-control-pink .custom-control-input:active ~ .custom-control-label::before {
  background-color: #e91e63;
}
.custom-control.custom-control-pink.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-control.custom-control-pink.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #e91e63;
}
.custom-control.custom-control-pink.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e91e63;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.custom-control.custom-control-pink.custom-radio .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23e91e63'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23e91e63'/%3E%3C/svg%3E");
}
.custom-control.custom-control-pink .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(233, 30, 99, 0.15);
}

.custom-control.custom-control-red .custom-control-label::before,
.custom-control.custom-control-red .custom-control-input:active ~ .custom-control-label::before {
  background-color: #f44336;
}
.custom-control.custom-control-red.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-control.custom-control-red.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #f44336;
}
.custom-control.custom-control-red.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f44336;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.custom-control.custom-control-red.custom-radio .custom-control-label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23f44336'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23f44336'/%3E%3C/svg%3E");
}
.custom-control.custom-control-red .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(244, 67, 54, 0.15);
}

.navbar.bg-success {
  background-color: #28D094 !important;
  color: #dcf7ee;
}
.navbar.bg-success .navbar-brand {
  color: #fff;
}
.navbar.bg-success .navbar-brand:hover, .navbar.bg-success .navbar-brand:focus {
  color: #fff;
}
.navbar.bg-success .navbar-nav .nav-link {
  color: #dcf7ee;
}
.navbar.bg-success .navbar-nav .nav-link:hover, .navbar.bg-success .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar.bg-success .navbar-nav .nav-link.disabled {
  color: #94e7ca !important;
}
.navbar.bg-success .navbar-nav .show > .nav-link,
.navbar.bg-success .navbar-nav .active > .nav-link,
.navbar.bg-success .navbar-nav .nav-link.show,
.navbar.bg-success .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-success .navbar-toggler {
  color: #dcf7ee;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-success .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar.bg-success .navbar-text {
  color: #dcf7ee;
}
.navbar.bg-success .navbar-text a {
  color: #fff;
}
.navbar.bg-success .navbar-text a:hover, .navbar.bg-success .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-success hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-info {
  background-color: #1E9FF2 !important;
  color: #d3ecfc;
}
.navbar.bg-info .navbar-brand {
  color: #fff;
}
.navbar.bg-info .navbar-brand:hover, .navbar.bg-info .navbar-brand:focus {
  color: #fff;
}
.navbar.bg-info .navbar-nav .nav-link {
  color: #d3ecfc;
}
.navbar.bg-info .navbar-nav .nav-link:hover, .navbar.bg-info .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar.bg-info .navbar-nav .nav-link.disabled {
  color: #8bcdf8 !important;
}
.navbar.bg-info .navbar-nav .show > .nav-link,
.navbar.bg-info .navbar-nav .active > .nav-link,
.navbar.bg-info .navbar-nav .nav-link.show,
.navbar.bg-info .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-info .navbar-toggler {
  color: #d3ecfc;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-info .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar.bg-info .navbar-text {
  color: #d3ecfc;
}
.navbar.bg-info .navbar-text a {
  color: #fff;
}
.navbar.bg-info .navbar-text a:hover, .navbar.bg-info .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-info hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-warning {
  background-color: #FF9149 !important;
  color: #8f5129;
}
.navbar.bg-warning .navbar-brand {
  color: #663a1d;
}
.navbar.bg-warning .navbar-brand:hover, .navbar.bg-warning .navbar-brand:focus {
  color: #663a1d;
}
.navbar.bg-warning .navbar-nav .nav-link {
  color: #8f5129;
}
.navbar.bg-warning .navbar-nav .nav-link:hover, .navbar.bg-warning .navbar-nav .nav-link:focus {
  color: #663a1d;
}
.navbar.bg-warning .navbar-nav .nav-link.disabled {
  color: #bc6b36 !important;
}
.navbar.bg-warning .navbar-nav .show > .nav-link,
.navbar.bg-warning .navbar-nav .active > .nav-link,
.navbar.bg-warning .navbar-nav .nav-link.show,
.navbar.bg-warning .navbar-nav .nav-link.active {
  color: #663a1d;
}
.navbar.bg-warning .navbar-toggler {
  color: #8f5129;
  border-color: rgba(102, 58, 29, 0.15);
}
.navbar.bg-warning .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar.bg-warning .navbar-text {
  color: #8f5129;
}
.navbar.bg-warning .navbar-text a {
  color: #663a1d;
}
.navbar.bg-warning .navbar-text a:hover, .navbar.bg-warning .navbar-text a:focus {
  color: #663a1d;
}
.navbar.bg-warning hr {
  border-color: rgba(102, 58, 29, 0.15);
}

.navbar.bg-danger {
  background-color: #FF4961 !important;
  color: #ffdbe0;
}
.navbar.bg-danger .navbar-brand {
  color: #fff;
}
.navbar.bg-danger .navbar-brand:hover, .navbar.bg-danger .navbar-brand:focus {
  color: #fff;
}
.navbar.bg-danger .navbar-nav .nav-link {
  color: #ffdbe0;
}
.navbar.bg-danger .navbar-nav .nav-link:hover, .navbar.bg-danger .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar.bg-danger .navbar-nav .nav-link.disabled {
  color: #ffa1ad !important;
}
.navbar.bg-danger .navbar-nav .show > .nav-link,
.navbar.bg-danger .navbar-nav .active > .nav-link,
.navbar.bg-danger .navbar-nav .nav-link.show,
.navbar.bg-danger .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-danger .navbar-toggler {
  color: #ffdbe0;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-danger .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar.bg-danger .navbar-text {
  color: #ffdbe0;
}
.navbar.bg-danger .navbar-text a {
  color: #fff;
}
.navbar.bg-danger .navbar-text a:hover, .navbar.bg-danger .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-danger hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-dark {
  background-color: #2c2e3e !important;
  color: #9b9ca3;
}
.navbar.bg-dark .navbar-brand {
  color: #fff;
}
.navbar.bg-dark .navbar-brand:hover, .navbar.bg-dark .navbar-brand:focus {
  color: #fff;
}
.navbar.bg-dark .navbar-nav .nav-link {
  color: #9b9ca3;
}
.navbar.bg-dark .navbar-nav .nav-link:hover, .navbar.bg-dark .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar.bg-dark .navbar-nav .nav-link.disabled {
  color: #6f707b !important;
}
.navbar.bg-dark .navbar-nav .show > .nav-link,
.navbar.bg-dark .navbar-nav .active > .nav-link,
.navbar.bg-dark .navbar-nav .nav-link.show,
.navbar.bg-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-dark .navbar-toggler {
  color: #9b9ca3;
  border-color: rgba(255, 255, 255, 0.06);
}
.navbar.bg-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar.bg-dark .navbar-text {
  color: #9b9ca3;
}
.navbar.bg-dark .navbar-text a {
  color: #fff;
}
.navbar.bg-dark .navbar-text a:hover, .navbar.bg-dark .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-dark hr {
  border-color: rgba(255, 255, 255, 0.06);
}

.navbar.bg-white {
  background-color: #ffffff !important;
  color: #4E5155;
}
.navbar.bg-white .navbar-brand {
  color: #4E5155;
}
.navbar.bg-white .navbar-brand:hover, .navbar.bg-white .navbar-brand:focus {
  color: #4E5155;
}
.navbar.bg-white .navbar-nav .nav-link {
  color: #4E5155;
}
.navbar.bg-white .navbar-nav .nav-link:hover, .navbar.bg-white .navbar-nav .nav-link:focus {
  color: #4E5155;
}
.navbar.bg-white .navbar-nav .nav-link.disabled {
  color: #959799 !important;
}
.navbar.bg-white .navbar-nav .show > .nav-link,
.navbar.bg-white .navbar-nav .active > .nav-link,
.navbar.bg-white .navbar-nav .nav-link.show,
.navbar.bg-white .navbar-nav .nav-link.active {
  color: #4E5155;
}
.navbar.bg-white .navbar-toggler {
  color: #4E5155;
  border-color: rgba(78, 81, 85, 0.075);
}
.navbar.bg-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar.bg-white .navbar-text {
  color: #4E5155;
}
.navbar.bg-white .navbar-text a {
  color: #4E5155;
}
.navbar.bg-white .navbar-text a:hover, .navbar.bg-white .navbar-text a:focus {
  color: #4E5155;
}
.navbar.bg-white hr {
  border-color: rgba(78, 81, 85, 0.075);
}

.navbar[class*="bg-"]:not(.bg-white) .app-brand-logo img {
  -webkit-filter: brightness(4);
          filter: brightness(4);
}
.navbar[class*="bg-"]:not(.bg-white) .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.navbar[class*="bg-"]:not(.bg-white) .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.navbar[class*="bg-"]:not(.bg-white) .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.navbar.bg-white .app-brand-logo img {
  -webkit-filter: brightness(1);
          filter: brightness(1);
}

.sidenav.bg-dark {
  background-color: #2A3542 !important;
  color: #ffffff;
  font-size: .875rem;
  font-weight: 500;
}
.sidenav.bg-dark .sidenav-link,
.sidenav.bg-dark .sidenav-horizontal-prev,
.sidenav.bg-dark .sidenav-horizontal-next {
  color: #a2a3b7;
}
.sidenav-vertical .sidenav-inner > .sidenav-item:hover{
  background-color: #1b1b28;
}
.sidenav.bg-dark .sidenav-link:hover, .sidenav.bg-dark .sidenav-link:focus,
.sidenav.bg-dark .sidenav-horizontal-prev:hover,
.sidenav.bg-dark .sidenav-horizontal-prev:focus,
.sidenav.bg-dark .sidenav-horizontal-next:hover,
.sidenav.bg-dark .sidenav-horizontal-next:focus {
  color: #fff;
}
.sidenav.bg-dark .sidenav-link.active,
.sidenav.bg-dark .sidenav-horizontal-prev.active,
.sidenav.bg-dark .sidenav-horizontal-next.active {
  color: #fff;
}
.sidenav.bg-dark .sidenav-item.disabled .sidenav-link,
.sidenav.bg-dark .sidenav-horizontal-prev.disabled,
.sidenav.bg-dark .sidenav-horizontal-next.disabled {
  color: #6f707b !important;
}
.sidenav.bg-dark .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #008ace;
  color: #ececec;
}
.sidenav.bg-dark.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #414251;
}
.sidenav.bg-dark.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu, .sidenav.bg-dark.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #373848;
}
.sidenav.bg-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #9b9ca3;
}
.sidenav.bg-dark .sidenav-text {
  color: #000;
}
.sidenav.bg-dark .sidenav-header {
  color: #7f818a;
  font-size: .875rem;
  font-weight: 500;
}
.sidenav.bg-dark hr,
.sidenav.bg-dark .sidenav-divider,
.sidenav.bg-dark .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.06) !important;
}
.sidenav.bg-dark .sidenav-inner > .sidenav-header::before,
.sidenav.bg-dark .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.06);
}
.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #434453;
}
.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}
.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  -webkit-box-shadow: 0 0 0 2px #373848;
          box-shadow: 0 0 0 2px #373848;
}
.sidenav.bg-dark .ps__thumb-y,
.sidenav.bg-dark .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.47408) !important;
}

.sidenav.bg-white {
  background-color: #fff !important;
  color: #4E5155;
}
.sidenav.bg-white .sidenav-link,
.sidenav.bg-white .sidenav-horizontal-prev,
.sidenav.bg-white .sidenav-horizontal-next {
  color: #4E5155;
}
.sidenav.bg-white .sidenav-link:hover, .sidenav.bg-white .sidenav-link:focus,
.sidenav.bg-white .sidenav-horizontal-prev:hover,
.sidenav.bg-white .sidenav-horizontal-prev:focus,
.sidenav.bg-white .sidenav-horizontal-next:hover,
.sidenav.bg-white .sidenav-horizontal-next:focus {
  color: #4E5155;
}
.sidenav.bg-white .sidenav-link.active,
.sidenav.bg-white .sidenav-horizontal-prev.active,
.sidenav.bg-white .sidenav-horizontal-next.active {
  color: #4E5155;
}
.sidenav.bg-white .sidenav-item.disabled .sidenav-link,
.sidenav.bg-white .sidenav-horizontal-prev.disabled,
.sidenav.bg-white .sidenav-horizontal-next.disabled {
  color: #959799 !important;
}
.sidenav.bg-white .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-white .sidenav-item.active > .sidenav-link {
  color: #4E5155;
}
.sidenav.bg-white .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f9f9f9;
}
.sidenav.bg-white.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f3f3f3;
}
.sidenav.bg-white.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu, .sidenav.bg-white.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #f9f9f9;
}
.sidenav.bg-white .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-white .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #4E5155;
}
.sidenav.bg-white .sidenav-text {
  color: #4E5155;
}
.sidenav.bg-white .sidenav-header {
  color: #7a7d80;
}
.sidenav.bg-white hr,
.sidenav.bg-white .sidenav-divider,
.sidenav.bg-white .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(78, 81, 85, 0.075) !important;
}
.sidenav.bg-white .sidenav-inner > .sidenav-header::before,
.sidenav.bg-white .sidenav-block::before {
  background-color: rgba(78, 81, 85, 0.075);
}
.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #ececed;
}
.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #4E5155;
}
.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  -webkit-box-shadow: 0 0 0 2px #f9f9f9;
          box-shadow: 0 0 0 2px #f9f9f9;
}
.sidenav.bg-white .ps__thumb-y,
.sidenav.bg-white .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(78, 81, 85, 0.2) !important;
}

.sidenav.bg-dark {
  background: #2c2e3e;
}

.sidenav.bg-white .app-brand-logo img {
  -webkit-filter: contrast(0);
          filter: contrast(0);
}

.footer.bg-dark {
  background-color: #2c2e3e !important;
  color: #9b9ca3;
}
.footer.bg-dark .footer-link {
  color: #9b9ca3;
}
.footer.bg-dark .footer-link:hover, .footer.bg-dark .footer-link:focus {
  color: #fff;
}
.footer.bg-dark .footer-link.disabled {
  color: #6f707b !important;
}
.footer.bg-dark .footer-text {
  color: #fff;
}
.footer.bg-dark .show > .footer-link,
.footer.bg-dark .active > .footer-link,
.footer.bg-dark .footer-link.show,
.footer.bg-dark .footer-link.active {
  color: #fff;
}
.footer.bg-dark hr {
  border-color: rgba(255, 255, 255, 0.06);
}

.footer.bg-white {
  background-color: #fff !important;
  color: #4E5155;
}
.footer.bg-white .footer-link {
  color: #4E5155;
}
.footer.bg-white .footer-link:hover, .footer.bg-white .footer-link:focus {
  color: #666666;
}
.footer.bg-white .footer-link.disabled {
  color: #959799 !important;
}
.footer.bg-white .footer-text {
  color: #666666;
}
.footer.bg-white .show > .footer-link,
.footer.bg-white .active > .footer-link,
.footer.bg-white .footer-link.show,
.footer.bg-white .footer-link.active {
  color: #666666;
}
.footer.bg-white hr {
  border-color: rgba(102, 102, 102, 0.075);
}

@media (max-width: 480px) {
  .layout-footer .d-flex > .pt-3 {
    margin: 0 auto;
    width: 100%;
  }
}
.demo-vertical-spacing > * + * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-sm > * + * {
  margin-top: 0.9375rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-lg > * + * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}

.demo-inline-spacing > *,
.demo-paragraph-spacing > p > * {
  margin: 0 0.375rem 0.9375rem 0 !important;
}

.demo-paragraph-spacing > p {
  margin: 0;
}

.demo-blocks > * {
  display: block !important;
}

.demo-navbar-messages .dropdown-toggle,
.demo-navbar-messages.b-nav-dropdown .nav-link,
.demo-navbar-notifications .dropdown-toggle,
.demo-navbar-notifications.b-nav-dropdown .nav-link,
.demo-navbar-user .dropdown-toggle,
.demo-navbar-user.b-nav-dropdown .nav-link {
  white-space: nowrap;
}

.demo-navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0;
}

@media (min-width: 992px) {
  .demo-navbar-messages .dropdown-menu,
  .demo-navbar-notifications .dropdown-menu {
    margin-top: 0.5rem;
    width: 22rem;
  }

  .demo-navbar-user .dropdown-menu {
    margin-top: 0.25rem;
  }
}
.demo-navbar-notifications > a {
  -webkit-animation: ring 1.5s infinite linear;
          animation: ring 1.5s infinite linear;
}
@media screen and (max-width: 992px) {
  .demo-navbar-notifications > a {
    -webkit-animation: unset;
            animation: unset;
  }
}

.navbar-nav .badge-dot {
  opacity: 0;
  -webkit-animation: hblink 1.5s infinite linear;
          animation: hblink 1.5s infinite linear;
}

@-webkit-keyframes hblink {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  7% {
    opacity: 1;
  }
  10.25% {
    opacity: 0;
  }
  90.75% {
    opacity: 0;
  }
  93% {
    opacity: 1;
  }
  97% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hblink {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  7% {
    opacity: 1;
  }
  10.25% {
    opacity: 0;
  }
  90.75% {
    opacity: 0;
  }
  93% {
    opacity: 1;
  }
  97% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  6.25% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  12.5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  18.75% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  25% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  31.25% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  37.5% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
  43.75% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
  56.25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  62.5% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
  68.75% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  75% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  81.25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  87.5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  93.75% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  6.25% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  12.5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  18.75% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  25% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  31.25% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  37.5% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
  43.75% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
  56.25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  62.5% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
  68.75% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  75% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  81.25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  87.5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  93.75% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.ui-builder {
  z-index: 1029;
  position: relative;
}
.ui-builder .style-toggler > a {
  position: fixed;
  right: 0;
  top: 120px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.ui-builder .style-toggler > a:before {
  content: "";
  top: 0;
  right: 0;
  position: absolute;
  width: 45px;
  height: 45px;
  font-family: "pct";
  z-index: 1001;
  line-height: 0;
  background: #716aca;
  border-radius: 5px 0 0 5px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-box-shadow: -1px 0 8px rgba(113, 106, 202, 0.9), -6px 0 8px rgba(0, 0, 0, 0.1);
          box-shadow: -1px 0 8px rgba(113, 106, 202, 0.9), -6px 0 8px rgba(0, 0, 0, 0.1);
}
.ui-builder .style-toggler > a:after {
  content: "\e810";
  top: 10px;
  right: 10px;
  font-size: 23px;
  position: absolute;
  font-family: 'Linearicons-Free';
  z-index: 1029;
  line-height: 1;
  color: #fff;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-animation: cir 1.5s linear infinite;
          animation: cir 1.5s linear infinite;
}
.ui-builder.open .style-toggler > a {
  right: 400px;
}
.ui-builder.open .style-toggler > a:after {
  content: "\e870";
  -webkit-animation: anim-rotate 0s linear infinite;
          animation: anim-rotate 0s linear infinite;
}
.ui-builder.open .ui-block {
  right: 0;
  -webkit-box-shadow: -1px 0 15px 2px rgba(62, 57, 107, 0.15);
          box-shadow: -1px 0 15px 2px rgba(62, 57, 107, 0.15);
}
.ui-builder .ui-block {
  position: fixed;
  top: 120px;
  right: -400px;
  width: 400px;
  background: #fff;
  z-index: 1030;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  border-radius: 0 0 0 5px;
}
.ui-builder .ui-block .style-head {
  background: #716aca;
  color: #fff;
  padding: 13.7px 30px;
  -webkit-box-shadow: -1px 0 8px rgba(113, 106, 202, 0.9), 5px 0 8px rgba(0, 0, 0, 0.1);
          box-shadow: -1px 0 8px rgba(113, 106, 202, 0.9), 5px 0 8px rgba(0, 0, 0, 0.1);
}
.ui-builder .ui-block .style-body {
  padding: 30px;
}
.ui-builder label {
  margin-bottom: 10px;
}
.ui-builder .layout {
  display: block;
}
.ui-builder .layout > a {
  position: relative;
  width: 45px;
  height: 35px;
  display: inline-block;
  margin-right: 10px;
  background: #f2f3f8;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3), 3px 3px 9px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3), 3px 3px 9px 0 rgba(0, 0, 0, 0.08);
}
.ui-builder .layout > a span {
  width: 100%;
  position: absolute;
}
.ui-builder .layout > a span:after, .ui-builder .layout > a span:before {
  content: "";
  height: 100%;
  position: absolute;
}
.ui-builder .layout > a span:before {
  width: 30%;
  left: 0;
  background: #2c2e3e;
}
.ui-builder .layout > a span:after {
  width: 70%;
  right: 0;
  background: #f2f3f8;
}
.ui-builder .layout > a > span:nth-child(1) {
  height: 30%;
  top: 0;
}
.ui-builder .layout > a > span:nth-child(2) {
  height: 70%;
  bottom: 0;
}
.ui-builder .layout > a.active {
  border-color: #716aca;
}
.ui-builder .layout.small > a {
  width: 45px;
  height: 30px;
}
.ui-builder .layout.small > a > span {
  display: none;
}
.ui-builder .layout.header-color > a[data-val="bg-primary"] > span:nth-child(1):after {
  background: #716aca;
}
.ui-builder .layout.header-color > a[data-val="bg-success"] > span:nth-child(1):after {
  background: #28D094;
}
.ui-builder .layout.header-color > a[data-val="bg-info"] > span:nth-child(1):after {
  background: #1E9FF2;
}
.ui-builder .layout.header-color > a[data-val="bg-warning"] > span:nth-child(1):after {
  background: #FF9149;
}
.ui-builder .layout.header-color > a[data-val="bg-danger"] > span:nth-child(1):after {
  background: #FF4961;
}
.ui-builder .layout.header-color > a[data-val="bg-dark"] > span:nth-child(1):after {
  background: #2c2e3e;
}
.ui-builder .layout.header-color > a[data-val="bg-white"] > span:nth-child(1):after {
  background: #fff;
}
@media only screen and (max-width: 992px) {
  .ui-builder {
    display: none;
  }
}

@-webkit-keyframes cir {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes cir {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.card-badges {
  position: absolute;
  top: 20px;
  right: -30px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 120px;
  text-align: center;
}
.card-badges.left {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  right: auto;
  left: -30px;
}

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
  display: none;
}

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle {
  display: block !important;
}
/* custome styles comes here */
.btn-reveal-trigger .dropdown-toggle:after {
  display: none;
}
td .dropdown-toggle:after {
  display: none;
}