.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x,2rem) !important;
  padding-left: var(--bs-gutter-x,2rem) !important;
  margin-right: auto;
  margin-left: auto;
}
.btn {
  text-transform: capitalize !important;
}
.form-control[_ngcontent-yvn-c63] {
  display: block;
  width: 100%;
  height: 2.1875rem;
  padding: 0.5rem 0.875rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.33;
  color: #4E5155;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid rgba(24, 28, 33, 0.1) !important;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.help-block{
  color: #F44336;
  font-size: 14px;
}
.card-header:first-child {
    border-radius: calc(0.125rem - 0px) calc(0.125rem - 0px) 0 0;
}
.card-header, .card-footer, .card hr {
    border-color: rgba(24, 28, 33, 0.06);
}
.card-header {
    line-height: 1.54;
    border-bottom-width: 1px;
}
.card-header {
    padding: 1.3rem 1.5rem;
    margin-bottom: 0;
    color: inherit;
    background-color: transparent;
    border-bottom: 0px solid rgba(24, 28, 33, 0.06);
}